import moment from "moment";
import React, { useContext, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import { CODE, MEMBER_TYPE } from "../../../utils/Constant";
import { getCommittees, getTopicById, regisAcceptanceMinute } from "../services/services";
import { TDataScienceMinutes, TMember } from "../models/TopicModel";
const TYPE_MINUTES = {
  HD: {
    id: 1,
    name: `Hội đồng xét duyệt thẩm định
    đề cương nhiệm vụ KH&CN cấp cơ sở.`,
    status: 5,
  },
  NT: {
    id: 2,
    name: `Hội đồng nghiệm thu thẩm định đề tài.`,
    status: 9,
  },
};
interface Props {
  show: boolean;
  onHide: () => void;
  id: string;
  onRefresh: () => Promise<void>;
}

const AcceptanceMinutesDialog = (props: Props) => {
  const { show, onHide, id, onRefresh } = props;
  const { setIsLoading } = useContext(AppContext);
  const [dataMinutes, setDataMinutes] = React.useState<TDataScienceMinutes>({});
  const [topicDetails, setTopicDetails] = React.useState<any>({});
  const [numRows, setNumRows] = React.useState<any>({});
  const [acceptanceCommitteeId, setAcceptanceCommitteeId] = React.useState<string>("");
  const [committeeInfo, setCommitteeInfo] = React.useState<any>({});
  const [validatorInput, setValidatorInput] = React.useState<any>({
    place: {
      valid: false,
      name: "place",
    },
    dayDate: {
      valid: false,
      name: "dayDate",
    },
    monthDate: {
      valid: false,
      name: "monthDate",
    },
    yearDate: {
      valid: false,
      name: "yearDate",
    },
    supervisor: {
      valid: false,
      name: "supervisor",
    },
    numAttendees: {
      valid: false,
      name: "numAttendees",
    },
    absentMembers: {
      valid: false,
      name: "absentMembers",
    },
    chairman: {
      valid: false,
      name: "chairman",
    },
    presenter: {
      valid: false,
      name: "presenter",
    },
    presenterDuty: {
      valid: false,
      name: "presenterDuty",
    },
    committeeFeedback: {
      valid: false,
      name: "committeeFeedback",
    },
    committeeRecommendation: {
      valid: false,
      name: "committeeRecommendation",
    },
    topicPiName: {
      valid: false,
      name: "topicPiName",
    },
    guest: {
      valid: false,
      name: "guest",
    }
  });
  const validatorField = (name: any, value: any) => {
    if (name === validatorInput[name].name) {
      if (!value) {
        setValidatorInput({
          ...validatorInput,
          [name]: {
            ...validatorInput[name],
            valid: true,
          },
        });
      } else {
        setValidatorInput({
          ...validatorInput,
          [name]: {
            ...validatorInput[name],
            valid: false,
          },
        });
      }
    }
  };

  useEffect(() => {
    getDetails(id);
  }, [id]);

  useEffect(() => {
    if(!acceptanceCommitteeId) return;
    getCommitteesInfo(acceptanceCommitteeId);
  }, [acceptanceCommitteeId]);

  useEffect(() => {
    if (topicDetails) {
      setDataMinutes((prev) => ({
        ...prev,
        topicName: topicDetails.name,
        supervisor: "bệnh viện đa khoa Mỹ Đức",
        implementingAgency: "bệnh viện đa khoa Mỹ Đức",
        topicPiName: topicDetails.members?.find(
          (member: TMember) => member.typeId === MEMBER_TYPE.CHU_NHIEM_DE_TAI
        )?.name
      }));
    }
  }, [topicDetails]);

  const handleChange = (e: any) => {
    const { name, value, type } = e.target;
    if (type === "radio") {
      const inputValue = Number(value);
      setDataMinutes({ ...dataMinutes, [name]: inputValue });
    }
    if (type !== "radio") {
      autoResize(e.target);
      setDataMinutes({ ...dataMinutes, [name]: type === "number" ? Number(value) : value });
    }
    if (name === "numAttendees") {
      setDataMinutes((prevData) => {
        const newNumAttendees = Number(value);
        return {
          ...prevData,
          [name]: newNumAttendees,
          numAbsent: 5 - newNumAttendees,
        };
      });
    }
    if(name === "scoreScientificNature" || name === "scoreCreativity"){
      if(Number(value) > 20){
        setDataMinutes({ ...dataMinutes, [name]: 20 });
      }
      if(Number(value) < 0){
        setDataMinutes({ ...dataMinutes, [name]: 0 });
      }
    }
    if(name === "scoreEffectiveness" || name === "scoreApplicability"){
      if(Number(value) > 20){
        setDataMinutes({ ...dataMinutes, [name]: 30 });
      }
      if(Number(value) < 0){
        setDataMinutes({ ...dataMinutes, [name]: 0 });
      }
    }
  };
  const addDataMinute = async (data: any) => {
    try {
      const response = await regisAcceptanceMinute(id, data);
      if (response?.data?.code === CODE.SUCCESS) {
        toast.success("Tạo biên bản thành công !");
        onHide();
        onRefresh();
        setTopicDetails({});
      }
    } catch (e) {
      toast.error("Tạo biên bản thất bại !");
    }
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    addDataMinute({
      ...dataMinutes,
      ...committeeInfo
    });
  };
  const autoResize = (textarea: any) => {
    const name = textarea.name;
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
    setNumRows({
      ...numRows,
      [name]: textarea.scrollHeight / 24,
    });
  };
  const getDetails = async (id: string) => {
    try {
      setIsLoading(true);
      let { data } = await getTopicById(id);
      if (data?.code === CODE.SUCCESS) {
        setTopicDetails(data?.data);
        setAcceptanceCommitteeId(data?.data?.acceptanceCommitteeId);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getCommitteesInfo = async (committeeId: string) => {
    try {
      setIsLoading(true);
      let { data } = await getCommittees(committeeId);
      if (data?.code === CODE.SUCCESS) {
        setCommitteeInfo(data?.data);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="lg"
      className="spaces h-calc-vh-50"
      scrollable
    >
      <form onSubmit={handleSubmit}>
        <div className="stepper stepper-links d-flex flex-column">
          <Modal.Header className="header-modal min-h-40px" closeButton>
            <Modal.Title className="py-4">Biên bản họp hội đồng nghiệm thu</Modal.Title>
          </Modal.Header>
          <Modal.Body className="spaces p-0 bg-white mxh-60vh">
            <div className="a4-page">
              <div className="a4-container">
                <div className="header-container">
                  <div className="left-column center-text">
                    <h3 className="bold">SỞ Y TẾ HÀ NỘI</h3>
                    <h3 className="bold underline">
                      BỆNH VIỆN ĐA KHOA MỸ ĐỨC{" "}
                    </h3>
                  </div>
                  <div className="right-column center-text">
                    <p className="bold spaces mb-4">
                      CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM
                    </p>
                    <p className="underline bold">
                      Độc lập – Tự do – Hạnh phúc
                    </p>
                  </div>
                </div>

                <h3 className="text-center bold section">
                  BIÊN BẢN CUỘC HỌP HỘI ĐỒNG NGHIỆM THU
                </h3>
                <h4 className="text-center">
                  SÁNG KIẾN, GIẢI PHÁP CÔNG TÁC CẤP CƠ SỞ
                </h4>

                <div className="section">
                  <p className="">
                    <b>1. Tên sáng kiến: </b>
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="text"
                        title="Số hội đồng"
                        name="topicName"
                        value={dataMinutes?.topicName}
                        style={{ width: "100%", display: "inline-block" }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        readOnly
                      />
                      <span className="dot-line"></span>
                    </span>
                  </p>
                  <p>
                    <b>2. Chủ nhiệm sáng kiến, giải pháp: </b>
                  </p>
                  <div className="flex spaces pb-8">
                    <span className="pe-2">Nhóm tác giả:</span>
                    <div className="relative flex-1">
                      <textarea
                        className="no-padding custom-input"
                        title="Nhập nhận xét"
                        wrap="hard"
                        name="topicPiName"
                        value={dataMinutes?.topicPiName}
                        required
                        onInvalid={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("Vui lòng nhập thông tin!");
                          validatorField(target.name, target.value);
                        }}
                        onInput={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("");
                          validatorField(target.name, target.value);
                        }}
                        onChange={(e) => handleChange(e)}
                        style={{
                          fontFamily: "Times New Roman",
                          fontSize: "16px",
                          display: "block",
                          zIndex: "1",
                          width: "100%",
                          outline: "unset",
                          position: "relative",
                          resize: "none",
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                          height: "auto",
                          minHeight: "24px",
                          backgroundColor: "transparent",
                        }}
                      />
                      {Array.from({
                        length: isNaN(numRows?.topicPiName)
                          ? 1
                          : numRows?.topicPiName,
                      })?.map((item: any, index: any) => (
                        <span
                          key={index}
                          style={{
                            position: "absolute",
                            top: `${
                              (1 / numRows?.topicPiName || 0) * 100 * index
                            }%`,
                            right: "0",
                            left: "0",
                            width: "100%",
                            color: `${
                              validatorInput?.topicPiName?.valid
                                ? "red"
                                : "black"
                            }`,
                            height: `24px`,
                            borderBottom: "1px dashed",
                            transform: "translateY(-2px)",
                            backgroundColor: "transparent",
                          }}
                        ></span>
                      ))}
                    </div>
                  </div>
                  <p>
                    <b>3. Đơn vị thực hiện sáng kiến, giải pháp: </b>
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="text"
                        name="implementingAgency"
                        value={dataMinutes?.implementingAgency}
                        style={{ width: "100%", display: "inline-block" }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span className="dot-line"></span>
                    </span>
                  </p>
                  <p>
                    <b>4. Ngày họp: </b>
                    <input
                      type="date"
                      name="date"
                      className="date-field"
                      value={dataMinutes?.date || moment().format("YYYY-MM-DD")}
                      onChange={(e) => handleChange(e)}
                    />
                  </p>
                  <p>
                    <b>5. Địa điểm: </b>
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="text"
                        name="place"
                        value={dataMinutes?.place || committeeInfo?.place}
                        style={{ width: "100%", display: "inline-block" }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span className="dot-line"></span>
                    </span>
                  </p>

                  <p>
                    <b>6. Thành viên hội đồng:</b> Theo QĐ số
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="text"
                        name="code"
                        value={dataMinutes?.code || committeeInfo?.code}
                        style={{
                          width: "70px",
                          textAlign: "center",
                          display: "inline-block",
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span className="dot-line"></span>
                    </span>
                    QĐ/BVĐKSS của
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="text"
                        name="supervisor"
                        value={dataMinutes?.supervisor}
                        style={{
                          width: "210px",
                          display: "inline-block",
                          marginLeft: "8px",
                        }}
                        required
                        onInvalid={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("Vui lòng nhập thông tin!");
                          validatorField(target.name, target.value);
                        }}
                        onInput={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("");
                          validatorField(target.name, target.value);
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span
                        className="dot-line"
                        style={{
                          color: `${
                            validatorInput.supervisor.valid ? "red" : "black"
                          }`,
                        }}
                      ></span>
                    </span>
                    về việc nghiệm thu sáng kiến cải tiến năm 2024
                  </p>
                  <p>
                    Tổng số 5. Có mặt:
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="number"
                        name="numAttendees"
                        value={dataMinutes?.numAttendees || ""}
                        required
                        onInvalid={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("Vui lòng nhập thông tin!");
                          validatorField(target.name, target.value);
                        }}
                        onInput={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("");
                          validatorField(target.name, target.value);
                        }}
                        style={{
                          width: "30px",
                          display: "inline-block",
                          marginLeft: "8px",
                        }}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          if (value >= 0 && value <= 5) {
                            handleChange(e);
                          } else {
                            e.target.value =
                              dataMinutes?.numAttendees !== undefined
                                ? String(dataMinutes.numAttendees)
                                : "";
                          }
                        }}
                      />
                      <span
                        className="dot-line"
                        style={{
                          color: `${
                            validatorInput.numAttendees.valid ? "red" : "black"
                          }`,
                        }}
                      ></span>
                    </span>
                    .Vắng mặt:{" "}
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="text"
                        name="numAbsent"
                        value={dataMinutes?.numAbsent}
                        style={{
                          width: "20px",
                          display: "inline-block",
                          marginLeft: "8px",
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        readOnly
                      />
                      <span className="dot-line"></span>
                    </span>
                  </p>
                  <p>
                    <b>7. Khách mời tham dự: </b>
                    <div className="relative">
                      <textarea
                        className="no-padding custom-input"
                        wrap="hard"
                        name="guest"
                        value={dataMinutes?.guest}
                        required
                        onInvalid={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("Vui lòng nhập thông tin!");
                          validatorField(target.name, target.value);
                        }}
                        onInput={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("");
                          validatorField(target.name, target.value);
                        }}
                        onChange={(e) => handleChange(e)}
                        style={{
                          fontFamily: "Times New Roman",
                          fontSize: "16px",
                          display: "block",
                          zIndex: "1",
                          width: "100%",
                          outline: "unset",
                          position: "relative",
                          resize: "none",
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                          height: "auto",
                          minHeight: "24px",
                          backgroundColor: "transparent",
                        }}
                      />
                      {Array.from({
                        length: isNaN(numRows?.guest)
                          ? 1
                          : numRows?.guest,
                      })?.map((item: any, index: any) => (
                        <span
                          key={index}
                          style={{
                            position: "absolute",
                            top: `${
                              (1 / numRows?.guest || 0) *
                              100 *
                              index
                            }%`,
                            right: "0",
                            left: "0",
                            width: "100%",
                            color: `${
                              validatorInput.guest.valid
                                ? "red"
                                : "black"
                            }`,
                            height: `24px`,
                            borderBottom: "1px dashed",
                            transform: "translateY(-2px)",
                            backgroundColor: "transparent",
                          }}
                        ></span>
                      ))}
                    </div>
                  </p>
                  <p>
                    <b>8. Nội dung chấm điểm: </b>
                  </p>
                  <table className="mark-table">
                    <thead>
                      <tr>
                        <td width={"50px"}>STT</td>
                        <td>Tiêu chí</td>
                        <td>Điểm chuẩn</td>
                        <td width={"100px"}>
                          <p className="m-0">Điểm đạt</p>
                          <p className="m-0">(trung bình)</p>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Tính khoa học</td>
                        <td>20</td>
                        <td>
                          <input
                            className="no-padding custom-input"
                            type="number"
                            name="scoreScientificNature"
                            min="0"
                            max="20"
                            required
                            value={dataMinutes?.scoreScientificNature || ""}
                            style={{
                              width: "100%",
                              display: "inline-block",
                              textAlign: "center",
                            }}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Tính mới, tính sáng tạo</td>
                        <td>20</td>
                        <td>
                          <input
                            className="no-padding custom-input"
                            type="number"
                            name="scoreCreativity"
                            min="0"
                            max="20"
                            required
                            value={dataMinutes?.scoreCreativity || ""}
                            style={{
                              width: "100%",
                              display: "inline-block",
                              textAlign: "center",
                            }}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Tính hiệu quả</td>
                        <td>30</td>
                        <td>
                          <input
                            className="no-padding custom-input"
                            type="number"
                            name="scoreEffectiveness"
                            min="0"
                            max="30"
                            required
                            value={dataMinutes?.scoreEffectiveness || ""}
                            style={{
                              width: "100%",
                              display: "inline-block",
                              textAlign: "center",
                            }}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Tính ứng dụng, khả thi</td>
                        <td>30</td>
                        <td>
                          <input
                            className="no-padding custom-input"
                            type="number"
                            name="scoreApplicability"
                            required
                            min="0"
                            max="30"
                            value={dataMinutes?.scoreApplicability || ""}
                            style={{
                              width: "100%",
                              display: "inline-block",
                              textAlign: "center",
                            }}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>Tổng số</td>
                        <td>100</td>
                        <td>
                          <input
                            className="no-padding custom-input"
                            type="number"
                            name="total"
                            value={(Number(dataMinutes?.scoreScientificNature) || 0) + (Number(dataMinutes?.scoreCreativity) || 0) + (Number(dataMinutes?.scoreEffectiveness) || 0) + (Number(dataMinutes?.scoreApplicability) || 0)}
                            readOnly
                            style={{
                              width: "100%",
                              display: "inline-block",
                              textAlign: "center",
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    <b>9. Kết luận và kiến nghị của Hội đồng: </b>
                    <div className="relative">
                      <textarea
                        className="no-padding custom-input"
                        title="Nhập nhận xét"
                        wrap="hard"
                        name="committeeRecommendation"
                        value={dataMinutes?.committeeRecommendation}
                        required
                        onInvalid={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("Vui lòng nhập thông tin!");
                          validatorField(target.name, target.value);
                        }}
                        onInput={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("");
                          validatorField(target.name, target.value);
                        }}
                        onChange={(e) => handleChange(e)}
                        style={{
                          fontFamily: "Times New Roman",
                          fontSize: "16px",
                          display: "block",
                          zIndex: "1",
                          width: "100%",
                          outline: "unset",
                          position: "relative",
                          resize: "none",
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                          height: "auto",
                          minHeight: "24px",
                          backgroundColor: "transparent",
                        }}
                      />
                      {Array.from({
                        length: isNaN(numRows?.committeeRecommendation)
                          ? 1
                          : numRows?.committeeRecommendation,
                      })?.map((item: any, index: any) => (
                        <span
                          key={index}
                          style={{
                            position: "absolute",
                            top: `${
                              (1 / numRows?.committeeRecommendation || 0) *
                              100 *
                              index
                            }%`,
                            right: "0",
                            left: "0",
                            width: "100%",
                            color: `${
                              validatorInput.committeeRecommendation.valid
                                ? "red"
                                : "black"
                            }`,
                            height: `24px`,
                            borderBottom: "1px dashed",
                            transform: "translateY(-2px)",
                            backgroundColor: "transparent",
                          }}
                        ></span>
                      ))}
                    </div>
                  </p>
                </div>

                <div className="signature-container">
                  <div className="left-signature">
                    <p>
                      <b>THƯ KÝ</b>
                    </p>
                    <p className="pt-4">{committeeInfo?.secretaryName}</p>
                  </div>

                  <div className="right-signature">
                    <p>
                      <b>CHỦ TỊCH HỘI ĐỒNG</b>
                    </p>
                    <p className="pt-4">{committeeInfo?.chairmanName}</p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="flex justify-content-center">
            <Button
              variant="primary"
              className="min-w-75px fw-bold"
              type="submit"
            >
              {"Gửi"}
            </Button>
            <Button
              variant="secondary"
              className="min-w-75px fw-bold"
              onClick={onHide}
            >
              Hủy
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </Modal>
  );
};
export default AcceptanceMinutesDialog;
