import { createContext, Dispatch, FC, SetStateAction, useState } from "react";
import MyTopic from "./MyTopic";
import "./MyTopic.scss";
import { initFilter, TMyTopic, TMyTopicFilter } from "./models/MyTopicModel";

export interface MyTopicContextProps {
  onReset: () => void;
  filter: TMyTopicFilter;
  setFilter: Dispatch<SetStateAction<TMyTopicFilter>>;
  myTopicList: TMyTopic[];
  setMyTopicList: Dispatch<SetStateAction<TMyTopic[]>>;
}

const initialContext = {
  onReset: () => {},
  filter: initFilter,
  setFilter: () => {},
  myTopicList: [],
  setMyTopicList: () => {}
};

export const MyTopicContext =
  createContext<MyTopicContextProps>(initialContext);

export const MyTopicProvider: FC = () => {
  const [filter, setFilter] = useState<TMyTopicFilter>(initFilter);
  const [myTopicList, setMyTopicList] = useState<TMyTopic[]>([]);

  return (
    <MyTopicContext.Provider
      value={{
        onReset: () => {},
        filter,
        setFilter,
        myTopicList,
        setMyTopicList
      }}
    >
      <div className="bg-white h-100">
        <div className="flex-1 flex flex-column">
          <div className="py-1">
            <MyTopic />
          </div>
        </div>
      </div>
    </MyTopicContext.Provider>
  );
};

export default MyTopicProvider;
