import { Form, Formik, FormikValues } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { StepperComponent } from "../../../../../_metronic/assets/ts/components";
import { AppContext } from "../../../appContext/AppContext";
import { CODE } from "../../../utils/Constant";
import {
  TAcceptanceCouncilDetails,
  TAcceptanceCouncilTopic,
  TAddAcceptanceCouncil,
  initAddAcceptanceCouncil
} from "../models/AcceptanceCouncilManagerModels";
import { getAcceptanceCouncilById } from "../services/services";
import { Step1 } from "./steps/Step1";
import { Step2 } from "./steps/Step2";
import { Step3 } from "./steps/Step3";

interface Props {
  show: boolean;
  onHide: () => void;
  id: string;
}

const DetailsAcceptanceCouncilDialog = (props: Props) => {
  const { show, onHide, id } = props;
  const { setIsLoading } = useContext(AppContext);

  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const formRef = useRef<any>(null);

  const [isSubmitButton, setSubmitButton] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState<TAcceptanceCouncilTopic[]>([]);
  const [regisPeriodId, setRegisPeriodId] = useState<string>("");
  const [details, setDetails] = useState<TAcceptanceCouncilDetails>({});

  useEffect(() => {
    if(!id) return;
    getDetails(id);
  }, [])

  const getDetails = async (id: string) => {
    try {
      setIsLoading(true);
      let { data } = await getAcceptanceCouncilById(id);
      if (data?.code === CODE.SUCCESS) {
        setDetails(data?.data);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    stepper.current.goPrev();
    setSubmitButton(
      stepper.current.currentStepIndex === stepper.current.totatStepsNumber
    );
  };

  const submitStep = (values: TAddAcceptanceCouncil, actions: FormikValues) => {
    if (!stepper.current) {
      return;
    }
    if (values?.regisPeriodId) {
      setRegisPeriodId(values?.regisPeriodId);
    }

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      setSubmitButton(
        stepper.current.currentStepIndex ===
          stepper.current.totatStepsNumber! - 1
      );
      stepper.current.goNext();
    } else {
      actions.resetForm();
    }
  };

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }

    loadStepper();
  }, [stepperRef]);

  const handleSubmitStep = () => {
    formRef.current?.handleSubmit();
  }

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="xl"
      className="spaces h-calc-vh-50"
      scrollable
    >
      <div
        ref={stepperRef}
        className="stepper stepper-links d-flex flex-column"
        id="kt_create_account_stepper"
      >
        <Modal.Header className="header-modal min-h-40px" closeButton>
          <Modal.Title className="py-4">
            Chi tiết hội đồng nghiệm thu
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="spaces p-0 bg-white mxh-65vh acceptance-council">
          <div className="card">
            <div className="card-body p-0">
              <div className="stepper-nav">
                <div
                  className="stepper-item current"
                  data-kt-stepper-element="nav"
                >
                  <h3 className="stepper-title">1. Thông tin chung</h3>
                </div>

                <div className="stepper-item" data-kt-stepper-element="nav">
                  <h3 className="stepper-title">2. Thành viên hội đồng</h3>
                </div>

                <div className="stepper-item" data-kt-stepper-element="nav">
                  <h3 className="stepper-title">3. Danh sách đề tài</h3>
                </div>
              </div>

              <Formik<TAddAcceptanceCouncil>
                innerRef={formRef}
                enableReinitialize
                validationSchema={Yup.object({})}
                initialValues={{
                  ...initAddAcceptanceCouncil,
                  members: details?.members || [],
                  topics: details?.topics || [],
                }}
                onSubmit={submitStep}
              >
                {({
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <Form
                    className="mx-auto px-10 w-100 py-4"
                    id="kt_create_council-form"
                  >
                    <div className="current" data-kt-stepper-element="content">
                      <Step1
                        values={values}
                        details={details}
                        errors={errors}
                        isView
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                    </div>

                    <div data-kt-stepper-element="content">
                      <Step2
                        values={values}
                        details={details}
                        errors={errors}
                        isView
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                    </div>

                    <div data-kt-stepper-element="content">
                      <Step3
                        setSelectedTopics={setSelectedTopics}
                        regisPeriodId={regisPeriodId}
                        isView
                        details={details}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-stack w-100">
            <div className="mr-2">
              <button
                onClick={prevStep}
                type="button"
                className="btn btn-primary me-3"
                data-kt-stepper-action="previous"
              >
                <i className="bi bi-chevron-bar-left m-0"></i>
                Quay lại
              </button>
            </div>
            <div>
              {!isSubmitButton && (
                <button
                  type="submit"
                  className="btn btn-lg btn-primary"
                  onClick={handleSubmitStep}
                >
                  <span className="indicator-label">
                    {!isSubmitButton && "Tiếp theo"}
                    <i className="bi bi-chevron-bar-right m-0"></i>
                  </span>
                </button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default DetailsAcceptanceCouncilDialog;
