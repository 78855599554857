import { FC, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

interface Props {
  show: boolean
  onCloseClick?: () => void
  onYesClick?: (data: any) => void
  onCancelClick?: () => void
  title?: string
  message?: string
  yes?: string
  cancel?: string
  close?: string
  className?: string
  hasFile?: boolean;
  acceptFileTypes?: string[];
  errorText?: { file: string };
  setErrorText?: (value: { file: string }) => void;
}

const ConfirmDialog: FC<Props> = (props) => {
  const { show, onCloseClick, onYesClick, onCancelClick, title, message, yes, cancel, close, className, hasFile,acceptFileTypes,errorText,setErrorText } = props

  const [file, setFile] = useState<any>();
 
  const handleChangeFile = async (e: any) => {
    let file = e?.target?.files?.[0];
    if(!file) return;
    if(acceptFileTypes && !acceptFileTypes.includes(file.type)) {
      toast.warning(`Chi được phép tải lên tệp tin nên định dạng ${acceptFileTypes.toString().replace(/,/g, ', ')}.`);
      e.target.value = '';
    }else{
      setFile(file);
      if (setErrorText) {
        setErrorText({ 
          ...errorText
          ,file: '' });
      }
    }
  };
  return (
    <Modal
      show={show}
      onHide={onCloseClick}
      centered
      animation
      className={`background__modal dialog-background ${className}`}
    >
      <Modal.Header className="header-modal" closeButton>
        <Modal.Title className='text-white text-uppercase'>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-4'>
        <h5 className='m-0'>{message}</h5>
        {hasFile && (
          <Form.Group controlId="formFile" className="flex-1 pt-4">
            <Form.Label>Chọn file</Form.Label>
            <Form.Control
              type="file"
              name="file"
              size="sm"
              accept={".pdf, .doc, .docx"}
              onChange={(e) => handleChangeFile(e)}
              isInvalid={!!errorText?.file}
            />
            
        <Form.Control.Feedback type='invalid'>{errorText?.file}</Form.Control.Feedback>
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-end p-2 border-top'>
        {cancel && (
          <Button
            className="spaces btn-secondary px-16"
            onClick={onCancelClick}
          >
            {cancel}
          </Button>
        )}
        {yes && (
          <Button className="min-w-50px btn btn-primary" onClick={() => onYesClick && onYesClick({ file })}>
            {yes}
          </Button>
        )}
        {close && (
          <Button
            className="btn-outline min-w-80px"
            onClick={onCloseClick}
          >
            {close}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export { ConfirmDialog }