import { OptionType } from "../../../category-personnel/model/PersonnelModel";

export const TRANG_THAI: OptionType[] = [
  {
    id: 0,
    title: "Toàn bộ",
    value: "all"
  },
  {
    id: 1,
    title: "Mở",
    value: "1"
  },
  {
    id: 2,
    title: "Đóng",
    value: "2"
  }
]

export const CONFIRM_TYPE = {
  OPEN: "mở đợt đăng ký",
  CLOSE: "đóng đợt đăng ký",
  DELETE: "xóa đợt đăng ký"
}