import { columnNamesType, OctTable } from "@oceantech/oceantech-ui";
import { Field, FieldArray, Form, Formik } from "formik";
import React, { FC, useContext } from "react";
import { Col, FormCheck, Modal, Row, Form as FormBS } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AppContext } from "../../../appContext/AppContext";
import { TextFieldCustom } from "../../../category-personnel/components/text-field-custom/TextFieldCustom";
import { DEPARTMENTS } from "../../../dang-ky-de-tai/constants/constants";
import { addProposal, searchDepartment } from "../../../dang-ky-de-tai/services/services";
import { TTopicDetails } from "../../../quan-ly-de-tai/de-tai/models/TopicModel";
import { CODE, DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE } from "../../../utils/Constant";
import { TMainResearcher, TTopicSecretary } from "../../models/MyTopicModel";
import AutocompleteObjectV2 from '../../../component/AutocompleteObjectV2';
import { searchEmployee } from "../../../quan-ly-hoi-dong/khoa-hoc/services/services";

type IProps = {
  memberList: TMainResearcher[];
  topicInfo: TTopicDetails;
  mainResearcher: TMainResearcher;
  coMainResearcher: TMainResearcher;
  topicSecretary: TTopicSecretary;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  formRef: React.MutableRefObject<any>;
};
const TabProposal: FC<IProps> = (props) => {
  const { memberList, topicInfo, topicSecretary, mainResearcher, coMainResearcher, isEdit, setIsEdit, formRef } = props;
  const { setIsLoading } = useContext(AppContext);

  let validationSchema = Yup.object({
    urgency: Yup.string().required("Vui lòng nhập trường này!"),
    objective: Yup.string().required("Vui lòng nhập trường này!"),
    content: Yup.string().required("Vui lòng nhập trường này!"),
    estimatedTimeframe: Yup.string().required("Vui lòng nhập trường này!"),
    expectedOutcome: Yup.string().required("Vui lòng nhập trường này!"),
    feasibility: Yup.string().required("Vui lòng nhập trường này!"),
    otherInformation: Yup.string().required("Vui lòng nhập trường này!"),
  });

  const handleFormSubmit = async (values: TTopicDetails) => {
    let members = [
      { ...values.mainResearcher, typeId: 1 },
      { ...values.coMainResearcher, typeId: 5 },
      { ...values.topicSecretary, typeId: 2 },
      { ...values.participatingMember1, typeId: 3 },
      { ...values.participatingMember2, typeId: 3 }
    ];
    let dataProposal = {
      id: topicInfo.id,
      regisPeriodId: topicInfo.regisPeriodId,
      name: values.name,
      urgency: values.urgency,
      objective: values.objective,
      content: values.content,
      estimatedTimeframe: values.estimatedTimeframe,
      expectedOutcome: values.expectedOutcome,
      feasibility: values.feasibility,
      otherInformation: values.otherInformation,
      members,
    };
    try {
      setIsLoading(true);
      let { data } = await addProposal(dataProposal);
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        toast.success("Chỉnh sửa đề xuất thành công");
      } else {
        setIsLoading(false);
        let errorMesage =
          data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {
    }
    setIsLoading(false);
    setIsEdit(false);
  };

  const handleCheckedChange = (checked: boolean, values: TTopicDetails, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, name: string) => {
    if(checked === true){
      let newParticipatingMembers = values?.participatingMembers;
      newParticipatingMembers?.pop();
      setFieldValue("participatingMembers", newParticipatingMembers);
    }else if(checked === false){
      let newParticipatingMembers = values?.participatingMembers;
      newParticipatingMembers?.push({});
      setFieldValue("participatingMembers", newParticipatingMembers);
      setFieldValue(name, {});
    }
  }

  return (
    <Formik<TTopicDetails>
      enableReinitialize
      innerRef={formRef}
      initialValues={{ ...topicInfo, mainResearcher, hasCoMainResearcher: !!coMainResearcher, coMainResearcher, hasTopicSecretary: !!topicSecretary, topicSecretary, participatingMembers: memberList }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleFormSubmit(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Modal.Body className="spaces p-0 mt-5 bg-white">
              <div className="form spaces p-10">
                <>
                  <Row className="py-2">
                    <Col xs={8}>
                      <div className="flex align-items-center">
                        <div className="label min-w-100px fw-bold">
                          Tên đề tài
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name="name"
                            disabled={!isEdit}
                            values={values.name}
                            errors={errors.name}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center">
                        <div className="label min-w-100px fw-bold">
                          Ngày đăng ký
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="date"
                            title=""
                            name="date"
                            disabled={!isEdit}
                            values={values.date}
                            errors={errors.date}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col
                      xs={12}
                      className="label min-w-100px fw-bold pb-2 text-gray"
                    >
                      Chủ nhiệm đề tài/Nghiên cứu viên chính:
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center px-2">
                        <div className="label min-w-100px fw-bold">Họ tên</div>
                        <div className="flex-1">
                          {isEdit ? (
                            <AutocompleteObjectV2
                              options={[]}
                              name="mainResearcher"
                              onChange={(option: any) => {
                                setFieldValue("mainResearcher", {
                                  ...option,
                                  gender: option?.gender?.code,
                                  duty: option?.viTriCongViecText,
                                  department: option?.phongBanText,
                                  position: option?.chucVuText,
                                  email: option?.emailCaNhan,
                                });
                              }}
                              searchFunction={() =>
                                searchEmployee({
                                  pageIndex: DEFAULT_PAGE_INDEX,
                                  pageSize: MAX_PAGE_SIZE,
                                  donViCongTacId:
                                    "8e482f21-2414-422a-9bc1-fb997776bc2b",
                                })
                              }
                              value={values?.mainResearcher}
                              errors={errors?.mainResearcher}
                              searchObject={{}}
                              getOptionLabel={(e: any) => e?.name}
                            />
                          ) : (
                            <TextFieldCustom
                              type="text"
                              title=""
                              name="mainResearcher.name"
                              disabled
                              values={values.mainResearcher?.name}
                              errors={errors.mainResearcher}
                              handleChange={handleChange}
                            />
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center">
                        <div className="label min-w-100px fw-bold">
                          Khoa phòng
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name="mainResearcher.department"
                            disabled
                            values={values.mainResearcher?.department || ""}
                            errors={errors.mainResearcher}
                            handleChange={handleChange}
                            // options={DEPARTMENTS}
                          />
                          {/* <AutocompleteObjectV2
                              options={[]}
                              isSearchDefauilt={true}
                              name={"mainResearcher.department"}
                              onChange={(e: any) =>  { setFieldValue("mainResearcher.department", e?.name || null) }}
                              isDisabled={!isEdit}
                              searchFunction={() =>
                                searchDepartment({
                                  pageIndex: DEFAULT_PAGE_INDEX,
                                  pageSize: MAX_PAGE_SIZE,
                                  level: 1
                                })
                              }
                              value={values.mainResearcher?.departmentOption}
                              errors={errors.mainResearcher}
                              searchObject={{}}
                              getOptionLabel={(e: any) => e?.name}
                            /> */}
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center">
                        <div className="label min-w-100px fw-bold">Chức vụ</div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name="mainResearcher.position"
                            disabled
                            values={values.mainResearcher?.position || ""}
                            errors={errors.mainResearcher}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center px-2 pt-6">
                        <div className="label min-w-100px fw-bold">
                          Điện thoại
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name="mainResearcher.phone"
                            disabled
                            values={values.mainResearcher?.phone || ""}
                            errors={errors.mainResearcher}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center pt-6">
                        <div className="label min-w-100px fw-bold">Email</div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name="mainResearcher.email"
                            disabled
                            values={values.mainResearcher?.email || ""}
                            errors={errors.mainResearcher}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col
                      xs={12}
                      className="label min-w-100px fw-bold pb-2 text-gray"
                    >
                      Đồng chủ nhiệm:
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center px-2">
                        <div className="label min-w-100px fw-bold">Họ tên</div>
                        <div className="flex-1">
                          {isEdit ? (
                            <AutocompleteObjectV2
                              options={[]}
                              name="coMainResearcher"
                              onChange={(option: any) => {
                                setFieldValue("coMainResearcher", {
                                  ...option,
                                  gender: option?.gender?.code,
                                  duty: option?.viTriCongViecText,
                                  department: option?.phongBanText,
                                  position: option?.chucVuText,
                                  email: option?.emailCaNhan,
                                });
                              }}
                              searchFunction={() =>
                                searchEmployee({
                                  pageIndex: DEFAULT_PAGE_INDEX,
                                  pageSize: MAX_PAGE_SIZE,
                                  donViCongTacId:
                                    "8e482f21-2414-422a-9bc1-fb997776bc2b",
                                })
                              }
                              value={values?.coMainResearcher}
                              errors={errors?.coMainResearcher}
                              searchObject={{}}
                              getOptionLabel={(e: any) => e?.name}
                            />
                          ) : (
                            <TextFieldCustom
                              type="text"
                              title=""
                              name="coMainResearcher.name"
                              disabled
                              values={values.coMainResearcher?.name}
                              errors={errors.coMainResearcher}
                              handleChange={handleChange}
                            />
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center">
                        <div className="label min-w-100px fw-bold">
                          Khoa phòng
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            disabled
                            name="coMainResearcher.department"
                            values={values.coMainResearcher?.department || ""}
                            errors={errors.coMainResearcher}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center">
                        <div className="label min-w-100px fw-bold">Chức vụ</div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            disabled
                            name="coMainResearcher.position"
                            values={values.coMainResearcher?.position || ""}
                            errors={errors.coMainResearcher}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center px-2 pt-6">
                        <div className="label min-w-100px fw-bold">
                          Điện thoại
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            disabled
                            name="coMainResearcher.phone"
                            values={values.coMainResearcher?.phone || ""}
                            errors={errors.coMainResearcher}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="flex align-items-center pt-6">
                        <div className="label min-w-100px fw-bold">Email</div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            disabled
                            name="coMainResearcher.email"
                            values={values.coMainResearcher?.email || ""}
                            errors={errors.coMainResearcher}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="py-2">
                      <Col xs={12}>
                        <div className="flex align-items-center">
                          <div className="label fw-bold text-pri spaces pr-16">
                            <Field
                              type="checkbox"
                              name="hasCoMainResearcher"
                              checked={values.hasCoMainResearcher}
                              disabled={!isEdit}
                              onChange={(e: any) => {
                                handleChange(e);
                                handleCheckedChange(e?.target?.checked, values, setFieldValue, "coMainResearcher");
                              }}
                            />
                          </div>
                          <div className="flex-1">
                            <FormBS.Label className="label fw-bold text-gray">
                              Đồng chủ nhiệm
                            </FormBS.Label>
                          </div>
                        </div>
                      </Col>
                      {values.hasCoMainResearcher && (
                        <>
                          <Col xs={4}>
                            <div className="flex align-items-center px-2">
                              <div className="label min-w-100px fw-bold">
                                Họ tên
                              </div>
                              <div className="flex-1">
                                {isEdit ? (
                                  <AutocompleteObjectV2
                                    options={[]}
                                    name="coMainResearcher"
                                    onChange={(option: any) =>  { setFieldValue("coMainResearcher", 
                                      {
                                        ...option,
                                        gender: option?.gender?.code,
                                        duty: option?.viTriCongViecText,
                                        department: option?.phongBanText,
                                        position: option?.chucVuText,
                                        email: option?.emailCaNhan,
                                      }
                                    )}}
                                    searchFunction={() =>
                                      searchEmployee({
                                        pageIndex: DEFAULT_PAGE_INDEX,
                                        pageSize: MAX_PAGE_SIZE,
                                        donViCongTacId: "8e482f21-2414-422a-9bc1-fb997776bc2b"
                                      })
                                    }
                                    value={values?.coMainResearcher}
                                    errors={errors?.coMainResearcher}
                                    searchObject={{}}
                                    getOptionLabel={(e: any) => e?.name}
                                  />
                                ): (
                                  <TextFieldCustom
                                    type="text"
                                    title=""
                                    name="coMainResearcher.name"
                                    disabled
                                    values={values.coMainResearcher?.name}
                                    errors={errors.coMainResearcher}
                                    handleChange={handleChange}
                                  />
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col xs={4}>
                            <div className="flex align-items-center">
                              <div className="label min-w-100px fw-bold">
                                Khoa phòng
                              </div>
                              <div className="flex-1">
                                <TextFieldCustom
                                  type="text"
                                  title=""
                                  disabled
                                  name="coMainResearcher.department"
                                  values={values.coMainResearcher?.department || ""}
                                  errors={errors.coMainResearcher}
                                  handleChange={handleChange}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xs={4}>
                            <div className="flex align-items-center">
                              <div className="label min-w-100px fw-bold">
                                Chức vụ
                              </div>
                              <div className="flex-1">
                                <TextFieldCustom
                                  type="text"
                                  title=""
                                  disabled
                                  name="coMainResearcher.position"
                                  values={values.coMainResearcher?.position || ""}
                                  errors={errors.coMainResearcher}
                                  handleChange={handleChange}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xs={4}>
                            <div className="flex align-items-center px-2 pt-6">
                              <div className="label min-w-100px fw-bold">
                                Điện thoại
                              </div>
                              <div className="flex-1">
                                <TextFieldCustom
                                  type="text"
                                  title=""
                                  disabled
                                  name="coMainResearcher.phone"
                                  values={values.coMainResearcher?.phone || ""}
                                  errors={errors.coMainResearcher}
                                  handleChange={handleChange}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xs={4}>
                            <div className="flex align-items-center pt-6">
                              <div className="label min-w-100px fw-bold">Email</div>
                              <div className="flex-1">
                                <TextFieldCustom
                                  type="text"
                                  title=""
                                  disabled
                                  name="coMainResearcher.email"
                                  values={values.coMainResearcher?.email || ""}
                                  errors={errors.coMainResearcher}
                                  handleChange={handleChange}
                                />
                              </div>
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row className="py-2">
                      <Col xs={12}>
                        <div className="flex align-items-center">
                          <div className="label fw-bold text-pri spaces pr-16">
                            <Field
                              type="checkbox"
                              name="hasTopicSecretary"
                              disabled={!isEdit}
                              checked={values.hasTopicSecretary}
                              onChange={(e: any) => {
                                handleChange(e);
                                handleCheckedChange(e?.target?.checked, values, setFieldValue, "topicSecretary");
                              }}
                            />
                          </div>
                          <div className="flex-1">
                            <FormBS.Label>
                              <span className="label min-w-100px fw-bold text-gray">
                                Thư ký đề tài
                              </span>
                              <span className="fst-italic fw-light text-gray">
                                {" "}
                                (tham gia với &gt;50% thời gian, nếu có):
                              </span>
                            </FormBS.Label>
                          </div>
                        </div>
                      </Col>
                      {values.hasTopicSecretary && (
                        <>
                          <Col xs={4}>
                            <div className="flex align-items-center px-2">
                              <div className="label min-w-100px fw-bold">
                                Họ tên
                              </div>
                              <div className="flex-1">
                                {isEdit ? (
                                  <AutocompleteObjectV2
                                    options={[]}
                                    name="topicSecretary"
                                    onChange={(option: any) =>  { setFieldValue("topicSecretary", 
                                      {
                                        ...option,
                                        gender: option?.gender?.code,
                                        duty: option?.viTriCongViecText,
                                        department: option?.phongBanText,
                                        position: option?.chucVuText,
                                        email: option?.emailCaNhan,
                                      }
                                    )}}
                                    searchFunction={() =>
                                      searchEmployee({
                                        pageIndex: DEFAULT_PAGE_INDEX,
                                        pageSize: MAX_PAGE_SIZE,
                                        donViCongTacId: "8e482f21-2414-422a-9bc1-fb997776bc2b"
                                      })
                                    }
                                    value={values?.topicSecretary}
                                    errors={errors?.topicSecretary}
                                    searchObject={{}}
                                    getOptionLabel={(e: any) => e?.name}
                                  />
                                ): (
                                  <TextFieldCustom
                                    type="text"
                                    title=""
                                    name="topicSecretary.name"
                                    disabled
                                    values={values.topicSecretary?.name}
                                    errors={errors.topicSecretary}
                                    handleChange={handleChange}
                                  />
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col xs={4}>
                            <div className="flex align-items-center">
                              <div className="label min-w-100px fw-bold">
                                Khoa phòng
                              </div>
                              <div className="flex-1">
                                <TextFieldCustom
                                  type="text"
                                  title=""
                                  disabled
                                  name="topicSecretary.department"
                                  values={values.topicSecretary?.department || ""}
                                  errors={errors.topicSecretary}
                                  handleChange={handleChange}
                                  // options={DEPARTMENTS}
                                />
                                {/* <AutocompleteObjectV2
                                  options={[]}
                                  isSearchDefauilt={true}
                                  name={"topicSecretary.department"}
                                  onChange={(e: any) =>  { setFieldValue("topicSecretary.department", e?.name || null) }}
                                  searchFunction={() =>
                                    searchDepartment({
                                      pageIndex: DEFAULT_PAGE_INDEX,
                                      pageSize: MAX_PAGE_SIZE,
                                      level: 1
                                    })
                                  }
                                  value={values.topicSecretary?.departmentOption}
                                  errors={errors.topicSecretary}
                                  searchObject={{}}
                                  getOptionLabel={(e: any) => e?.name}
                                /> */}
                              </div>
                            </div>
                          </Col>
                          <Col xs={4}>
                            <div className="flex align-items-center">
                              <div className="label min-w-100px fw-bold">
                                Chức vụ
                              </div>
                              <div className="flex-1">
                                <TextFieldCustom
                                  type="text"
                                  title=""
                                  disabled
                                  name="topicSecretary.position"
                                  values={values.topicSecretary?.position || ""}
                                  errors={errors.topicSecretary}
                                  handleChange={handleChange}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xs={4}>
                            <div className="flex align-items-center px-2 pt-6">
                              <div className="label min-w-100px fw-bold">
                                Điện thoại
                              </div>
                              <div className="flex-1">
                                <TextFieldCustom
                                  type="text"
                                  title=""
                                  disabled
                                  name="topicSecretary.phone"
                                  values={values.topicSecretary?.phone || ""}
                                  errors={errors.topicSecretary}
                                  handleChange={handleChange}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xs={4}>
                            <div className="flex align-items-center pt-6">
                              <div className="label min-w-100px fw-bold">Email</div>
                              <div className="flex-1">
                                <TextFieldCustom
                                  type="text"
                                  title=""
                                  disabled
                                  name="topicSecretary.email"
                                  values={values.topicSecretary?.email || ""}
                                  errors={errors.topicSecretary}
                                  handleChange={handleChange}
                                />
                              </div>
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  <Row className="py-2">
                      <FieldArray
                        name="participatingMembers"
                        render={() => (
                          <>
                            {values?.participatingMembers?.map((item, index) => (
                              <React.Fragment key={index}>
                                <Col
                                  xs={12}
                                  className="label min-w-100px fw-bold pb-2 text-gray"
                                >
                                  <div className="flex justify-content-between align-items-center">
                                    <p className="m-0">Thành viên tham gia {index + 1}</p>
                                  </div>
                                </Col>
                                <Col xs={4}>
                                  <div className="flex align-items-center px-2">
                                    <div className="label min-w-100px fw-bold">
                                      Họ tên
                                    </div>
                                    <div className="flex-1">
                                      {isEdit ? (
                                        <AutocompleteObjectV2
                                          options={[]}
                                          name={`participatingMembers.${index}`}
                                          onChange={(option: any) =>  { setFieldValue(`participatingMembers.${index}`, 
                                            {
                                              ...option,
                                              gender: option?.gender?.code,
                                              duty: option?.viTriCongViecText,
                                              department: option?.phongBanText,
                                              position: option?.chucVuText,
                                              email: option?.emailCaNhan,
                                            }
                                          )}}
                                          searchFunction={() =>
                                            searchEmployee({
                                              pageIndex: DEFAULT_PAGE_INDEX,
                                              pageSize: MAX_PAGE_SIZE,
                                              donViCongTacId: "8e482f21-2414-422a-9bc1-fb997776bc2b"
                                            })
                                          }
                                          value={values?.participatingMembers?.[index]}
                                          errors={errors?.participatingMembers?.[index]}
                                          searchObject={{}}
                                          getOptionLabel={(e: any) => e?.name}
                                        />
                                      ): (
                                        <TextFieldCustom
                                          type="text"
                                          title=""
                                          name={`participatingMembers.${index}`}
                                          disabled
                                          values={values.participatingMembers?.[index]?.name}
                                          errors={errors.participatingMembers?.[index]}
                                          handleChange={handleChange}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={4}>
                                  <div className="flex align-items-center">
                                    <div className="label min-w-100px fw-bold">
                                      Khoa phòng
                                    </div>
                                    <div className="flex-1">
                                      <TextFieldCustom
                                        type="text"
                                        title=""
                                        disabled
                                        name={`participatingMembers.${index}.department`}
                                        values={values.participatingMembers?.[index]?.department || ""}
                                        errors={errors.participatingMembers?.[index]}
                                        handleChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={4}>
                                  <div className="flex align-items-center">
                                    <div className="label min-w-100px fw-bold">
                                      Chức vụ
                                    </div>
                                    <div className="flex-1">
                                      <TextFieldCustom
                                        type="text"
                                        title=""
                                        disabled
                                        name={`participatingMembers.${index}.position`}
                                        values={values.participatingMembers?.[index]?.position || ""}
                                        errors={errors.participatingMembers?.[index]}
                                        handleChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={4}>
                                  <div className="flex align-items-center px-2 pt-6">
                                    <div className="label min-w-100px fw-bold">
                                      Điện thoại
                                    </div>
                                    <div className="flex-1">
                                      <TextFieldCustom
                                        type="text"
                                        title=""
                                        disabled
                                        name={`participatingMembers.${index}.phone`}
                                        values={values.participatingMembers?.[index]?.phone || ""}
                                        errors={errors.participatingMembers?.[index]}
                                        handleChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={4}>
                                  <div className="flex align-items-center pt-6">
                                    <div className="label min-w-100px fw-bold">Email</div>
                                    <div className="flex-1">
                                      <TextFieldCustom
                                        type="text"
                                        title=""
                                        disabled
                                        name={`participatingMembers.${index}.email`}
                                        values={values.participatingMembers?.[index]?.email || ""}
                                        errors={errors.participatingMembers?.[index]}
                                        handleChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </React.Fragment>
                            ))}
                          </>
                        )}
                      />
                    </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        1. Giải thích về tính cấp thiết của đề tài
                      </span>
                      <span className="fst-italic">
                        {" "}
                        (Nêu tầm quan trọng, bức xúc, cấp bách)
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="urgency"
                      disabled={!isEdit}
                      values={values.urgency}
                      errors={errors.urgency}
                      handleChange={handleChange}
                    />
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        2. Mục tiêu dự kiến
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="objective"
                      disabled={!isEdit}
                      values={values.objective}
                      errors={errors.objective}
                      handleChange={handleChange}
                    />
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        3. Nội dung
                      </span>
                      <span className="fst-italic">
                        {" "}
                        (Trình bày phương pháp tiếp cận, thiết kế nghiên cứu và
                        nội dung nghiên cứu)
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="content"
                      disabled={!isEdit}
                      values={values.content}
                      errors={errors.content}
                      handleChange={handleChange}
                    />
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        4. Ước tính thời gian nghiên cứu
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="estimatedTimeframe"
                      disabled={!isEdit}
                      values={values.estimatedTimeframe}
                      errors={errors.estimatedTimeframe}
                      handleChange={handleChange}
                    />
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        5. Dự kiến sản phẩm
                      </span>
                      <span className="fst-italic">
                        {" "}
                        (Quy trình/bài báo/đào tạo)
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="expectedOutcome"
                      disabled={!isEdit}
                      values={values.expectedOutcome}
                      errors={errors.expectedOutcome}
                      handleChange={handleChange}
                    />
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        6. Khả năng ứng dụng vào Trung tâm/khoa phòng/bệnh viện
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="feasibility"
                      disabled={!isEdit}
                      values={values.feasibility}
                      errors={errors.feasibility}
                      handleChange={handleChange}
                    />
                  </Row>
                  <Row className="py-2">
                    <Col xs={12} className="pb-2">
                      <span className="label min-w-100px fw-bold">
                        7. Thông tin khác
                      </span>
                      <span className="fst-italic">
                        {" "}
                        (nếu có, đề tài dựa vào chương trình đề tài khác)
                      </span>
                    </Col>
                    <TextFieldCustom
                      className="min-h-90px"
                      type="text"
                      as={"textarea"}
                      title=""
                      name="otherInformation"
                      disabled={!isEdit}
                      values={values.otherInformation}
                      errors={errors.otherInformation}
                      handleChange={handleChange}
                    />
                  </Row>
                </>
              </div>
            </Modal.Body>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TabProposal;
