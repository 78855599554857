import { Form, Formik } from "formik";
import { Button, Col, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import { TextFieldCustom } from "../../category-personnel/components/text-field-custom/TextFieldCustom";
import { initImplementOfficer, TImplementOfficer } from "../models/MyTopicModel";
import { MEMBER_TYPE } from "../../utils/Constant";
import { generateSecureRandomId } from "../../utils/AppFunction";

interface Props {
  show: boolean;
  onHide: () => void;
  onAddOfficer: (officer: TImplementOfficer) => void;
  data: TImplementOfficer;
}

const AddOfficerDialog = (props: Props) => {
  const { show, onHide, onAddOfficer, data } = props;

  const validationSchema = Yup.object({});

  const handleSubmit = async (values: TImplementOfficer) => {
    onAddOfficer({
      ...values,
      ...(values?.id ? { id: values?.id } : {fakeId: values?.fakeId || generateSecureRandomId()}),
      typeId: MEMBER_TYPE.CAN_BO_THUC_HIEN,
    });
    onHide();
  };

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="lg"
      scrollable
      className="spaces h-calc-vh-50"
    >
      <Formik
        enableReinitialize
        initialValues={(data?.fakeId || data?.id) ? data : initImplementOfficer}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header className="header-modal min-h-40px" closeButton>
              <Modal.Title className="py-4">
                Cán bộ thực hiện đề tài
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="spaces p-0 bg-white">
              <div className="spaces p-10">
                <Row className="py-2">
                  <Col xs={4} className="pb-4">
                    <TextFieldCustom
                      type="text"
                      title="Họ và tên"
                      name="name"
                      values={values?.name}
                      errors={errors.name}
                      handleChange={handleChange}
                    />
                  </Col>
                  <Col xs={4} className="pb-4">
                    <TextFieldCustom
                      type="text"
                      title="Học hàm"
                      name="academicRank"
                      values={values?.academicRank}
                      errors={errors.academicRank}
                      handleChange={handleChange}
                    />
                  </Col>
                  <Col xs={4} className="pb-4">
                    <TextFieldCustom
                      type="text"
                      title="Học vị"
                      name="degree"
                      values={values?.degree}
                      errors={errors.degree}
                      handleChange={handleChange}
                    />
                  </Col>
                  <Col xs={4} className="pb-4">
                    <TextFieldCustom
                      type="text"
                      title="Chức danh"
                      name="scientificTitle"
                      values={values?.scientificTitle}
                      errors={errors.scientificTitle}
                      handleChange={handleChange}
                    />
                  </Col>
                  <Col xs={8} className="pb-4">
                    <TextFieldCustom
                      type="text"
                      title="Tổ chức công tác"
                      name="officeCurrently"
                      values={values?.officeCurrently}
                      errors={errors.officeCurrently}
                      handleChange={handleChange}
                    />
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer className="flex justify-content-center">
              <Button
                variant="primary"
                className="min-w-75px fw-bold"
                type="submit"
              >
                Lưu
              </Button>
              <Button
                variant="secondary"
                className="min-w-75px fw-bold"
                onClick={onHide}
              >
                Hủy
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddOfficerDialog;
