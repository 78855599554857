import moment from "moment";
import React, { useContext, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import { CODE, MEMBER_TYPE } from "../../../utils/Constant";
import { getCommittees, getTopicById, regisScienceMinute } from "../services/services";
import { TDataScienceMinutes, TMember } from "../models/TopicModel";

interface Props {
  show: boolean;
  onHide: () => void;
  id: string;
  onRefresh: () => Promise<void>;
}

const ScienceMinutesDialog = (props: Props) => {
  const { show, onHide, id, onRefresh } = props;
  const { setIsLoading } = useContext(AppContext);
  const [dataMinutes, setDataMinutes] = React.useState<TDataScienceMinutes>({});
  const [topicDetails, setTopicDetails] = React.useState<any>({});
  const [numRows, setNumRows] = React.useState<any>({});
  const [scienceCommitteeId, setScienceCommitteeId] = React.useState<string>("");
  const [committeeInfo, setCommitteeInfo] = React.useState<any>({});
  const [validatorInput, setValidatorInput] = React.useState<any>({
    place: {
      valid: false,
      name: "place",
    },
    dayDate: {
      valid: false,
      name: "dayDate",
    },
    monthDate: {
      valid: false,
      name: "monthDate",
    },
    yearDate: {
      valid: false,
      name: "yearDate",
    },
    supervisor: {
      valid: false,
      name: "supervisor",
    },
    numAttendees: {
      valid: false,
      name: "numAttendees",
    },
    presenter: {
      valid: false,
      name: "presenter",
    },
    presenterDuty: {
      valid: false,
      name: "presenterDuty",
    },
    committeeRecommendation: {
      valid: false,
      name: "committeeRecommendation",
    },
    topicPiName: {
      valid: false,
      name: "topicPiName",
    },
    scoreTotal: {
      valid: false,
      name: "scoreTotal",
    },
    scoreInitialAverage: {
      valid: false,
      name: "scoreInitialAverage",
    },
    scoreInitialTotal: {
      valid: false,
      name: "scoreInitialTotal",
    },
    scoreValidTotal: {
      valid: false,
      name: "scoreValidTotal",
    },
    scoreFinalAverage: {
      valid: false,
      name: "scoreFinalAverage",
    },
  });
  const validatorField = (name: any, value: any) => {
    if (name === validatorInput[name].name) {
      if (!value) {
        setValidatorInput({
          ...validatorInput,
          [name]: {
            ...validatorInput[name],
            valid: true,
          },
        });
      } else {
        setValidatorInput({
          ...validatorInput,
          [name]: {
            ...validatorInput[name],
            valid: false,
          },
        });
      }
    }
  };

  useEffect(() => {
    getDetails(id);
  }, [id]);

  useEffect(() => {
    if(!scienceCommitteeId) return;
    getCommitteesInfo(scienceCommitteeId);
  }, [scienceCommitteeId]);

  useEffect(() => {
    if (topicDetails) {
      setDataMinutes((prev) => ({
        ...prev,
        topicName: topicDetails?.name,
        supervisor: "bệnh viện đa khoa Mỹ Đức",
        implementingAgency: "bệnh viện đa khoa Mỹ Đức",
        topicPiName: topicDetails.members?.find(
          (member: TMember) => member.typeId === MEMBER_TYPE.CHU_NHIEM_DE_TAI
        )?.name
      }));
    }
  }, [topicDetails]);

  const handleChange = (e: any) => {
    const { name, value, type } = e.target;
    if (type === "radio") {
      const inputValue = Number(value);
      setDataMinutes({ ...dataMinutes, [name]: inputValue });
    }
    if (type !== "radio") {
      autoResize(e.target);
      setDataMinutes({ ...dataMinutes, [name]: type === "number" ? Number(value) : value });
    }
    if (name === "numAttendees") {
      setDataMinutes((prevData) => {
        const newnumAttendees = Number(value);
        return {
          ...prevData,
          [name]: newnumAttendees,
          numAbsent: 5 - newnumAttendees,
        };
      });
    }
  };
  const addDataMinute = async (data: any) => {
    try {
      const response = await regisScienceMinute(id, data);
      if (response?.data?.code === CODE.SUCCESS) {
        toast.success("Tạo biên bản thành công!");
        onHide();
        onRefresh();
        setTopicDetails({});
      }
    } catch (e) {
      toast.error("Tạo biên bản thất bại!");
    }
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (
      !dataMinutes.committeeRecommendation ||
      !dataMinutes.numAttendees
    ) {
      toast.warning("Vui lòng nhập đủ thông tin!");
      return;
    }
    addDataMinute({
      ...dataMinutes,
      ...committeeInfo
    });
  };
  const autoResize = (textarea: any) => {
    const name = textarea.name;
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
    setNumRows({
      ...numRows,
      [name]: textarea.scrollHeight / 24,
    });
  };
  const getDetails = async (id: string) => {
    try {
      setIsLoading(true);
      let { data } = await getTopicById(id);
      if (data?.code === CODE.SUCCESS) {
        setTopicDetails(data?.data);
        setScienceCommitteeId(data?.data?.scienceCommitteeId);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getCommitteesInfo = async (committeeId: string) => {
    try {
      setIsLoading(true);
      let { data } = await getCommittees(committeeId);
      if (data?.code === CODE.SUCCESS) {
        setCommitteeInfo(data?.data);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };
  
  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="lg"
      className="spaces h-calc-vh-50"
      scrollable
    >
      <form onSubmit={handleSubmit}>
        <div className="stepper stepper-links d-flex flex-column">
          <Modal.Header className="header-modal min-h-40px" closeButton>
            <Modal.Title className="py-4">
              Biên bản họp hội đồng khoa học
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="spaces p-0 bg-white mxh-60vh">
            <div className="a4-page">
              <div className="a4-container">
                <div className="header-container">
                  <div className="left-column center-text">
                    <h3 className="bold">SỞ Y TẾ HÀ NỘI</h3>
                    <h3 className="bold underline">
                      BỆNH VIỆN ĐA KHOA MỸ ĐỨC{" "}
                    </h3>
                  </div>
                  <div className="right-column center-text">
                    <p className="bold spaces mb-4">
                      CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM
                    </p>
                    <p className="underline bold">
                      Độc lập – Tự do – Hạnh phúc
                    </p>
                    <p className="center-text">
                      Mỹ Đức, ngày {moment().format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>

                <h3 className="text-center bold section">
                  BIÊN BẢN HỌP HỘI ĐỒNG ĐÁNH GIÁ
                </h3>
                <h4 className="text-center">
                  THUYẾT MINH ĐỀ TÀI KHOA HỌC VÀ CÔNG NGHỆ CẤP CƠ SỞ
                </h4>

                <div className="section">
                  <p className="">
                    <b>1. Tên đề tài: </b>
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="text"
                        name="topicName"
                        value={dataMinutes?.topicName}
                        style={{ width: "100%", display: "inline-block" }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        readOnly
                      />
                      <span className="dot-line"></span>
                    </span>
                  </p>
                  <p>
                    <b>2. Chủ nhiệm đề tài: </b>
                  </p>
                  <div className="flex pb-4">
                    <div className="relative flex-1">
                      <textarea
                        className="no-padding custom-input"
                        wrap="hard"
                        name="topicPiName"
                        value={dataMinutes?.topicPiName}
                        required
                        onInvalid={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("Vui lòng nhập thông tin!");
                          validatorField(target.name, target.value);
                        }}
                        onInput={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("");
                          validatorField(target.name, target.value);
                        }}
                        onChange={(e) => handleChange(e)}
                        style={{
                          fontFamily: "Times New Roman",
                          fontSize: "16px",
                          display: "block",
                          zIndex: "1",
                          width: "100%",
                          outline: "unset",
                          position: "relative",
                          resize: "none",
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                          height: "auto",
                          minHeight: "24px",
                          backgroundColor: "transparent",
                        }}
                      />
                      {Array.from({
                        length: isNaN(numRows?.topicPiName)
                          ? 2
                          : numRows?.topicPiName,
                      })?.map((item: any, index: any) => (
                        <span
                          key={index}
                          style={{
                            position: "absolute",
                            top: `${
                              (1 / numRows?.topicPiName || 0) * 100 * index
                            }%`,
                            right: "0",
                            left: "0",
                            width: "100%",
                            color: `${
                              validatorInput?.topicPiName?.valid
                                ? "red"
                                : "black"
                            }`,
                            height: `24px`,
                            borderBottom: "1px dashed",
                            transform: "translateY(-2px)",
                            backgroundColor: "transparent",
                          }}
                        ></span>
                      ))}
                    </div>
                  </div>
                  <p>
                    <b>3. Quyết định thành lập:</b> Hội đồng số
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="text"
                        name="code"
                        value={dataMinutes?.code || committeeInfo?.code}
                        style={{
                          width: "70px",
                          textAlign: "center",
                          display: "inline-block",
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span className="dot-line"></span>
                    </span>
                    /QĐ-BVĐKSS của
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="text"
                        name="supervisor"
                        value={dataMinutes?.supervisor}
                        style={{
                          width: "289px",
                          display: "inline-block",
                          marginLeft: "8px",
                        }}
                        required
                        onInvalid={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("Vui lòng nhập thông tin!");
                          validatorField(target.name, target.value);
                        }}
                        onInput={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("");
                          validatorField(target.name, target.value);
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span
                        className="dot-line"
                        style={{
                          color: `${
                            validatorInput.supervisor.valid ? "red" : "black"
                          }`,
                        }}
                      ></span>
                    </span>
                  </p>
                  <p>
                    <b>4. Cơ quan thực hiện đề tài: </b>
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="text"
                        name="implementingAgency"
                        value={dataMinutes?.implementingAgency}
                        style={{ width: "526px", display: "inline-block" }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span className="dot-line"></span>
                    </span>
                  </p>
                  <p>
                    <b>5. Ngày họp: </b>
                    <input
                      type="date"
                      name="date"
                      className="date-field"
                      value={dataMinutes?.date || moment().format("YYYY-MM-DD")}
                      onChange={(e) => handleChange(e)}
                    />
                  </p>
                  <p>
                    <b>6. Địa điểm: </b>
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="text"
                        name="place"
                        value={dataMinutes?.place || committeeInfo?.place}
                        style={{ width: "622px", display: "inline-block" }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span className="dot-line"></span>
                    </span>
                  </p>
                  <p>
                    <b>7. Thành viên của hội đồng:</b>
                  </p>
                  <p>
                    Tổng số: 5. Có mặt:{" "}
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="number"
                        name="numAttendees"
                        value={dataMinutes?.numAttendees || ""}
                        required
                        onInvalid={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("Vui lòng nhập thông tin!");
                          validatorField(target.name, target.value);
                        }}
                        onInput={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("");
                          validatorField(target.name, target.value);
                        }}
                        style={{
                          width: "253px",
                          display: "inline-block",
                          textAlign: "center",
                          marginLeft: "8px",
                        }}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          if (value >= 0 && value <= 5) {
                            handleChange(e);
                          } else {
                            e.target.value =
                              dataMinutes?.numAttendees !== undefined
                                ? String(dataMinutes.numAttendees)
                                : "";
                          }
                        }}
                      />
                      <span
                        className="dot-line"
                        style={{
                          color: `${
                            validatorInput.numAttendees.valid ? "red" : "black"
                          }`,
                        }}
                      ></span>
                    </span>
                    . Vắng mặt{" "}
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="text"
                        name="numAbsent"
                        value={dataMinutes?.numAbsent}
                        style={{
                          width: "253px",
                          display: "inline-block",
                          textAlign: "center",
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        readOnly
                      />
                      <span className="dot-line"></span>
                    </span>
                  </p>
                  <p>
                    <b>8. Khách mời dự:</b>
                  </p>
                  <p>
                    Tổng số điểm:{" "}
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="number"
                        name="scoreTotal"
                        value={dataMinutes?.scoreTotal || ""}
                        required
                        onInvalid={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("Vui lòng nhập thông tin!");
                          validatorField(target.name, target.value);
                        }}
                        onInput={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("");
                          validatorField(target.name, target.value);
                        }}
                        style={{
                          width: "224px",
                          display: "inline-block",
                          textAlign: "center",
                          marginLeft: "8px",
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span
                        className="dot-line"
                        style={{
                          color: `${
                            validatorInput.scoreTotal.valid ? "red" : "black"
                          }`,
                        }}
                      ></span>
                    </span>
                    . Điểm trung bình ban đầu:{" "}
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="number"
                        name="scoreInitialAverage"
                        value={dataMinutes?.scoreInitialAverage || ""}
                        style={{
                          width: "224px",
                          display: "inline-block",
                          textAlign: "center",
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span className="dot-line"></span>
                    </span>
                  </p>
                  <p>
                    <b>9. Tổng số đầu điểm:</b>{" "}
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="number"
                        name="scoreInitialTotal"
                        value={dataMinutes?.scoreInitialTotal || ""}
                        required
                        onInvalid={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("Vui lòng nhập thông tin!");
                          validatorField(target.name, target.value);
                        }}
                        onInput={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("");
                          validatorField(target.name, target.value);
                        }}
                        style={{
                          width: "120px",
                          display: "inline-block",
                          textAlign: "center",
                          marginLeft: "8px",
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span
                        className="dot-line"
                        style={{
                          color: `${
                            validatorInput.scoreInitialTotal.valid
                              ? "red"
                              : "black"
                          }`,
                        }}
                      ></span>
                    </span>
                    . Trong đó hợp lệ{" "}
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="number"
                        name="scoreInitialValid"
                        value={dataMinutes?.scoreInitialValid || ""}
                        style={{
                          width: "120px",
                          display: "inline-block",
                          textAlign: "center",
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span className="dot-line"></span>
                    </span>{" "}
                    không hợp lệ{" "}
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="number"
                        name="scoreInitialInvalid"
                        value={dataMinutes?.scoreInitialInvalid || ""}
                        style={{
                          width: "136px",
                          display: "inline-block",
                          textAlign: "center",
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span className="dot-line"></span>
                    </span>
                  </p>
                  <p>
                    <b>10. Tổng số điểm hợp lệ:</b>{" "}
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="number"
                        name="scoreValidTotal"
                        value={dataMinutes?.scoreValidTotal || ""}
                        required
                        onInvalid={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("Vui lòng nhập thông tin!");
                          validatorField(target.name, target.value);
                        }}
                        onInput={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("");
                          validatorField(target.name, target.value);
                        }}
                        style={{
                          width: "539px",
                          display: "inline-block",
                          marginLeft: "8px",
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span
                        className="dot-line"
                        style={{
                          color: `${
                            validatorInput.scoreValidTotal.valid
                              ? "red"
                              : "black"
                          }`,
                        }}
                      ></span>
                    </span>
                  </p>
                  <p>
                    <b>11. Điểm trung bình cuối cùng:</b>{" "}
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="number"
                        name="scoreFinalAverage"
                        value={dataMinutes?.scoreFinalAverage || ""}
                        required
                        onInvalid={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("Vui lòng nhập thông tin!");
                          validatorField(target.name, target.value);
                        }}
                        onInput={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("");
                          validatorField(target.name, target.value);
                        }}
                        style={{
                          width: "499px",
                          display: "inline-block",
                          marginLeft: "8px",
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span
                        className="dot-line"
                        style={{
                          color: `${
                            validatorInput.scoreFinalAverage.valid
                              ? "red"
                              : "black"
                          }`,
                        }}
                      ></span>
                    </span>
                  </p>
                  <p>
                    <b>12. Kết luận và kiến nghị của Hội đồng: </b>
                    <div className="relative">
                      <textarea
                        className="no-padding custom-input"
                        title="Nhập nhận xét"
                        wrap="hard"
                        name="committeeRecommendation"
                        value={dataMinutes?.committeeRecommendation}
                        required
                        onInvalid={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("Vui lòng nhập thông tin!");
                          validatorField(target.name, target.value);
                        }}
                        onInput={(e) => {
                          const target = e.target as HTMLInputElement;
                          target.setCustomValidity("");
                          validatorField(target.name, target.value);
                        }}
                        onChange={(e) => handleChange(e)}
                        style={{
                          fontFamily: "Times New Roman",
                          fontSize: "16px",
                          display: "block",
                          zIndex: "1",
                          width: "100%",
                          outline: "unset",
                          position: "relative",
                          resize: "none",
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                          height: "auto",
                          minHeight: "24px",
                          backgroundColor: "transparent",
                        }}
                      />
                      {Array.from({
                        length: isNaN(numRows?.committeeRecommendation)
                          ? 1
                          : numRows?.committeeRecommendation,
                      })?.map((item: any, index: any) => (
                        <span
                          key={index}
                          style={{
                            position: "absolute",
                            top: `${
                              (1 / numRows?.committeeRecommendation || 0) *
                              100 *
                              index
                            }%`,
                            right: "0",
                            left: "0",
                            width: "100%",
                            color: `${
                              validatorInput.committeeRecommendation.valid
                                ? "red"
                                : "black"
                            }`,
                            height: `24px`,
                            borderBottom: "1px dashed",
                            transform: "translateY(-2px)",
                            backgroundColor: "transparent",
                          }}
                        ></span>
                      ))}
                    </div>
                  </p>
                  <p>
                    <b>13. Xếp loại phê duyệt: </b>
                    <span className="relative">
                      <input
                        className="no-padding custom-input"
                        type="text"
                        name="committeeConclusion"
                        value={dataMinutes?.committeeConclusion}
                        style={{ width: "556px", display: "inline-block" }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <span className="dot-line"></span>
                    </span>
                  </p>
                  <div className="flex" style={{ fontStyle: "italic" }}>
                    <p style={{ width: "100px" }}>* Ghi chú:</p>
                    <div>
                      <div>
                        - Phê duyệt: &gt; 70 điểm (trong đó tổng điểm của 4 tiêu
                        chí 1,2,3,4 &gt; 50 điểm);
                      </div>
                      <div>- Không phê duyệt: ≤ 70 điểm</div>
                      <div>
                        - Điểm của thành viên hội đồng chênh lệch &gt; 20 điểm
                        so với điểm trung bình ban đầu coi là điểm không hợp lệ
                        và không được tính vào tổng điểm số điểm hợp lệ.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="signature-container">
                  <div className="left-signature">
                    <p>
                      <b>THƯ KÝ</b>
                    </p>
                    <p className="pt-4">{committeeInfo?.secretaryName}</p>
                  </div>

                  <div className="right-signature">
                    <p>
                      <b>CHỦ TỊCH HỘI ĐỒNG</b>
                    </p>
                    <p className="pt-4">{committeeInfo?.chairmanName}</p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="flex justify-content-center">
            <Button
              variant="primary"
              className="min-w-75px fw-bold"
              type="submit"
            >
              {"Gửi"}
            </Button>
            <Button
              variant="secondary"
              className="min-w-75px fw-bold"
              onClick={onHide}
            >
              Hủy
            </Button>
          </Modal.Footer>
        </div>
      </form>
    </Modal>
  );
};
export default ScienceMinutesDialog;
