import { TAcceptanceCouncilMember } from "../models/AcceptanceCouncilManagerModels";

export const listAcceptanceCouncilMember: TAcceptanceCouncilMember[] = [
  { id: '1', name: 'Trần Văn A', gender: 1, position: 'Nhân viên', duty: "Chủ nhiệm đề tài", academicRank: "Phó giáo sư", degree: "Tiến sĩ", department: "Khoa chẩn đoán hình ảnh", email: "tranvana@example.com", phone: "0123456789", office: "Bệnh viện 103"},
  { id: '2', name: 'Trần Thị B', gender: 2, position: 'Giám đốc', duty: "Thư ký đề tài", academicRank: "Phó giáo sư", degree: "Tiến sĩ", department: "Khoa Ngoại Tiết Niệu", email: "tranthib@example.com", phone: "0985155467", office: "Bệnh viện Hòe Nhai"},
  { id: '3', name: 'Đặng Thị C', gender: 2, position: 'Phó giám đốc', duty: "Thành viên", academicRank: "Phó giáo sư", degree: "Tiến sĩ", department: "Khoa Tai Mũi họng", email: "phamthic@example.com", phone: "0964751452", office: "Bệnh viện Vân Đình"},
  { id: '4', name: 'Phạm Thị D', gender: 2, position: 'Trưởng phòng', duty: "Thành viên", academicRank: "Phó giáo sư", degree: "Tiến sĩ", department: "Khoa Tai Mũi họng", email: "phamthid@example.com", phone: "0969245751", office: "Bệnh viện 103"}
];

export const CONFIRM_TYPE = {
  DELETE_COUNCIL: "xóa hội đồng nghiệm thu"
}