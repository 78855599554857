/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormikErrors, FormikHandlers } from "formik";
import { FC, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { TextFieldCustom } from "../../../../category-personnel/components/text-field-custom/TextFieldCustom";
import { generateYears } from "../../../../utils/AppFunction";
import {
  CODE,
  DEFAULT_PAGE_INDEX,
  MAX_PAGE_SIZE,
} from "../../../../utils/Constant";
import {
  TAcceptanceCouncilDetails,
  TAddAcceptanceCouncil,
} from "../../models/AcceptanceCouncilManagerModels";
import { searchBatchOfRegistration } from "../../services/services";
import moment from "moment";
import { handleDownloadFile } from "../../../../utils/ServicesUtils";

type Iprops = {
  values: TAddAcceptanceCouncil;
  errors: FormikErrors<TAddAcceptanceCouncil>;
  handleChange: FormikHandlers["handleChange"];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleChangeFile?: (e: any) => void;
  isView?: boolean;
  details?: TAcceptanceCouncilDetails;
};
const Step1: FC<Iprops> = (props) => {
  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    handleChangeFile,
    isView,
    details,
  } = props;

  const [batchOfRegistrationList, setBatchOfRegistrationList] = useState<any[]>(
    []
  );
  const [openEditFile, setOpenEditFile] = useState<boolean>(false);

  useEffect(() => {
    getBatchOfRegistrationList();
  }, []);

  const getBatchOfRegistrationList = async () => {
    try {
      let { data } = await searchBatchOfRegistration({
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: MAX_PAGE_SIZE,
      });
      if (data?.code === CODE.SUCCESS) {
        setBatchOfRegistrationList(
          data?.data?.content?.map((item: any) => {
            return {
              ...item,
              title: item?.startDate
                ? moment(item?.startDate).format("DD/MM/YYYY")
                : "",
              value: item?.id,
            };
          })
        );
      } else {
        toast.error("Đã có lỗi xảy ra!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-100">
      <Row>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="select"
            title="Năm"
            name="year"
            disabled={isView || !!details?.id}
            values={values.year || details?.year}
            errors={errors.year}
            handleChange={handleChange}
            options={generateYears()}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="select"
            title="Đợt"
            name="regisPeriodId"
            disabled={isView || !!details?.id}
            values={values.regisPeriodId || details?.regisPeriodId}
            errors={errors.regisPeriodId}
            handleChange={(e: any) => {
              const selectedIndex = e.target.selectedIndex;

              const regisPeriodDate = moment(
                e.target.options[selectedIndex].text,
                "DD/MM/YYYY"
              ).format("YYYY-MM-DD");
              setFieldValue("regisPeriod", regisPeriodDate);
              handleChange(e);
            }}
            options={batchOfRegistrationList}
          />
        </Col>
        <Col xs={6} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Tên hội đồng"
            name="name"
            disabled={isView || !!details?.id}
            values={values.name || details?.name}
            errors={errors.name}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Số quyết định"
            name="code"
            disabled={isView || !!details?.id}
            values={values.code || details?.code}
            errors={errors.code}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="time"
            title="Thời gian"
            name="time"
            disabled={isView}
            values={values.time || details?.time}
            errors={errors.time}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="date"
            title="Ngày diễn ra"
            name="date"
            disabled={isView}
            values={values.date || details?.date}
            errors={errors.date}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Địa điểm"
            name="place"
            disabled={isView}
            values={values.place || details?.place}
            errors={errors.place}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={6} className="pb-4">
          {isView && (
            <div className="flex w-100 justify-content-between align-items-end gap-5">
              <div className="flex-1">
                <TextFieldCustom
                  type="text"
                  title="Tệp đính kèm"
                  name="originalFileName"
                  disabled={isView}
                  values={details?.originalFileName}
                  errors={errors.originalFileName}
                  handleChange={handleChange}
                />
              </div>
              <div>
                <button
                  type="button"
                  className="spaces min-w-90px btn btn-primary btn btn-primary"
                  onClick={() =>
                    handleDownloadFile(
                      details?.fileId || "",
                      details?.originalFileName || ""
                    )
                  }
                >
                  Tải xuống
                </button>
              </div>
            </div>
          )}
          {!isView && details?.id && !openEditFile ? (
            <div className="flex w-100 justify-content-between align-items-end gap-5">
              <div className="flex-1">
                <TextFieldCustom
                  type="text"
                  title="Tệp đính kèm"
                  name="originalFileName"
                  disabled
                  values={details?.originalFileName}
                  errors={errors.originalFileName}
                  handleChange={handleChange}
                />
              </div>
              <div>
                <button
                  type="button"
                  className="spaces min-h-35 w-35px flex justify-content-center align-items-center btn btn-primary"
                  onClick={() => setOpenEditFile(true)}
                >
                  <i className="bi bi-pencil-fill p-0"></i>
                </button>
              </div>
            </div>
          ) : (
            !isView && (
              <Form.Group controlId="formFile" className="flex-1">
                <Form.Label>Tệp đính kèm</Form.Label>
                <Form.Control
                  type="file"
                  name="file"
                  size="sm"
                  accept={".pdf, .doc, .docx"}
                  onChange={(e) => handleChangeFile && handleChangeFile(e)}
                />
              </Form.Group>
            )
          )}
        </Col>
      </Row>
    </div>
  );
};

export { Step1 };
