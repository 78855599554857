import { FC, Fragment, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { TTopic } from "../models/TopicModel";
import { useLocation, useNavigate } from 'react-router-dom';
import { TextFieldCustom } from "../../../category-personnel/components/text-field-custom/TextFieldCustom";
import { ANNOUNCEMENT_TYPE } from "../../../de-tai-cua-toi/constants/constants";

const TopicResultAnnouncement: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state  as { show?: boolean, data?: TTopic };

  const [announcementInfo, setAnnouncementInfo] = useState({
    announcementType: undefined,
    content: "",
    file: undefined
  });

  const handleChange = (e: any) => {
    setAnnouncementInfo({
      ...announcementInfo,
      [e.target.name]: e.target.value
    })
  };

  return (
    <Modal
      centered
      show={state?.show}
      onHide={() => navigate('/quan-ly-de-tai/de-tai', { replace: true })}
      size="xl"
      fullscreen
      className="page-full spaces h-calc-vh-50"
    >
      <Modal.Header className="header-modal min-h-40px">
        <div className="d-flex gap-6 py-2">
          <div
            className="d-flex align-items-center title-back gap-3 cursor-pointer"
            onClick={() => {
              navigate('/quan-ly-de-tai/de-tai', { replace: true });
            }}
          >
            <i className="spaces bi bi-chevron-left ml-10"> </i>
            <div className="spaces mr-20">Quay lại</div>
          </div>
          <h4 className="text-title border-bottom text-pri fw-bold fs-4 spaces mb-0">
            Thông báo kết quả
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body className="spaces p-0 mt-3 bg-white">
        <Row className="spaces px-10 border-bottom">
          <Col xs={4}>
            <div className="flex py-2">
              <div className="label fw-bold min-w-150px">Tên đề tài:</div>
              <div className="flex-1">Hệ thống quản lý khóa học online</div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="flex py-2">
              <div className="label fw-bold min-w-150px">Mã đề tài:</div>
              <div className="flex-1">CS/BM/24/01</div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="flex py-2">
              <div className="label fw-bold min-w-150px">Ngày tạo:</div>
              <div className="flex-1">10/06/2024</div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="flex py-2">
              <div className="label fw-bold min-w-150px">
                Thời gian thực hiện:
              </div>
              <div className="flex-1">10/06/2024 - 10/06/2025</div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="flex py-2">
              <div className="label fw-bold min-w-150px">Đợt:</div>
              <div className="flex-1">15/06/2024</div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="flex py-2">
              <div className="label fw-bold min-w-150px">Gia hạn:</div>
              <div className="flex-1">Không</div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="flex py-2">
              <div className="label fw-bold min-w-150px">Chủ nhiệm:</div>
              <div className="flex-1">Nguyễn Văn Nhiệm</div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="flex py-2">
              <div className="label fw-bold min-w-150px">Email chủ nhiệm:</div>
              <div className="flex-1">nhiempc@gmail.com</div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="flex py-2">
              <div className="label fw-bold min-w-150px">Trạng thái:</div>
              <div className="flex-1">Đang thực hiện</div>
            </div>
          </Col>
        </Row>
        <Row className="spaces p-10">
          <Col xs={4}>
            <div className="flex py-2 align-items-center">
              <div className="label fw-bold min-w-150px">Chọn loại thông báo:</div>
              <div className="flex-1">
                <TextFieldCustom
                  type="select"
                  title=""
                  name="announcementType"
                  values={announcementInfo.announcementType}
                  handleChange={handleChange}
                  errors=""
                  options={ANNOUNCEMENT_TYPE}
                />
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className="flex py-2 align-items-center">
              <div className="label fw-bold min-w-150px">Nội dung:</div>
              <div className="flex-1">
                <TextFieldCustom
                  type="text"
                  as="textarea"
                  className="min-h-90px"
                  title=""
                  name="content"
                  values={announcementInfo.content}
                  handleChange={handleChange}
                  errors=""
                />
              </div>
            </div>
          </Col>
          <Col xs={4} className="spaces pt-10">
            <div className="flex py-2 align-items-center">
              <div className="label fw-bold min-w-150px">File đính kèm:</div>
              <div className="flex-1">
                <TextFieldCustom
                  type="file"
                  title=""
                  name="file"
                  values={announcementInfo.file}
                  handleChange={handleChange}
                  errors=""
                />
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="flex justify-content-end">
        <button
          type="submit"
          className="spaces min-w-90px btn btn-primary btn btn-primary"
        >
          Gửi
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default TopicResultAnnouncement;
