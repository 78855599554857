import { createContext, Dispatch, FC, SetStateAction, useState } from "react";
import ScienceCouncilManager from "./ScienceCouncilManager";
import { initFilter, TScienceCouncil, TScienceCouncilFilter } from "./models/ScienceCouncilManagerModels";
import "./ScienceCouncilManager.scss"

export interface ScienceCouncilContextProps {
  onReset: () => void;
  filter: TScienceCouncilFilter;
  setFilter: Dispatch<SetStateAction<TScienceCouncilFilter>>;
  scienceCouncilList: TScienceCouncil[];
  setScienceCouncilList: Dispatch<SetStateAction<TScienceCouncil[]>>;
}

const initialContext = {
  onReset: () => {},
  filter: initFilter,
  setFilter: () => {},
  scienceCouncilList: [],
  setScienceCouncilList: () => {}
};

export const ScienceCouncilContext =
  createContext<ScienceCouncilContextProps>(initialContext);

export const ScienceCouncilProvider: FC = () => {
  const [filter, setFilter] = useState<TScienceCouncilFilter>(initFilter);
  const [scienceCouncilList, setScienceCouncilList] = useState<TScienceCouncil[]>([]);

  return (
    <ScienceCouncilContext.Provider
      value={{
        onReset: () => {},
        filter,
        setFilter,
        scienceCouncilList,
        setScienceCouncilList
      }}
    >
      <div className="bg-white h-100">
        <div className="flex-1 flex flex-column">
          <div className="py-1">
            <ScienceCouncilManager />
          </div>
        </div>
      </div>
    </ScienceCouncilContext.Provider>
  );
};

export default ScienceCouncilProvider;
