import { columnNamesType, OctTable, OctTablePagination } from "@oceantech/oceantech-ui";
import { FC, useContext, useEffect, useState } from "react";
import Filter from "./components/Filter";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../appContext/AppContext";
import { addTopicOutline, deleteTopicById, searchTopic } from "./services/services";
import { toast } from "react-toastify";
import { BUTTON_CONFIG, checkActiveButton, checkDisplayButton, CODE, DEFAULT_PAGE_INDEX, TRANG_THAI } from "../utils/Constant";
import { MyTopicContext } from "./MyTopicContext";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../utils/PageUtils";
import moment from "moment";
import { ConfirmDialog } from "../component/ConfirmDialog";
import { TMyTopic } from "./models/MyTopicModel";
import { CONFIRM_TYPE } from "./constants/constants";
import UploadOutlineDialog from "./components/UploadOutlineDialog";

export const OpenTopicRegistration: FC = () => {

  let navigate = useNavigate();
  const { setIsLoading } = useContext(AppContext);
  const { filter, setFilter, myTopicList, setMyTopicList } = useContext(MyTopicContext);

  const [totalElements, setTotalElements] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [openUploadOutlineDialog, setOpenUploadOutlineDialog] = useState<boolean>(false);
  const [typeConfirm, setTypeConfirm] = useState<string>("");
  const [selectedRow, setSelectedRow] = useState<TMyTopic>({});

  useEffect(() => {
    getTopicList();
  }, [filter])

  useEffect(() => {
    setFilter({...filter, pageIndex: page, pageSize: rowsPerPage})
  }, [page, rowsPerPage])

  const getTopicList = async () => {
    try {
      setIsLoading(true);
      let { data } = await searchTopic(filter);
      if (data?.code === CODE.SUCCESS) {
        setMyTopicList(data?.data?.content);
        setTotalElements(data?.data?.pageable?.totalElements);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  const handleYesClick = async (row: TMyTopic) => {
    try {
      if(!row?.id) return;
      let api = null;
      switch (typeConfirm) {
        case CONFIRM_TYPE.DELETE_TOPIC:
          api = deleteTopicById(row.id);
          break;
        default:
          break;
      }
      if(!api) return;
      let { data } = await api;
      if(data?.code === CODE.SUCCESS) {
        setOpenConfirmDialog(false);
        toast.success(`${typeConfirm.charAt(0).toUpperCase() + typeConfirm.slice(1)} thành công!`);
        getTopicList();
      } else {
        toast.warning(`${typeConfirm.charAt(0).toUpperCase() + typeConfirm.slice(1)} thất bại`);
        setOpenConfirmDialog(false);
      }
    } catch (error) {
      console.error(error);
      setOpenConfirmDialog(false);
    }
  }


  const handleUploadOutline = async (data: any) => {
    let formData = new FormData();
    if(!data?.file){
      toast.warning("Vui lòng chọn file");
      return;
    }
    formData.append("file", data.file);
    formData.append("startDate", String(data?.startDate || ""));
    formData.append("endDate", String(data?.endDate || ""));
    try {
      setIsLoading(true);
      let { data } = await addTopicOutline(formData, String(selectedRow?.id || ""));
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        toast.success("Thêm đề cương thành công");
        setOpenUploadOutlineDialog(false);
        getTopicList();
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {
      setIsLoading(false);
      setOpenUploadOutlineDialog(false);
    }
  }

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: "50px",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row, index, stt) => {
        return <div>{(((Number(filter?.pageIndex) - 1) * Number(filter?.pageSize) + index) + 1)}</div>
      },
    },
    {
      name: "Đợt",
      field: "regisPeriod",
      headerStyle: {
        width: "100px",
      },
      cellStyle: {
        textAlign: "center",
        color: "#333",
        width: "100px"
      },
      render: (row, index, stt) => {
        return (
          <div>{row?.regisPeriod ? moment(row?.regisPeriod).format("DD/MM/YYYY") : ""}</div>
        );
      }
    },
    {
      name: "Mã đề xuất",
      field: "proposalCode",
      headerStyle: {
        minWidth: "50px",
      },
      cellStyle: {
        textAlign: "center",
        width: "100px"
      },
      render: (row, index, stt) => {
        return (
          <div
            className="text-pri"
            onClick={() => {
              navigate(`/de-tai-cua-toi/${row.id}`, {
                state: {
                  show: true,
                  data: row,
                },
                replace: true,
              });
            }}
          >
            {row.proposalCode}
          </div>
        );
      },
    },
    {
      name: "Mã đề tài",
      field: "code",
      headerStyle: {
        minWidth: "50px",
      },
      cellStyle: {
        textAlign: "center",
        width: "100px"
      },
      render: (row, index, stt) => {
        return (
          <div
            className="text-pri"
            onClick={() => {
              navigate(`/de-tai-cua-toi/${row.id}`, {
                state: {
                  show: true,
                  data: row,
                },
                replace: true,
              });
            }}
          >
            {row.code}
          </div>
        );
      },
    },
    {
      name: "Tên đề tài",
      field: "name",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "left",
        color: "#333",
      },
    },
    {
      name: "Chủ nhiệm",
      field: "chuNhiem",
      headerStyle: {
        minWidth: "100px",
      },
      cellStyle: {
        textAlign: "center",
        color: "#333",
      },
      render: (row, index, stt) => {
        return <div>{row?.members?.find((member: any) => member?.typeId === 1)?.name}</div>;
      }
    },
    {
      name: "Ngày đăng ký",
      field: "date",
      headerStyle: {
        width: "110px",
      },
      cellStyle: {
        textAlign: "center",
        color: "#333",
      },
      render: (row, index, stt) => {
        return <div>{row?.date ? moment(row?.date).format("DD/MM/YYYY") : ""}</div>;
      }
    },
    {
      name: "Thời gian",
      field: "thoiGian",
      headerStyle: {
        minWidth: "100px",
      },
      cellStyle: {
        textAlign: "center",
        color: "#333",
        width: "100px"
      },
      render: (row, index, stt) => {
        return <div>{`${row?.startDate && moment(row?.startDate).format("DD/MM/YYYY") || ""} ${row?.startDate && row?.endDate ? " - " : ""} ${row?.endDate && moment(row?.endDate).format("DD/MM/YYYY") || ""}`}</div>
      }
    },
    {
      name: "Gia hạn",
      field: "newDeadline",
      headerStyle: {
        minWidth: "50px",
      },
      cellStyle: {
        textAlign: "center",
        color: "#333",
      },
      render: (row, index, stt) => {
        return <div>{row?.newDeadline ? moment(row?.newDeadline).format("DD/MM/YYYY") : ""}</div>;
      }
    },
    {
      name: "Trạng thái",
      field: "statusName",
      headerStyle: {
        minWidth: "75px",
      },
      cellStyle: {
        textAlign: "center",
        color: "#333",
        width: "120px"
      },
    },
    {
      name: "Thao tác",
      field: "action",
      headerStyle: {
        width: "150px",
      },
      render: (row, index, stt) => {
        return (
          <div className="flex justify-content-start align-items-center px-4 gap-5">
            {checkDisplayButton("nopBaoCao", row?.statusId) && (
              <span
                className="tooltips"
                data-tooltip={BUTTON_CONFIG["nopBaoCao"].title}
                data-tooltip-pos="left"
                data-tooltip-length="medium"
              >
              <i
                className={`bi bi-file-earmark-zip-fill fs-1 ${checkActiveButton("nopBaoCao", row?.statusId) ? "text-pri" : "text-gray"} m-0`}
                onClick={() => {
                  if(!checkActiveButton("nopBaoCao", row?.statusId)) return;
                  navigate(`/de-tai-cua-toi/${row.id}/nop-bao-cao`, {
                    state: {
                      show: true,
                      data: row,
                    },
                    replace: true,
                  });
                }}></i></span>
              )}
            {checkDisplayButton("giayToKhac", row?.statusId) && (
              <span
                className="tooltips"
                data-tooltip={BUTTON_CONFIG["giayToKhac"].title}
                data-tooltip-pos="left"
                data-tooltip-length="medium"
              >
              <i 
                className={`bi bi-paperclip fs-1 ${checkActiveButton("giayToKhac", row?.statusId) ? "text-pri" : "text-gray"} m-0`}
                onClick={() => {
                if(!checkActiveButton("giayToKhac", row?.statusId)) return;
                navigate(`/de-tai-cua-toi/${row.id}/giay-to-khac`, {
                  state: {
                    show: true,
                    data: row,
                  },
                  replace: true,
                });
              }}></i></span>
            )}
            {checkDisplayButton("xoa", row?.statusId) && (
              <span
                className="tooltips"
                data-tooltip={BUTTON_CONFIG["xoa"].title}
                data-tooltip-pos="left"
                data-tooltip-length="medium"
              >
              <i className="bi bi-trash3 text-pri m-0 fs-1" onClick={() => {
                setOpenConfirmDialog(true);
                setSelectedRow(row);
                setTypeConfirm(CONFIRM_TYPE.DELETE_TOPIC);
              }}></i></span>
            )}
            {checkDisplayButton("deCuong", row?.statusId) && (
              <span
                className="tooltips"
                data-tooltip={BUTTON_CONFIG["deCuong"].title}
                data-tooltip-pos="left"
                data-tooltip-length="medium"
              >
              <i className="bi bi-file-earmark-medical-fill fs-1 text-pri m-0" onClick={() => {
                // navigate(`/de-tai-cua-toi/${row.id}/de-cuong`, {
                //   state: {
                //     show: true,
                //     data: row,
                //   },
                //   replace: true,
                // });
                setSelectedRow(row);
                setOpenUploadOutlineDialog(true);
              }}></i></span>
            )}
            {checkDisplayButton("giaHan", row?.statusId) && (
              <div onClick={() => {
                if(row?.newDeadline){
                  toast.warning("Chỉ được phép gia hạn 1 lần");
                  return;
                }
                navigate(`/quan-ly-de-tai/de-tai/${row.id}/gia-han`, {
                  state: {
                    show: true,
                    data: row,
                  },
                  replace: true,
                });
              }}>
                <span
                  className="tooltips"
                  data-tooltip={BUTTON_CONFIG["giaHan"].title}
                  data-tooltip-pos="left"
                  data-tooltip-length="medium"
                >
                  <i
                    className={`bi bi-clock-history fs-1 py-2 ${row?.newDeadline ? "text-gray" : "text-pri"}`}
                  >
                  </i>
                </span>
              </div>
            )}
          </div>
        )
      }
    }
  ];

  return (
    <div className="bg-white">
      <div className="spaces bg-gray radius-2">
        <div className="bg-white spaces width-100 flex justify-content-between p-10 border-bottom">
          <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0">
            Danh sách đề tài
          </h4>
        </div>
        <Filter/>
        <div className="d-flex flex-column spaces width-100">
          <div className="bg-white">
            <div className=" spaces bg-white m-0">
              <div className="bg-white spaces mb-6 pb-15 fl-1 ml-10 mr-10">
                <OctTable
                  id="ds-de-tai-cua-toi"
                  scrollable
                  className="spaces de-tai-table"
                  columns={columns}
                  data={myTopicList || []}
                  justFilter={false}
                  clearToolbar
                  noToolbar
                  noPagination
                />
                <OctTablePagination
                  className="pagination-de-tai-cua-toi"
                  handlePagesChange={handlePagesChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalPages={Math.ceil(totalElements/rowsPerPage)}
                  totalElements={totalElements}
                  rowsForPage={rowsForPage}
                  numberOfElements={0}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {openConfirmDialog && (
        <ConfirmDialog
          show={openConfirmDialog}
          yes="Xác nhận"
          onYesClick={() => handleYesClick(selectedRow)}
          message={`Bạn có chắc muốn ${typeConfirm} này?`}
          close="Huỷ"
          onCloseClick={() => setOpenConfirmDialog(false)}
          title="Xác nhận thao tác"
        />
      )}
      {openUploadOutlineDialog && (
        <UploadOutlineDialog
          show={openUploadOutlineDialog}
          onHide={() => setOpenUploadOutlineDialog(false)}
          onSubmit={(data: any) => handleUploadOutline(data)}
        />
      )}
    </div>
  );
};

export default OpenTopicRegistration;
