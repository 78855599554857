import { Form, Formik } from "formik";
import { Button, Col, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import { TextFieldCustom } from "../../category-personnel/components/text-field-custom/TextFieldCustom";
import { initOrganization, TMainCoordinatingOrganization } from "../models/MyTopicModel";
import { ORG_TYPE } from "../../utils/Constant";
import { generateSecureRandomId } from "../../utils/AppFunction";

interface Props {
  show: boolean;
  onHide: () => void;
  topicId: string | undefined;
  onAddOrganization: (organization: TMainCoordinatingOrganization) => void;
  data: TMainCoordinatingOrganization;
}

const AddOrganizationDialog = (props: Props) => {
  const { show, onHide, onAddOrganization, topicId, data } = props;

  const validationSchema = Yup.object({});

  const handleSubmit = async (values: TMainCoordinatingOrganization) => {
    onAddOrganization({
      ...values,
      fakeId: values?.fakeId || generateSecureRandomId(),
      typeId: ORG_TYPE.TO_CHUC_PHOI_HOP,
      topicId: topicId
    });
    onHide();
  };

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="lg"
      scrollable
      className="spaces h-calc-vh-50"
    >
      <Formik
        enableReinitialize
        initialValues={data?.fakeId ? data : initOrganization}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header className="header-modal min-h-40px" closeButton>
              <Modal.Title className="py-4">
                Tổ chức phối hợp chính thực hiện đề tài
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="spaces p-0 bg-white">
              <div className="spaces p-10">
                <Row className="py-2">
                  <Col xs={4} className="pb-4">
                    <TextFieldCustom
                      type="text"
                      title="Tên cơ quan chủ quản"
                      name="topicSupervisor"
                      values={values?.topicSupervisor}
                      errors={errors.topicSupervisor}
                      handleChange={handleChange}
                    />
                  </Col>
                  <Col xs={4} className="pb-4">
                    <TextFieldCustom
                      type="text"
                      title="Điện thoại"
                      name="phone"
                      values={values?.phone}
                      errors={errors.phone}
                      handleChange={handleChange}
                    />
                  </Col>
                  <Col xs={4} className="pb-4">
                    <TextFieldCustom
                      type="text"
                      title="Fax"
                      name="fax"
                      values={values?.fax}
                      errors={errors.fax}
                      handleChange={handleChange}
                    />
                  </Col>
                  <Col xs={8} className="pb-4">
                    <TextFieldCustom
                      type="text"
                      title="Địa chỉ"
                      name="address"
                      values={values?.address}
                      errors={errors.address}
                      handleChange={handleChange}
                    />
                  </Col>
                  <Col xs={4} className="pb-4">
                    <TextFieldCustom
                      type="text"
                      title="Họ và tên thủ trưởng tổ chức"
                      name="chiefName"
                      values={values?.chiefName}
                      errors={errors.chiefName}
                      handleChange={handleChange}
                    />
                  </Col>
                  <Col xs={4} className="pb-4">
                    <TextFieldCustom
                      type="text"
                      title="Số tài khoản"
                      name="bankNumber"
                      values={values?.bankNumber}
                      errors={errors.bankNumber}
                      handleChange={handleChange}
                    />
                  </Col>
                  <Col xs={4} className="pb-4">
                    <TextFieldCustom
                      type="text"
                      title="Ngân hàng"
                      name="bankName"
                      values={values?.bankName}
                      errors={errors.bankName}
                      handleChange={handleChange}
                    />
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer className="flex justify-content-center">
              <Button
                variant="primary"
                className="min-w-75px fw-bold"
                type="submit"
              >
                Lưu
              </Button>
              <Button
                variant="secondary"
                className="min-w-75px fw-bold"
                onClick={onHide}
              >
                Hủy
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddOrganizationDialog;
