import { FC, useContext, useEffect, useRef, useState } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import TabAcceptanceMinutes from "../../../de-tai-cua-toi/components/tabs/TabAcceptanceMinutes";
import TabDetails from "../../../de-tai-cua-toi/components/tabs/TabDetails";
import TabOutline from "../../../de-tai-cua-toi/components/tabs/TabOutline";
import TabProposal from "../../../de-tai-cua-toi/components/tabs/TabProposal";
import TabReviewAcceptanceCouncil from "../../../de-tai-cua-toi/components/tabs/TabReviewAcceptanceCouncil";
import TabReviewScienceCouncil from "../../../de-tai-cua-toi/components/tabs/TabReviewScienceCouncil";
import TabScienceMinutes from "../../../de-tai-cua-toi/components/tabs/TabScienceMinutes";
import { TABS } from "../../../de-tai-cua-toi/constants/constants";
import { TExecutiveOrganization, TMainCoordinatingOrganization } from "../../../de-tai-cua-toi/models/MyTopicModel";
import { CODE, MEMBER_TYPE, ORG_TYPE } from "../../../utils/Constant";
import { TMainResearcher, TMember, TTopic, TTopicDetails, TTopicSecretary } from "../models/TopicModel";
import { getTopicById } from "../services/services";

const TopicDetails: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsLoading } = useContext(AppContext);
  const state = location.state  as { show?: boolean, data?: TTopic };

  const formProposalRef = useRef<any>();
  const formOutlineRef = useRef<any>();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [key, setKey] = useState<string>(TABS.DETAILS.eventKey);
  const [topicInfo, setTopicInfo] = useState<TTopicDetails>({});
  const [mainResearcher, setMainResearcher] = useState<TMainResearcher>({});
  const [coMainResearcher, setCoMainResearcher] = useState<TMainResearcher>({});
  const [teacherList, setTeacherList] = useState<TMember[]>([]);
  const [memberList, setMemberList] = useState<TMember[]>([]);
  const [topicSecretary, setTopicSecretary] = useState<TTopicSecretary>({});
  const [executiveOrganization, setExecutiveOrganization] = useState<TExecutiveOrganization>({});
  const [mainCoordinatingOrganizationList, setMainCoordinatingOrganizationList] = useState<TMainCoordinatingOrganization[]>([]);

  useEffect(() => {
    let topicId = state?.data?.id;
    if (!topicId) return;
    getTopicDetails(topicId);
  }, [state?.data?.id]);

  const getTopicDetails = async (topicId: string) => {
    try {
      setIsLoading(true);
      let { data } = await getTopicById(topicId);
      if (data?.code === CODE.SUCCESS) {
        setTopicInfo(data?.data);
        setMainResearcher(
          data?.data?.members?.find(
            (member: TMember) => member?.typeId === MEMBER_TYPE.CHU_NHIEM_DE_TAI
          ) as TMainResearcher
        );
        setCoMainResearcher(
          data?.data?.members?.find(
            (member: TMember) => member?.typeId === MEMBER_TYPE.DONG_CHU_NHIEM
          ) as TMainResearcher
        );
        setTopicSecretary(
          data?.data?.members?.find(
            (member: TMember) => member?.typeId === MEMBER_TYPE.THU_KY
          ) as TTopicSecretary
        );
        setTeacherList(
          data?.data?.members?.filter(
            (member: TMember) => member?.typeId === MEMBER_TYPE.GVCN
          ) as TMember[]
        );
        setMemberList(
          data?.data?.members?.filter(
            (member: TMember) => member?.typeId === MEMBER_TYPE.CAN_BO_THUC_HIEN
          ) as TMember[]
        );
        setExecutiveOrganization(
          data?.data?.offices?.find(
            (organization: TExecutiveOrganization) => organization?.typeId === ORG_TYPE.TO_CHUC_CHU_TRI
          ) as TExecutiveOrganization
        );
        setMainCoordinatingOrganizationList(
          data?.data?.offices?.filter(
            (organization: TMainCoordinatingOrganization) => organization?.typeId === ORG_TYPE.TO_CHUC_PHOI_HOP
          )
        );
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <Modal
      centered
      show={state?.show}
      onHide={() => navigate('/quan-ly-de-tai/de-tai', { replace: true })}
      size="xl"
      fullscreen
      className="page-full spaces h-calc-vh-50"
    >
      <Modal.Header className="header-modal min-h-40px">
        <div className="d-flex gap-6 py-2">
          <div
            className="d-flex align-items-center title-back gap-3 cursor-pointer"
            onClick={() => {
              navigate('/quan-ly-de-tai/de-tai', { replace: true });
            }}
          >
            <i className="spaces bi bi-chevron-left ml-10"> </i>
            <div className="spaces mr-20">Quay lại</div>
          </div>
          <h4 className="text-title border-bottom text-pri fw-bold fs-4 spaces mb-0 px-10">
            Chi tiết đề tài
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body className="spaces p-0 mt-3 bg-white topic-details">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => {
            setKey(k || "");
          }}
          className="mb-3 tab-topic-details"
        >
          <Tab eventKey={TABS.DETAILS.eventKey} title={TABS.DETAILS.title}>
            <TabDetails
              topicInfo={topicInfo} 
              mainResearcher={mainResearcher}
              memberList={memberList}
              topicSecretary={topicSecretary}
              coMainResearcher={coMainResearcher}
            />
          </Tab>
          <Tab eventKey={TABS.PROPOSAL.eventKey} title={TABS.PROPOSAL.title}>
            <TabProposal
              memberList={memberList}
              topicInfo={topicInfo}
              mainResearcher={mainResearcher}
              coMainResearcher={coMainResearcher}
              topicSecretary={topicSecretary}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              formRef={formProposalRef}
            />
          </Tab>
          <Tab eventKey={TABS.OUTLINE.eventKey} title={TABS.OUTLINE.title}>
            <TabOutline 
              topicInfo={topicInfo}
              executiveOrganization={executiveOrganization}
              mainResearcher={mainResearcher}
              topicSecretary={topicSecretary}
              implementOfficerList={memberList}
              mainCoordinatingOrganizationList={mainCoordinatingOrganizationList}
              formRef={formOutlineRef}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
            />
          </Tab>
          <Tab eventKey={TABS.REVIEW_HDKH.eventKey} title={TABS.REVIEW_HDKH.title}>
            <TabReviewScienceCouncil topicId={topicInfo?.id} councilId={topicInfo?.scienceCommitteeId}/>
          </Tab>
          <Tab eventKey={TABS.REVIEW_HDNT.eventKey} title={TABS.REVIEW_HDNT.title}>
            <TabReviewAcceptanceCouncil topicId={topicInfo?.id}/>
          </Tab>
          {(topicInfo?.hasScienceRecord) && (
            <Tab eventKey={TABS.SCIENCE_MINUTES.eventKey} title={TABS.SCIENCE_MINUTES.title}>
              <TabScienceMinutes
                currentTab={key}
                topicId={topicInfo?.id}
                mainResearcher={mainResearcher}
                coMainResearcher={coMainResearcher}
              />
            </Tab>
          )}
          {( topicInfo?.hasAcceptanceRecord) && (
            <Tab eventKey={TABS.ACCEPTANCE_MINUTES.eventKey} title={TABS.ACCEPTANCE_MINUTES.title}>
              <TabAcceptanceMinutes
                currentTab={key}
                topicId={topicInfo?.id}
                mainResearcher={mainResearcher}
                coMainResearcher={coMainResearcher}
              />
            </Tab>
          )}
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};
export default TopicDetails;
