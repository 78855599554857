import { Form, Formik } from "formik";
import { useState } from "react";
import { Button, Col, Form as FormBS, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import { TextFieldCustom } from "../../../category-personnel/components/text-field-custom/TextFieldCustom";

interface Props {
  show: boolean;
  onHide: () => void;
  onSubmit: (officer: any) => void;
}

const ApprovalOutlineDialog = (props: Props) => {
  const { show, onHide, onSubmit } = props;

  const [file, setFile] = useState<any>(null);

  const validationSchema = Yup.object({
    code: Yup.string().required("Không được để trống trường này!"),
  });

  const handleChangeFile = (e: any) => {
    setFile(e?.target?.files?.[0]);
  }
  const handleSubmit = async (values: any) => {
    onSubmit({ 
      ...values,
      file
    });
    onHide();
  };

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="sm"
      scrollable
      className="spaces h-calc-vh-50"
    >
      <Formik
        enableReinitialize
        initialValues={{ code: ""}}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header className="header-modal min-h-40px" closeButton>
              <Modal.Title className="py-4">Phê duyệt đề cương</Modal.Title>
            </Modal.Header>
            <Modal.Body className="spaces p-0 bg-white">
              <div className="spaces p-10">
                <Row className="p-2">
                  <Col xs={12} className="pb-4">
                    <TextFieldCustom
                      type="text"
                      title="Mã đề tài"
                      name="code"
                      values={values.code}
                      errors={errors.code}
                      handleChange={handleChange}
                    />
                  </Col>
                  <Col xs={12} className="pb-4">
                    <FormBS.Group controlId="formFile" className="flex-1 pt-4">
                      <FormBS.Label>Chọn file</FormBS.Label>
                      <FormBS.Control
                        type="file"
                        name="file"
                        size="sm"
                        accept={".doc, .docx"}
                        required
                        onChange={(e) => handleChangeFile(e)}
                      />
                    </FormBS.Group>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer className="flex justify-content-center">
              <Button
                variant="primary"
                className="min-w-75px fw-bold"
                type="submit"
              >
                Lưu
              </Button>
              <Button
                variant="secondary"
                className="min-w-75px fw-bold"
                onClick={onHide}
              >
                Hủy
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ApprovalOutlineDialog;
