import React from "react";
import { Col, Row, Form as FormBS } from "react-bootstrap";
import { TextFieldCustom } from "../../../category-personnel/components/text-field-custom/TextFieldCustom";
import { TImplementOfficer, TMainCoordinatingOrganization, TMainResearcher, TTopicOutline, TTopicSecretary } from "../../models/MyTopicModel";
import { Field, FormikErrors, FormikHandlers } from "formik";
import { columnNamesType, OctTable } from "@oceantech/oceantech-ui";
import CustomIconButton from "../../../component/custom-icon-button/CustomIconButton";
import { GENDER } from "../../../utils/Constant";

type IProps = {
  values: TTopicOutline;
  errors: FormikErrors<TTopicOutline>;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  mainResearcher: TMainResearcher;
  topicSecretary: TTopicSecretary;
  setDataOrg: React.Dispatch<React.SetStateAction<TMainCoordinatingOrganization>>;
  handleEditOgnization: (organization: TMainCoordinatingOrganization) => void;
  handleDeleteOgnization: (organization: TMainCoordinatingOrganization) => void;
  setOpenAddOrganization: React.Dispatch<React.SetStateAction<boolean>>;
  organizationList: TMainCoordinatingOrganization[];
  officerList: TImplementOfficer[];
  handleChangeFile: (e: any) => Promise<void>;
};

const TopicInfo: React.FC<IProps> = (props) => {
  const { values, errors, handleChange, setFieldValue, mainResearcher, topicSecretary, setDataOrg,
    handleEditOgnization, handleDeleteOgnization, setOpenAddOrganization, organizationList, officerList, handleChangeFile
   } = props;

  const handleSingleCheckboxChange = (setFieldValue:any, checkboxName:any) => {
    setFieldValue('isPartProgram', checkboxName === 'isPartProgram');
    setFieldValue('isScience', checkboxName === 'isScience');
    setFieldValue('isSingle', checkboxName === 'isSingle');
    setFieldValue('isOther', checkboxName === 'isOther');
  };

  const officerColumns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: "50px"
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row, index, stt) => {
        return stt;
      },
    },
    {
      name: "Họ và tên",
      field: "name",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "center",
      }
    },
    {
      name: "Học hàm",
      field: "academicRank",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "center",
      }
    },
    {
      name: "Học vị",
      field: "degree",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "center",
      }
    },
    {
      name: "Chức danh thực hiện đề tài",
      field: "scientificTitle",
      headerStyle: {
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "center",
      }
    },
    {
      name: "Tổ chức công tác",
      field: "officeCurrently",
      headerStyle: {
        minWidth: "250px",
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    // {
    //   name: "Thao tác",
    //   field: "actions",
    //   headerStyle: {
    //     width: "150px",
    //   },
    //   cellStyle: {
    //     textAlign: "center"
    //   },
    //   render(data, index, numericalOrder, itemList) {
    //     return (
    //       <div className="flex spaces justify-content-center">
    //         <CustomIconButton variant="edit" onClick={() => handleEditOfficer(data)}>
    //           <i className="bi bi-pencil-square text-navy"></i>
    //         </CustomIconButton>

    //         <CustomIconButton variant="delete" onClick={() => handleDeleteOfficer(data)}>
    //           <i className="bi bi-trash3-fill text-danger"></i>
    //         </CustomIconButton>
    //       </div>
    //     );
    //   },
    // },
  ]

  const organizationColumns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: "50px",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row, index, stt) => {
        return stt;
      },
    },
    {
      name: "Tên tổ chức chủ trì đề tài",
      field: "topicSupervisor",
      headerStyle: {
        minWidth: "150px",
      }
    },
    {
      name: "Điện thoại",
      field: "phone",
      headerStyle: {
        minWidth: "75px",
      },
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      name: "Fax",
      field: "fax",
      headerStyle: {
        minWidth: "75px",
        maxWidth: "75px"
      },
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      name: "Địa chỉ",
      field: "address",
      headerStyle: {
        minWidth: "250px",
        maxWidth: "250px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: "Họ và tên thủ trưởng tổ chức",
      field: "chiefName",
      headerStyle: {
        minWidth: "150px",
      }
    },
    {
      name: "Số tài khoản",
      field: "bankNumber",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      name: "Ngân hàng",
      field: "bankName",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      name: "Thao tác",
      field: "actions",
      headerStyle: {
        width: "150px",
      },
      cellStyle: {
        textAlign: "center"
      },
      render(data, index, numericalOrder, itemList) {
        return (
          <div className="flex spaces justify-content-center">
            <CustomIconButton variant="edit" onClick={() => handleEditOgnization(data)}>
              <i className="bi bi-pencil-square text-navy"></i>
            </CustomIconButton>

            <CustomIconButton variant="delete" onClick={() => handleDeleteOgnization(data)}>
              <i className="bi bi-trash3-fill text-danger"></i>
            </CustomIconButton>
          </div>
        );
      },
    },
  ]

  return (
    <>
      <h4 className="text-title text-pri fw-bold fs-3 spaces mb-0 pb-2">I. THÔNG TIN CHUNG VỀ ĐỀ TÀI</h4>
      <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
        1. Thông tin đề tài:
      </h4>
      <Row className="py-2">
        <Col xs={4} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Tên đề tài"
            name="name"
            values={values.name}
            errors={errors.name}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={2} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Mã số"
            name="code"
            values={values.code}
            errors={errors.code}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="month"
            title="Thực hiện từ tháng"
            name="startDate"
            values={values.startDate}
            errors={errors.startDate}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="month"
            title="Thực hiện đến tháng"
            name="endDate"
            values={values.endDate}
            errors={errors.endDate}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={4}>
          <TextFieldCustom
            type="text"
            title="Cấp quản lý"
            name="manageLevel"
            values={values.manageLevel}
            errors={errors.manageLevel}
            handleChange={handleChange}
          />
        </Col>
      </Row>
      <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
        2. Tổng kinh phí thực hiện
        {(values.budgetFromGovernment || 0) + (values.budgetFromOther || 0) > 0
          ? ` ${
              (values.budgetFromGovernment || 0) + (values.budgetFromOther || 0)
            } triệu đồng, trong đó`
          : ""}
        :
      </h4>
      <Row className="py-2">
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="number"
            title="Từ ngân sách Nhà nước (triệu đồng)"
            name="budgetFromGovernment"
            values={values.budgetFromGovernment}
            errors={errors.budgetFromGovernment}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="number"
            title="Từ ngoài ngân sách Nhà nước (triệu đồng)"
            name="budgetFromOther"
            values={values.budgetFromOther}
            errors={errors.budgetFromOther}
            handleChange={handleChange}
          />
        </Col>
      </Row>
      <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
        3. Đề nghị phương thức khoán chi:
      </h4>
      <Row className="py-2">
        <Col xs={12}>
          <div className="flex align-items-center">
            <div className="label fw-bold text-pri spaces pr-16">
              <Field
                type="checkbox"
                name="isPaymentEntire"
                checked={values.isPaymentEntire}
                onChange={(e: any) => {
                  handleChange(e);
                  if (e.target.checked) {
                    setFieldValue("isPaymentPart", false);
                    setFieldValue("paymentPieceWork", 0);
                    setFieldValue("paymentNotPieceWork", 0);
                  }
                }}
              />
            </div>
            <div className="flex-1">
              <FormBS.Label>Khoán đến sản phẩm cuối cùng</FormBS.Label>
            </div>
          </div>
        </Col>
        <Col xs={12}>
          <div className="flex align-items-center">
            <div className="label fw-bold text-pri spaces pr-16">
              <Field
                type="checkbox"
                name="isPaymentPart"
                checked={values.isPaymentPart}
                onChange={(e: any) => {
                  handleChange(e);
                  if (!e.target.checked) {
                    setFieldValue("paymentPieceWork", 0);
                    setFieldValue("paymentNotPieceWork", 0);
                  } else {
                    setFieldValue("isPaymentEntire", false);
                  }
                }}
              />
            </div>
            <div className="flex-1">
              <FormBS.Label>Khoán từng phần, trong đó</FormBS.Label>
            </div>
          </div>
        </Col>
        {values.isPaymentPart && (
          <Row className="py-2">
            <Col xs={3}>
              <TextFieldCustom
                type="number"
                title="Kinh phí khoán (triệu đồng)"
                name="paymentPieceWork"
                values={values.paymentPieceWork}
                errors={errors.paymentPieceWork}
                handleChange={handleChange}
              />
            </Col>
            <Col xs={3}>
              <TextFieldCustom
                type="number"
                title="Kinh phí không khoán (triệu đồng)"
                name="paymentNotPieceWork"
                values={values.paymentNotPieceWork}
                errors={errors.paymentNotPieceWork}
                handleChange={handleChange}
              />
            </Col>
          </Row>
        )}
      </Row>
      <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
        4. Sự phụ thuộc:
      </h4>
      <Row className="py-2">
        <Col xs={12}>
          <div className="flex align-items-center">
            <div className="label fw-bold text-pri spaces pr-16">
              <Field
                type="checkbox"
                name="isPartProgram"
                checked={values.isPartProgram}
                onChange={(e: any) => {
                  handleChange(e);
                  handleSingleCheckboxChange(setFieldValue, "isPartProgram");
                }}
              />
            </div>
            <div className="flex-1">
              <FormBS.Label>
                Thuộc chương trình (Ghi rõ tên chương trình nếu có)
              </FormBS.Label>
            </div>
          </div>
        </Col>
        {values.isPartProgram && (
          <Row className="py-2">
            <Col xs={3}>
              <TextFieldCustom
                type="text"
                title="Tên chương trình"
                name="programName"
                values={values.programName}
                errors={errors.programName}
                handleChange={handleChange}
              />
            </Col>
            <Col xs={3}>
              <TextFieldCustom
                type="text"
                title="Mã chương trình"
                name="programCode"
                values={values.programCode}
                errors={errors.programCode}
                handleChange={handleChange}
              />
            </Col>
          </Row>
        )}
        <Col xs={12}>
          <div className="flex align-items-center">
            <div className="label fw-bold text-pri spaces pr-16">
              <Field
                type="checkbox"
                name="isScience"
                checked={values.isScience}
                onChange={(e: any) => {
                  handleChange(e);
                  handleSingleCheckboxChange(setFieldValue, "isScience");
                }}
              />
            </div>
            <div className="flex-1">
              <FormBS.Label>Thuộc dự án khoa học</FormBS.Label>
            </div>
          </div>
        </Col>
        <Col xs={12}>
          <div className="flex align-items-center">
            <div className="label fw-bold text-pri spaces pr-16">
              <Field
                type="checkbox"
                name="isSingle"
                checked={values.isSingle}
                onChange={(e: any) => {
                  handleChange(e);
                  handleSingleCheckboxChange(setFieldValue, "isSingle");
                }}
              />
            </div>
            <div className="flex-1">
              <FormBS.Label>Độc lập</FormBS.Label>
            </div>
          </div>
        </Col>
        <Col xs={12}>
          <div className="flex align-items-center">
            <div className="label fw-bold text-pri spaces pr-16">
              <Field
                type="checkbox"
                name="isOther"
                checked={values.isOther}
                onChange={(e: any) => {
                  handleChange(e);
                  handleSingleCheckboxChange(setFieldValue, "isOther");
                }}
              />
            </div>
            <div className="flex-1">
              <FormBS.Label>Khác</FormBS.Label>
            </div>
          </div>
        </Col>
      </Row>
      <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
        5. Chủ nhiệm đề tài:
      </h4>
      <Row className="py-2">
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Họ và tên"
            name="mainResearcher.name"
            values={values.mainResearcher?.name || mainResearcher?.name}
            errors={errors.mainResearcher}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="select"
            title="Giới tính"
            name="mainResearcher.gender"
            values={values.mainResearcher?.gender || mainResearcher?.gender}
            errors={errors.mainResearcher}
            handleChange={handleChange}
            options={GENDER}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Học hàm, học vị/Trình độ chuyên môn"
            name="mainResearcher.eduLevel"
            values={values.mainResearcher?.eduLevel || mainResearcher?.eduLevel}
            errors={errors.mainResearcher}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Chức danh khoa học"
            name="mainResearcher.scientificTitle"
            values={
              values.mainResearcher?.scientificTitle ||
              mainResearcher?.scientificTitle
            }
            errors={errors.mainResearcher}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Chức vụ"
            name="mainResearcher.position"
            values={values.mainResearcher?.position || mainResearcher?.position}
            errors={errors.mainResearcher}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Tổ chức"
            name="mainResearcher.office"
            values={values.mainResearcher?.office || mainResearcher?.office}
            errors={errors.mainResearcher}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Số điện thoại"
            name="mainResearcher.phone"
            values={values.mainResearcher?.phone || mainResearcher?.phone}
            errors={errors.mainResearcher}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Fax"
            name="mainResearcher.fax"
            values={values.mainResearcher?.fax || mainResearcher?.fax}
            errors={errors.mainResearcher}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3}>
          <TextFieldCustom
            type="text"
            title="Email"
            name="mainResearcher.email"
            values={values.mainResearcher?.email || mainResearcher?.email}
            errors={errors.mainResearcher}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3}>
          <TextFieldCustom
            type="text"
            title="Tên tổ chức đang công tác"
            name="mainResearcher.officeCurrently"
            values={
              values.mainResearcher?.officeCurrently ||
              mainResearcher?.officeCurrently
            }
            errors={errors.mainResearcher}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={6}>
          <TextFieldCustom
            type="text"
            title="Địa chỉ tổ chức"
            name="mainResearcher.officeAddress"
            values={
              values.mainResearcher?.officeAddress ||
              mainResearcher?.officeAddress
            }
            errors={errors.mainResearcher}
            handleChange={handleChange}
          />
        </Col>
      </Row>
      <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
        6. Thư ký khoa học của đề tài:
      </h4>
      <Row className="py-2">
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Họ và tên"
            name="topicSecretary.name"
            values={values.topicSecretary?.name || topicSecretary?.name}
            errors={errors.topicSecretary}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="select"
            title="Giới tính"
            name="topicSecretary.gender"
            values={values.topicSecretary?.gender || topicSecretary?.gender}
            errors={errors.topicSecretary}
            handleChange={handleChange}
            options={GENDER}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Học hàm, học vị/Trình độ chuyên môn"
            name="topicSecretary.eduLevel"
            values={values.topicSecretary?.eduLevel || topicSecretary?.eduLevel}
            errors={errors.topicSecretary}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Chức danh khoa học"
            name="topicSecretary.scientificTitle"
            values={
              values.topicSecretary?.scientificTitle ||
              topicSecretary?.scientificTitle
            }
            errors={errors.topicSecretary}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Chức vụ"
            name="topicSecretary.position"
            values={values.topicSecretary?.position || topicSecretary?.position}
            errors={errors.topicSecretary}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Tổ chức"
            name="topicSecretary.office"
            values={values.topicSecretary?.office || topicSecretary?.office}
            errors={errors.topicSecretary}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Số điện thoại"
            name="topicSecretary.phone"
            values={values.topicSecretary?.phone || topicSecretary?.phone}
            errors={errors.topicSecretary}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Fax"
            name="topicSecretary.fax"
            values={values.topicSecretary?.fax || topicSecretary?.fax}
            errors={errors.topicSecretary}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3}>
          <TextFieldCustom
            type="text"
            title="Email"
            name="topicSecretary.email"
            values={values.topicSecretary?.email || topicSecretary?.email}
            errors={errors.topicSecretary}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3}>
          <TextFieldCustom
            type="text"
            title="Tên tổ chức đang công tác"
            name="topicSecretary.officeCurrently"
            values={
              values.topicSecretary?.officeCurrently ||
              topicSecretary?.officeCurrently
            }
            errors={errors.topicSecretary}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={6}>
          <TextFieldCustom
            type="text"
            title="Địa chỉ tổ chức"
            name="topicSecretary.officeAddress"
            values={
              values.topicSecretary?.officeAddress ||
              topicSecretary?.officeAddress
            }
            errors={errors.topicSecretary}
            handleChange={handleChange}
          />
        </Col>
      </Row>
      <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
        7. Tổ chức chủ trì đề tài:
      </h4>
      <Row className="py-2">
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Tên tổ chức chủ trì đề tài"
            name="executiveOrganization.name"
            values={values.executiveOrganization?.name}
            errors={errors.executiveOrganization}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Điện thoại"
            name="executiveOrganization.phone"
            values={values.executiveOrganization?.phone}
            errors={errors.executiveOrganization}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Fax"
            name="executiveOrganization.fax"
            values={values.executiveOrganization?.fax}
            errors={errors.executiveOrganization}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Website"
            name="executiveOrganization.website"
            values={values.executiveOrganization?.website}
            errors={errors.executiveOrganization}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={6} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Địa chỉ"
            name="executiveOrganization.address"
            values={values.executiveOrganization?.address}
            errors={errors.executiveOrganization}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Họ và tên thủ trưởng tổ chức"
            name="executiveOrganization.chiefName"
            values={values.executiveOrganization?.chiefName}
            errors={errors.executiveOrganization}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Số tài khoản"
            name="executiveOrganization.bankNumber"
            values={values.executiveOrganization?.bankNumber}
            errors={errors.executiveOrganization}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Kho bạc nhà nước/Ngân hàng"
            name="executiveOrganization.bankName"
            values={values.executiveOrganization?.bankName}
            errors={errors.executiveOrganization}
            handleChange={handleChange}
          />
        </Col>
        <Col xs={3} className="pb-4">
          <TextFieldCustom
            type="text"
            title="Tên cơ quan chủ quản đề tài"
            name="executiveOrganization.topicSupervisor"
            values={values.executiveOrganization?.topicSupervisor}
            errors={errors.executiveOrganization}
            handleChange={handleChange}
          />
        </Col>
      </Row>
      <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
        8. Các tổ chức phối hợp chính thực hiện đề tài (nếu có):
      </h4>
      <Row className="py-2">
        <Col xs={12} className="label min-w-100px fw-bold pb-2 text-gray">
          <div className="flex justify-content-between align-items-center">
            <p className="m-0">Danh sách tổ chức</p>
            <button
              className="spaces min-w-90px btn btn-primary"
              onClick={() => {
                setDataOrg({});
                setOpenAddOrganization(true);
              }}
              type="button"
            >
              Thêm tổ chức
            </button>
          </div>
        </Col>
        <Col xs={12}>
          <OctTable
            id="outline-organization"
            columns={organizationColumns}
            data={organizationList}
            justFilter={false}
            clearToolbar
            noToolbar
            noPagination
          />
        </Col>
      </Row>
      <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
        9. Cán bộ thực hiện đề tài:
      </h4>
      <Row className="py-2">
        <Col xs={12} className="label min-w-100px fw-bold pb-2 text-gray">
          <div className="flex justify-content-between align-items-center">
            <p className="m-0">Danh sách cán bộ</p>
          </div>
        </Col>
        <Col xs={12}>
          <OctTable
            id="officer-organization"
            columns={officerColumns}
            data={officerList}
            justFilter={false}
            clearToolbar
            noToolbar
            noPagination
          />
        </Col>
      </Row>
      <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 pb-2">
        10. Tệp đính kèm:
      </h4>
      <Row className="py-2">
        <Col xs={4}>
          <FormBS.Group controlId="formFile" className="flex-1">
            <FormBS.Control
              type="file"
              name="file"
              size="sm"
              accept={".doc, .docx"}
              onChange={(e) => handleChangeFile(e)}
            />
          </FormBS.Group>
        </Col>
      </Row>
    </>
  );
};

export default TopicInfo;
