import { createContext, Dispatch, FC, SetStateAction, useState } from "react";
import AcceptanceCouncilManager from "./AcceptanceCouncilManager";
import { initFilter, TAcceptanceCouncil, TAcceptanceCouncilFilter } from "./models/AcceptanceCouncilManagerModels";
import "./AcceptanceCouncilManager.scss"

export interface AcceptanceCouncilContextProps {
  onReset: () => void;
  filter: TAcceptanceCouncilFilter;
  setFilter: Dispatch<SetStateAction<TAcceptanceCouncilFilter>>;
  acceptanceCouncilList: TAcceptanceCouncil[];
  setAcceptanceCouncilList: Dispatch<SetStateAction<TAcceptanceCouncil[]>>;
}

const initialContext = {
  onReset: () => {},
  filter: initFilter,
  setFilter: () => {},
  acceptanceCouncilList: [],
  setAcceptanceCouncilList: () => {}
};

export const AcceptanceCouncilContext =
  createContext<AcceptanceCouncilContextProps>(initialContext);

export const AcceptanceCouncilProvider: FC = () => {
  const [filter, setFilter] = useState<TAcceptanceCouncilFilter>(initFilter);
  const [acceptanceCouncilList, setAcceptanceCouncilList] = useState<TAcceptanceCouncil[]>([]);

  return (
    <AcceptanceCouncilContext.Provider
      value={{
        onReset: () => {},
        filter,
        setFilter,
        acceptanceCouncilList,
        setAcceptanceCouncilList
      }}
    >
      <div className="bg-white h-100">
        <div className="flex-1 flex flex-column">
          <div className="py-1">
            <AcceptanceCouncilManager />
          </div>
        </div>
      </div>
    </AcceptanceCouncilContext.Provider>
  );
};

export default AcceptanceCouncilProvider;
