import { createContext, Dispatch, FC, SetStateAction, useState } from "react";
import { initBatchOfRegistration, initFilter, TBatchOfRegistration, TBatchOfRegistrationFilter } from "./models/OpenBatchOfRegistrationModel";
import OpenBatchOfRegistration from "./OpenBatchOfRegistration";
import "./OpenBatchOfRegistration.scss";

export interface OpenBatchOfRegistrationContextProps {
  batchOfRegistrationInfo: TBatchOfRegistration;
  setBatchOfRegistrationInfo: Dispatch<SetStateAction<TBatchOfRegistration>>;
  batchOfRegistrationList: TBatchOfRegistration[];
  setBatchOfRegistrationList: Dispatch<SetStateAction<TBatchOfRegistration[]>>;
  filter: TBatchOfRegistrationFilter;
  setFilter: Dispatch<SetStateAction<TBatchOfRegistrationFilter>>;
  onReset: () => void;
}

const initialContext: OpenBatchOfRegistrationContextProps = {
  batchOfRegistrationInfo: initBatchOfRegistration,
  setBatchOfRegistrationInfo: () => {},
  batchOfRegistrationList: [],
  setBatchOfRegistrationList: () => {},
  filter: initFilter,
  setFilter: () => {},
  onReset: () => {},
};

export const OpenBatchOfRegistrationContext =
  createContext<OpenBatchOfRegistrationContextProps>(initialContext);

export const OpenBatchOfRegistrationProvider: FC = () => {
  const [batchOfRegistrationInfo, setBatchOfRegistrationInfo] =
    useState<TBatchOfRegistration>(initBatchOfRegistration);
  const [batchOfRegistrationList, setBatchOfRegistrationList] =
    useState<TBatchOfRegistration[]>([]);
  const [filter, setFilter] = useState<TBatchOfRegistrationFilter>(initFilter);

  return (
    <OpenBatchOfRegistrationContext.Provider
      value={{
        batchOfRegistrationInfo,
        setBatchOfRegistrationInfo,
        batchOfRegistrationList,
        setBatchOfRegistrationList,
        filter,
        setFilter,
        onReset: () => {
          setBatchOfRegistrationInfo(initBatchOfRegistration);
        },
      }}
    >
      <div className="bg-white h-100">
        <div className="flex-1 flex flex-column">
          <div className="py-1">
            <OpenBatchOfRegistration />
          </div>
        </div>
      </div>
    </OpenBatchOfRegistrationContext.Provider>
  );
};

export default OpenBatchOfRegistrationProvider;
