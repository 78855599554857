import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { Accordion, Col, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import { TextFieldCustom } from "../../../category-personnel/components/text-field-custom/TextFieldCustom";
import { CODE } from "../../../utils/Constant";
import { handleDownloadFile } from "../../../utils/ServicesUtils";
import { TFileProduct, TTopic } from "../models/TopicModel";
import { getAttachmentProduct } from "../services/services";
import "../Topic.scss";

const TopicProduct: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsLoading } = useContext(AppContext);
  const state = location.state  as { show?: boolean, data?: TTopic };

  const [files, setFiles] = useState<TFileProduct[]>([]);

  useEffect(() => {
    getHistoryFiles();
  }, [])

  const getHistoryFiles = async () => {
    let topicId = state?.data?.id;
    if (!topicId) return;
    try {
      setIsLoading(true);
      let { data } = await getAttachmentProduct({ topicId });
      if (data?.code === CODE.SUCCESS) {
        setFiles(data?.data?.content);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  const handleDownloadAll = async () => {
    for (let index = 0; index < files.length; index++) {
      let fileId = files[index].fileId;
      let fileName = files[index].originalFileName;
      if(!fileId || !fileName) return;
      handleDownloadFile(fileId, fileName);
    }
  }

  return (
    <Modal
      centered
      show={state?.show}
      onHide={() => navigate("/quan-ly-de-tai/de-tai", { replace: true })}
      size="xl"
      fullscreen
      className="page-full spaces h-calc-vh-50"
    >
      <Modal.Header className="header-modal min-h-40px">
        <div className="d-flex gap-6 py-2">
          <div
            className="d-flex align-items-center title-back gap-3 cursor-pointer"
            onClick={() => {
              navigate("/quan-ly-de-tai/de-tai", { replace: true });
            }}
          >
            <i className="spaces bi bi-chevron-left ml-10"> </i>
            <div className="spaces mr-20">Quay lại</div>
          </div>
          <h4 className="text-title border-bottom text-pri fw-bold fs-4 spaces mb-0 px-10">
            Sản phẩm
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body className="spaces py-10 px-0 mt-3 bg-white">
        {files.length > 0 ? (
          <>
            <div className="spaces px-10">
              <div className="">
                <Col xs={12}>
                  <div className="flex py-2 align-items-center">
                    <div className="label fw-bold min-w-150px">Ngày bắt đầu:</div>
                    <div className="flex-1">
                      {state?.data?.startDate &&
                        moment(state?.data?.startDate).format("DD/MM/YYYY")}
                    </div>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="flex py-2 align-items-center">
                    <div className="label fw-bold min-w-150px">Ngày kết thúc:</div>
                    <div className="flex-1">
                      {state?.data?.endDate &&
                        moment(state?.data?.endDate).format("DD/MM/YYYY")}
                    </div>
                  </div>
                </Col>
              </div>
            </div>
            <div className="flex justify-content-between align-items-center">
              <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 p-10">
                Lịch sử nộp báo cáo
              </h4>
              <div className="spaces pe-2">
                <button
                  type="button"
                  className="spaces min-w-90px btn btn-primary btn btn-primary"
                  onClick={() => handleDownloadAll()}
                >
                  Tải tất cả
                </button>
              </div>
            </div>
          </>
        ): (
          <div className="flex-column spaces p-10">
            <i className="bi bi-journal-x text-gray text-center" style={{ fontSize: "50px" }}></i>
            <h4 className="text-title text-gray fw-bold fs-4 spaces mb-0 p-10 text-center">
              Chưa có báo cáo nào được nộp
            </h4>
          </div>
        )}
        <Accordion defaultActiveKey="0">
          {files?.map((file: TFileProduct, index: number) => (
            <Accordion.Item eventKey={index.toString()}>
              <Accordion.Header>{`Ngày ${file.date ? moment(file.date).format("DD/MM/YYYY") : ""}`}</Accordion.Header>
              <Accordion.Body>
                <div className="spaces px-10 m-0">
                  <Row className="flex align-items-end">
                    <Col xs={6} className="spaces">
                      <div className="flex py-2 align-items-center">
                        <div className="label fw-bold min-w-150px">
                          Tập tin sản phẩm:
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name="file"
                            disabled
                            values={files[index]?.originalFileName}
                            handleChange={() => {}}
                            errors=""
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className="spaces pb-7">
                      <button
                        type="button"
                        className="spaces min-w-90px btn btn-primary btn btn-primary"
                        onClick={() => {
                          handleDownloadFile(String(files[index]?.fileId), String(files[index]?.originalFileName));
                        }}
                      >
                        Tải xuống
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <div className="flex py-2 align-items-center">
                        <div className="label fw-bold min-w-150px">Báo cáo:</div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            as="textarea"
                            className="min-h-90px"
                            title=""
                            name="content"
                            disabled
                            values={files[index]?.content}
                            handleChange={() => {}}
                            errors=""
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Modal.Body>
    </Modal>
  );
};
export default TopicProduct;
