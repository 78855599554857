export const listModule = [
    {
        name: "Đợt đăng ký",
        to: "/quan-ly-de-tai/mo-dot-dang-ky",
        imgSrc: "media/svg/dashboard-icons/hrm.svg"
    },
    {
        name: "Đề tài",
        to: "/quan-ly-de-tai/de-tai",
        imgSrc: "media/svg/dashboard-icons/hrm.svg"
    },
    {
        name: "Đăng ký đề tài",
        to: "/dang-ky-de-tai",
        imgSrc: "media/svg/dashboard-icons/hrm.svg"
    },
    {
        name: "Đề tài của tôi",
        to: "/de-tai-cua-toi",
        imgSrc: "media/svg/dashboard-icons/hrm.svg"
    },
    {
        name: "Quản lý hội đồng",
        to: "/quan-ly-hoi-dong/khoa-hoc",
        imgSrc: "media/svg/dashboard-icons/hrm.svg"
    },
]