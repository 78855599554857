import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import { addReviewScienceCouncil, getCommittees, getTopicById } from "../services/services";
import { CODE, MEMBER_TYPE } from "../../../utils/Constant";
import { TMainResearcher, TMember, TTopicDetails } from "../models/TopicModel";
import { getScienceCouncilById } from "../../../quan-ly-hoi-dong/khoa-hoc/services/services";
import moment from "moment";
export type TEvaluationForm = {
  topicId?: string;
  topicName?: string;
  topicPiName?: string;
  topicCoPiName?: string;
  committeeRecordCode?: string;
  committeeRecordDate?: string;
  committeeRecordPlace?: string;
  reviewerName?: string;
  criterion1?: number;
  criterion2?: number;
  criterion3?: number;
  criterion4?: number;
  criterion5?: number;
  criterion6?: number;
  criterion7?: number;
  criterion8?: number;
  sum?: number;
  opinion?: string;
};
const initAddEvaluationForm: TEvaluationForm = {
  reviewerName: "",
  topicName: "",
  topicPiName: "",
  topicCoPiName: "",
  committeeRecordDate: "",
  committeeRecordPlace: "",
  criterion1: 0,
  criterion2: 0,
  criterion3: 0,
  criterion4: 0,
  criterion5: 0,
  criterion6: 0,
  criterion7: 0,
  criterion8: 0,
  sum:0,
  opinion: ""
};
interface Props {
  show: boolean;
  onHide: () => void;
  id?: string;
  councilId?: string;
  onRefresh?: () => Promise<void>;
  dataDetails?: TEvaluationForm;
  isView?: boolean;
}
const EvaluationForm = (props: Props) => {
  const { show, onHide, id, councilId, onRefresh, dataDetails, isView } = props;
  const { setIsLoading } = useContext(AppContext);

  const [valueEvaluation, setValueEvaluation] = useState(initAddEvaluationForm);
  const [committeeInfo, setCommitteeInfo] = React.useState<any>({});

  useEffect(() => {
    if (!id) return;
    getTopicDetails(id);
  }, [id]);

  useEffect(() => {
    if(!councilId) return;
    getCommitteesInfo(councilId);
  }, [councilId]);

  useEffect(() => {
    if (!dataDetails) return;
    setValueEvaluation(dataDetails);
  }, [dataDetails]);

  const getTopicDetails = async (topicId: string) => {
    try {
      setIsLoading(true);
      let { data } = await getTopicById(topicId);
      if (data?.code === CODE.SUCCESS) {
        setValueEvaluation({
          ...valueEvaluation,
          topicName: data?.data?.name,
          topicPiName: data?.data?.members?.find(
            (member: TMember) => member?.typeId === MEMBER_TYPE.CHU_NHIEM_DE_TAI
          )?.name,
          topicCoPiName: data?.data?.members?.find(
            (member: TMember) => member?.typeId === MEMBER_TYPE.DONG_CHU_NHIEM
          )?.name
        })
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getCommitteesInfo = async (committeeId: string) => {
    try {
      setIsLoading(true);
      let { data } = await getCommittees(committeeId);
      if (data?.code === CODE.SUCCESS) {
        setCommitteeInfo(data?.data);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleChange = (e: any) => {
    const { name, value, type } = e.target;
    setValueEvaluation({
      ...valueEvaluation,
      [name]: type === "number" ? Number(value) : value,
    });
  };

  const handleBlur = (e: any) => {
    const { name, value, min, max } = e?.target;
    if(Number(value) < Number(min)){
      setValueEvaluation({
        ...valueEvaluation,
        [name]: +min
      })
    } else if(Number(value) > Number(max)){
      setValueEvaluation({
        ...valueEvaluation,
        [name]: +max
      })
    } else {
      setValueEvaluation({
        ...valueEvaluation,
        [name]: Number(value)
      })
    }
  }
  const handleSubmit = async (e:any) => {
    e.preventDefault();
    let dataReview = {
      ...valueEvaluation,
      topicId: id,
      sum: (valueEvaluation?.criterion1 || 0) + (valueEvaluation?.criterion2 || 0) + (valueEvaluation?.criterion3 || 0) + (valueEvaluation?.criterion4 || 0) + (valueEvaluation?.criterion5 || 0) + (valueEvaluation?.criterion6 || 0) + (valueEvaluation?.criterion7 || 0) + (valueEvaluation?.criterion8 || 0)
    }
    try {
      setIsLoading(true);
      let { data } = await addReviewScienceCouncil(dataReview);
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        toast.success("Thêm đánh giá thành công");
        onHide();
        onRefresh && onRefresh();
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="lg"
      className="spaces h-calc-vh-50"
      scrollable
    >
      <Modal.Header className="header-modal min-h-40px" closeButton>
        <Modal.Title className="py-4">Phiếu đánh giá thuyết minh đề tài khoa học & công nghệ</Modal.Title>
      </Modal.Header>
      <Form className="form-evaluation" onSubmit={(e)=>handleSubmit(e)}>
        <Modal.Body className="a4-page">
          <Row>
            <Col xs={12}>
              <div className="mb-4">
                <h3 className="text-center">PHIẾU ĐÁNH GIÁ</h3>
                <h3 className="text-center">
                  THUYẾT MINH ĐỀ TÀI KHOA HỌC VÀ CÔNG NGHỆ CẤP CƠ SỞ
                </h3>
              </div>
            </Col>
            <Col xs={12}>
              <div className="mb-4">
                <div className="fw-bold">1. Họ và tên thành viên hội đồng:</div>
                <input
                  type="text"
                  name="reviewerName"
                  readOnly={isView}
                  className="text-field field-name"
                  value={valueEvaluation?.reviewerName}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </Col>
            <Col xs={12}>
              <div className="mb-4">
                <b>2. Tên đề tài: "{valueEvaluation?.topicName}"</b>
              </div>
              <div className="d-flex mb-4">
                <div className="form-title"><b>3. Chủ nhiệm đề tài:</b> {valueEvaluation?.topicPiName}</div>
              </div>
              {valueEvaluation?.topicCoPiName && (
                <div className="d-flex mb-4">
                  <div className="form-title"> Đồng chủ nhiệm đề tài: {valueEvaluation?.topicCoPiName}</div>
                </div>
              )}
              <div className="mb-4">
                <b>4. Quyết định thành lập Hội đồng số &nbsp;</b>{committeeInfo?.code}
                {/* <input
                  type="text"
                  style={{ width: "50px", display: "inline-block", textAlign: "center" }}
                  className="text-field"
                  name="committeeRecordCode"
                  value={valueEvaluation?.committeeRecordCode}
                  onChange={(e) => handleChange(e)}
                /> */}
                /QĐ-BVĐKSS
              </div>
              <div className="d-flex mb-4">
                <div className="form-title"><b>5. Cơ quan thực hiện:</b> Bệnh viện đa khoa Mỹ Đức</div>
              </div>
              <div className="d-flex mb-4">
                <div className="form-title"><b>6. Ngày họp:</b> {valueEvaluation?.committeeRecordDate ? moment(valueEvaluation?.committeeRecordDate).format("DD/MM/YYYY") : ""}</div>
                {/* <input
                  type="date"
                  name="committeeRecordDate"
                  className="date-field"
                  value={valueEvaluation?.committeeRecordDate}
                  onChange={(e) => handleChange(e)}
                /> */}
              </div>
              <div className="d-flex mb-4">
                <div className="form-title"><b>7. Địa điểm:</b> {valueEvaluation?.committeeRecordPlace || committeeInfo?.place}</div>
                {/* <input
                  type="text"
                  className="text-field"
                  name="committeeRecordPlace"
                  value={valueEvaluation?.committeeRecordPlace}
                  onChange={(e) => handleChange(e)}
                /> */}
              </div>
              <div>
                <span className="mb-2 pb-2">
                  Ý kiến đánh giá theo các nội dung sau:
                </span>
                <Table bordered className="table-bordered">
                  <thead>
                    <tr>
                      <td style={{ width: "60%", textAlign: "center", padding: "10px" }}>
                        Nội dung đánh giá
                      </td>
                      <td style={{ width: "20%", textAlign: "center", padding: "10px" }}>
                        Điểm tối đa
                      </td>
                      <td style={{ width: "20%", textAlign: "center", padding: "10px" }}>
                        Điểm đánh giá
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ paddingLeft: "10px" }}>
                        1. Tổng quan kết quả nghiên cứu trong và ngoài nước
                        trong lĩnh vực đề tài.
                      </td>
                      <td className="text-center">5</td>
                      <td className="text-center">
                        <input
                          type="number"
                          className="outline-border text-center"
                          min={0}
                          max={5}
                          required
                          readOnly={isView}
                          name="criterion1"
                          value={valueEvaluation?.criterion1}
                          onChange={(e) => handleChange(e)}
                          onBlur={(e) => handleBlur(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: "10px" }}>2. Xác định mục tiêu, tính cấp thiết</td>
                      <td className="text-center">10</td>
                      <td className="text-center">
                        <input
                          type="number"
                          min={0}
                          max={10}
                          required
                          readOnly={isView}
                          className="outline-border text-center"
                          name="criterion2"
                          value={valueEvaluation?.criterion2}
                          onChange={(e) => handleChange(e)}
                          onBlur={(e) => handleBlur(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: "10px" }}>
                        3. Xác định nội dung, tiến độ thực hiện và phương pháp
                        nghiên cứu.
                      </td>
                      <td className="text-center">30</td>
                      <td className="text-center">
                        <input
                          type="number"
                          min={0}
                          max={30}
                          required
                          readOnly={isView}
                          className="outline-border text-center"
                          name="criterion3"
                          value={valueEvaluation?.criterion3}
                          onChange={(e) => handleChange(e)}
                          onBlur={(e) => handleBlur(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: "10px" }}>
                        4. Sản phẩm và khả năng ứng dụng kết quả nghiên cứu
                      </td>
                      <td className="text-center">25</td>
                      <td className="text-center">
                        <input
                          type="number"
                          min={0}
                          max={25}
                          required
                          readOnly={isView}
                          className="outline-border text-center"
                          name="criterion4"
                          value={valueEvaluation?.criterion4}
                          onChange={(e) => handleChange(e)}
                          onBlur={(e) => handleBlur(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: "10px" }}>
                        5. Kinh nghiệm nghiên cứu, những thành tích nổi bật và
                        năng lực quản lý của cá nhân đăng ký chủ trì đề tài
                      </td>
                      <td className="text-center">9</td>
                      <td className="text-center">
                        <input
                          type="number"
                          min={0}
                          max={9}
                          required
                          readOnly={isView}
                          className="outline-border text-center"
                          name="criterion5"
                          value={valueEvaluation?.criterion5}
                          onChange={(e) => handleChange(e)}
                          onBlur={(e) => handleBlur(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: "10px" }}>
                        6. Kinh nghiệm nghiên cứu, những thành tích của những
                        người tham gia thực hiện đề tài
                      </td>
                      <td className="text-center">3</td>
                      <td className="text-center">
                        <input
                          type="number"
                          min={0}
                          max={3}
                          required
                          readOnly={isView}
                          className="outline-border text-center"
                          name="criterion6"
                          value={valueEvaluation?.criterion6}
                          onChange={(e) => handleChange(e)}
                          onBlur={(e) => handleBlur(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: "10px" }}>7. Tiềm lực của cơ quan chủ trì đề tài</td>
                      <td className="text-center">8</td>
                      <td className="text-center">
                        <input
                          type="number"
                          min={0}
                          max={8}
                          required
                          readOnly={isView}
                          className="outline-border text-center"
                          name="criterion7"
                          value={valueEvaluation?.criterion7}
                          onChange={(e) => handleChange(e)}
                          onBlur={(e) => handleBlur(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingLeft: "10px" }}>8. Tính hợp lý của dự toán kinh phí đề nghị</td>
                      <td className="text-center">10</td>
                      <td className="text-center">
                        <input
                          type="number"
                          min={0}
                          max={10}
                          required
                          readOnly={isView}
                          className="outline-border text-center"
                          name="criterion8"
                          value={valueEvaluation?.criterion8}
                          onChange={(e) => handleChange(e)}
                          onBlur={(e) => handleBlur(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center toUpperCase">Cộng</td>
                      <td className="text-center">100</td>
                      <td className="text-center" style={{ paddingRight: "10px" }}>
                        {Number(valueEvaluation?.criterion1 || 0) + 
                        Number(valueEvaluation?.criterion2 || 0) + 
                        Number(valueEvaluation?.criterion3 || 0) + 
                        Number(valueEvaluation?.criterion4 || 0) + 
                        Number(valueEvaluation?.criterion5 || 0) + 
                        Number(valueEvaluation?.criterion6 || 0) + 
                        Number(valueEvaluation?.criterion7 || 0) + 
                        Number(valueEvaluation?.criterion8 || 0)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <span className="mb-4">
                  Ghi chú:{" "}
                  {`Đề tài được thông qua khi đạt >70 điểm (trong đó tổng điểm của 4 tiêu chí 1,2,3,4 > 50 điểm).`}
                </span>
              </div>
              <div>
                Ý kiến khác:
                <Form.Group>
                  <Form.Control
                    {...props}
                    as="textarea"
                    rows={4}
                    value={valueEvaluation?.opinion}
                    name="opinion"
                    readOnly={isView}
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Group>
              </div>
            </Col>
            {/* <div className="sign flex justify-content-end py-10">
              <div className="flex-column text-center">
                <p className="fs-4 fw-bold">Hà Nội, ngày....tháng....năm 2024</p>
                <p className="fs-4 fw-bold">Ký tên</p>
              </div>
            </div> */}
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          {!isView && (
            <Button
                variant="primary"
                className="min-w-75px fw-bold"
                type="submit"
              >
                {"Lưu"}
            </Button>
          )}
          <Button
              variant="secondary"
              className="min-w-75px fw-bold"
              onClick={onHide}
            >
              Đóng
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EvaluationForm;
