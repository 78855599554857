import moment from "moment";

const currentYear = moment().year();
// Đợt đăng ký
export type TBatchOfRegistration = {
  id?: string;
  startDate?: string;
  name?: string;
  statusId?: number;
  statusName?: string;
  topicIdOfCurrentUser?: string;
  proposalCode?: string;
};

export const initBatchOfRegistration: TBatchOfRegistration = {
  startDate: "",
  name: "",
};

// Thành viên
export type TMember = {
  id?: string;
  fakeId?: string;
  name?: string;
  position?: string;
  typeId?: number;
  isKeyMember?: boolean;
  gender?: number;
  academicRank?: string;
  degree?: string;
  scientificTitle?: string;
  officeCurrently?: string;
  department?: string;
  email?: string;
  phone?: string;
  eduLevel?: string;
  office?: string;
  fax?: string;
  officeAddress?: string;
}

export const initMember: TMember = {
  name: "",
  position: "",
  typeId: 3,
  isKeyMember: false
}

// Giảng viên chủ nhiệm
export type TTeacher = {
  id?: string;
  fakeId?: string;
  name?: string;
  position?: string;
  typeId?: number;
  isKeyMember?: boolean;
  academicRank?: string;
  degree?: string;
  scientificTitle?: string;
  officeCurrently?: string;
  gender?: number;
}

// Thông tin đăng ký đề tài
export type TTopicRegistration = {
  id?: string;
  date?: string;
  numMembers?: number;
  memberList: TMember[];
  numTeachers?: number;
  teacherList: TTeacher[];
}

export const initTopicRegistration: TTopicRegistration = {
  numMembers: 1,
  memberList: [{
    id: "",
    name: "",
    position: ""
  }],
  numTeachers: 1,
  teacherList: [{
    id: "",
    name: "",
    position: ""
  }],
}

// Tìm kiếm
export type TBatchOfRegistrationFilter = {
  year?: string;
  yearOption?: any;
  status?: string;
  keyword?: string;
  statusOption?: any;
  pageIndex?: number;
  pageSize?: number;
};

export const initFilter: TBatchOfRegistrationFilter = {
  year: currentYear.toString(),
  status: "all",
  keyword: "",
  pageIndex: 1,
  pageSize: 10
};

// Chủ nhiệm đề tài/Nghiên cứu viên chính
export type TMainResearcher = {
  fakeId?: string;
  name?: string;
  department?: string;
  departmentOption?: any;
  position?: string;
  phone?: string;
  email?: string;
  typeId?: number;
  academicRank?: string;
  degree?: string;
  scientificTitle?: string;
  officeCurrently?: string;
  gender?: number;
  eduLevel?: string;
  office?: string;
  fax?: string;
  officeAddress?: string;
}

// Thư ký đề tài
export type TTopicSecretary = {
  name?: string;
  department?: string;
  departmentOption?: any;
  position?: string;
  phone?: string;
  email?: string;
}

// Phiếu đề xuất
export type TProposal = {
  name?: string; // Tên đề tài
  date?: string; // Ngày đăng ký
  mainResearcher?: TMainResearcher; // Chủ nhiệm đề tài
  coMainResearcher?: TMainResearcher; // Đồng chủ nhiệm đề tài
  topicSecretary?: TTopicSecretary; // Thư ký đề tài
  participatingMembers?: TMember[]; // Thành viên tham gia
  urgency?: string; // Tính cấp thiết
  objective?: string; // Mục tiêu
  content?: string; // Nội dung
  estimatedTimeframe?: string; // Thời gian ước tính
  expectedOutcome?: string; // Dự kiến sản phẩm
  feasibility?: string; // Khả năng ứng dụng
  otherInformation?: string; // Thông tin khác
  memberQuantity?: number; // Số lượng thành viên tham gia
  hasCoMainResearcher?: boolean;
  hasTopicSecretary?: boolean;
}

export const initProposal: TProposal = {
  name: "",
  mainResearcher: {
    name: "",
    department: "",
    position: "",
    phone: "",
    email: ""
  },
  hasCoMainResearcher: false,
  hasTopicSecretary: false,
  participatingMembers: [{},{},{},{}],
  urgency: "",
  objective: "",
  content: "",
  estimatedTimeframe: "",
  expectedOutcome: "",
  feasibility: "",
  otherInformation: ""
}
