import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { addReviewAcceptanceCouncil, getCommittees, getTopicById, getUserDetails } from "../services/services";
import { CODE, DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE } from "../../../utils/Constant";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import moment from "moment";
export type TAcceptanceForm = {
  topicId?: string;
  topicName?: string;
  topicCode?: string;
  duration?: string;
  reviewerName?: string;
  reviewerAcademicTitle?: string;
  reviewerPosition?: string;
  reviewerExpertise?: string;
  criterion1?: string;
  criterion2?: string;
  criterion3?: string;
  criterion4?: string;
  criterion5?: string;
  criterion6?: string;
  criterion7?: string
};
const initAddAcceptanceForm: TAcceptanceForm = {
  reviewerName: "",
  topicName: "",
  topicCode: ""
};
interface Props {
  show: boolean;
  onHide: () => void;
  id?: string;
  onRefresh?: () => Promise<void>;
  dataDetails?: TAcceptanceForm;
  isView?: boolean;
}
const AcceptanceForm = (props: Props) => {
  const { show, onHide, id, onRefresh, isView, dataDetails } = props;
  const { setIsLoading } = useContext(AppContext);

  const [valueAcceptance, setValueAcceptance] = useState(initAddAcceptanceForm);
  const [numRows, setNumRows] = React.useState<any>({});
  const [reviewer, setReviewer] = React.useState<string>();

  useEffect(() => {
    if (!id) return;
    getTopicDetails(id);
  }, [id]);

  useEffect(() => {
    if (!dataDetails) return;
    setValueAcceptance(dataDetails);
  }, [dataDetails]);

  // useEffect(() => {
  //   if (dataDetails) return;
  //   getUserInfo();
  // }, [dataDetails]);

  const getTopicDetails = async (topicId: string) => {
    try {
      setIsLoading(true);
      let { data } = await getTopicById(topicId);
      if (data?.code === CODE.SUCCESS) {
        setValueAcceptance({
          ...initAddAcceptanceForm,
          topicName: data?.data?.name,
          topicCode: data?.data?.code,
          duration: `Từ tháng ${data?.data?.startDate ? moment(data?.data?.startDate).format("MM/YYYY") : ""} đến tháng ${data?.data?.endDate ? moment(data?.data?.endDate).format("MM/YYYY") : ""}`,
        })
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getUserInfo = async () => {
    try {
      let { data } = await getUserDetails({
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: MAX_PAGE_SIZE
      });
      if (data?.code === CODE.SUCCESS) {
      } else {
        toast.error("Đã có lỗi xảy ra!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const autoResize = (textarea: any) => {
    const name = textarea.name;
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
    setNumRows({
      ...numRows,
      [name]: textarea.scrollHeight / 24,
    });
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValueAcceptance({
      ...valueAcceptance,
      [name]: value,
    });
    autoResize(e.target)
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let dataReview = {
      ...valueAcceptance,
      topicId: id
    }
    try {
      setIsLoading(true);
      let { data } = await addReviewAcceptanceCouncil(dataReview);
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        toast.success("Thêm đánh giá thành công");
        onHide();
        onRefresh && onRefresh();
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="lg"
      className="spaces h-calc-vh-50"
      scrollable
    >
      <Modal.Header className="header-modal min-h-40px" closeButton>
        <Modal.Title className="py-4">Phiếu đánh giá nghiệm thu công trình nghiên cứu KHCN</Modal.Title>
      </Modal.Header>
      <Form className="form-evaluation" onSubmit={(e) => handleSubmit(e)}>
        <Modal.Body className="a4-page">
          <Row>
            <Col
              xs={12}
              className="d-flex"
              style={{ justifyContent: "space-between" }}
            >
              <div className="mb-4">
                <h3 className="text-center" style={{ fontWeight: "normal" }}>
                  SỞ Y TẾ HÀ NỘI
                </h3>
                <h3
                  className="text-center"
                  style={{ borderBottom: "1px solid black" }}
                >
                  BỆNH VIỆN ĐA KHOA MỸ ĐỨC
                </h3>
              </div>
              <div className="mb-4">
                <h3 className="text-center" style={{ fontWeight: "normal" }}>
                  CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM
                </h3>
                <h3
                  className="text-center"
                  style={{ borderBottom: "1px solid black" }}
                >
                  Độc lập- Tự do- Hạnh phúc
                </h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="mb-4">
                <h3 className="text-center">
                  PHIẾU ĐÁNH GIÁ NGHIỆM THU CÔNG TRÌNH NGHIÊN CỨU KHCN
                </h3>
                <h3 className="text-center" style={{ fontWeight: "normal" }}>
                  HỘI ĐỒNG KHCN NGHIỆM THU CẤP CƠ SỞ
                </h3>
              </div>
            </Col>
            <Col xs={12}>
              <div
                className="mb-4 d-flex"
                style={{ border: "1px solid black", padding: "10px" }}
              >
                <div
                  className="name-topic"
                  style={{ borderRight: "1px solid black", width: "50%" }}
                >
                  <span style={{ fontWeight: "normal" }}>Tên đề tài:</span>"
                  {valueAcceptance.topicName}"
                </div>
                <div style={{width: "50%",paddingLeft: "10px"}}>
                <span style={{ fontWeight: "normal" }}>Mã số: </span>
                {valueAcceptance.topicCode}
                <div>
                Thời gian thực hiện đề tài: {valueAcceptance?.duration}
                </div>
                </div>
                
              </div>
            </Col>
            <Col xs={12}>
              <div className="d-flex mb-4">
                <div className="form-title">Họ và tên ủy viên hội đồng: </div>
                <input
                  style={{ width: "480px" }}
                  type="text"
                  name="reviewerName"
                  readOnly={isView}
                  className="text-field"
                  value={valueAcceptance.reviewerName}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="d-flex mb-4">
                <div className="form-title"> Học hàm học vị:</div>
                <input
                  style={{ width: "545px" }}
                  type="text"
                  readOnly={isView}
                  className="text-field"
                  name="reviewerAcademicTitle"
                  value={valueAcceptance.reviewerAcademicTitle}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="d-flex mb-4">
                <div className="form-title"> Chức vụ :</div>
                <input
                  style={{ width: "584px" }}
                  type="text"
                  readOnly={isView}
                  className="text-field"
                  name="reviewerPosition"
                  value={valueAcceptance.reviewerPosition}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="d-flex mb-4">
                <div className="form-title">Chuyên môn :</div>
                <input
                  style={{ width: "559px" }}
                  type="text"
                  readOnly={isView}
                  className="text-field"
                  name="reviewerExpertise"
                  value={valueAcceptance.reviewerExpertise}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div>
                <div className="mb-2 text-center"
                style={{fontWeight: "bold"}}
                >
                Ý KIẾN NHẬN XÉT ĐÁNH GIÁ
                </div>
              </div>
              <div className="mb-4">
              <div>
               <div style={{fontWeight:'bold'}}>1.Về các kết quả đạt được so với nội dung nghiên cứu được phê duyệt:</div>
               <em>(Khối lượng và chất lượng sản phẩm. Giá trị khoa học môi trường, công nghệ, kỹ thuật,...)</em>
                </div>
                <div className="relative">
                    <textarea
                      className="no-padding custom-input"
                      title="Nhập nhận xét"
                      wrap="hard"
                      name="criterion1"
                      readOnly={isView}
                      value={valueAcceptance?.criterion1}
                      onChange={(e) => handleChange(e)}
                      style={{
                        fontFamily: "Times New Roman",
                        fontSize: "16px",
                        display: "block",
                        zIndex: "1",
                        width: "100%",
                        outline: "unset",
                        position: "relative",
                        resize: "none",
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                        height: "auto",
                        minHeight: "24px",
                        backgroundColor: "transparent",
                      }}
                    />
                    {Array.from({
                      length: isNaN(numRows?.criterion1)
                        ? 1
                        : numRows?.criterion1,
                    })?.map((item: any, index: any) => (
                      <span
                        key={index}
                        style={{
                          position: "absolute",
                          top: `${
                            (1 / numRows?.criterion1 || 0) * 100 * index
                          }%`,
                          right: "0",
                          left: "0",
                          width: "100%",
                          height: `24px`,
                          borderBottom: "1px dashed",
                          transform: "translateY(-2px)",
                          backgroundColor: "transparent",
                        }}
                       
                      ></span>
                    ))}
                  </div>
              </div>
              <div className="mb-4">
               <div style={{fontWeight:'bold'}}>2. Đánh giá chung về việc thực hiện các mục tiêu đặt ra:</div>
                <div className="relative">
                    <textarea
                      className="no-padding custom-input"
                      title="Nhập nhận xét"
                      wrap="hard"
                      readOnly={isView}
                      name="criterion2"
                      value={valueAcceptance?.criterion2}
                      onChange={(e) => handleChange(e)}
                      style={{
                        fontFamily: "Times New Roman",
                        fontSize: "16px",
                        display: "block",
                        zIndex: "1",
                        width: "100%",
                        outline: "unset",
                        position: "relative",
                        resize: "none",
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                        height: "auto",
                        minHeight: "24px",
                        backgroundColor: "transparent",
                      }}
                    />
                    {Array.from({
                      length: isNaN(numRows?.criterion2)
                        ? 1
                        : numRows?.criterion2,
                    })?.map((item: any, index: any) => (
                      <span
                        key={index}
                        style={{
                          position: "absolute",
                          top: `${
                            (1 / numRows?.criterion2 || 0) * 100 * index
                          }%`,
                          right: "0",
                          left: "0",
                          width: "100%",
                          height: `24px`,
                          borderBottom: "1px dashed",
                          transform: "translateY(-2px)",
                          backgroundColor: "transparent",
                        }}
                       
                      ></span>
                    ))}
                  </div>
              </div>
              <div className="mb-4">
              <div>
               <div style={{fontWeight:'bold'}}>3. Tính hợp lý và khoa học của phương pháp nghiên cứu:</div>
                </div>
                <div className="relative">
                    <textarea
                      className="no-padding custom-input"
                      title="Nhập nhận xét"
                      wrap="hard"
                      readOnly={isView}
                      name="criterion3"
                      value={valueAcceptance?.criterion3}
                      onChange={(e) => handleChange(e)}
                      style={{
                        fontFamily: "Times New Roman",
                        fontSize: "16px",
                        display: "block",
                        zIndex: "1",
                        width: "100%",
                        outline: "unset",
                        position: "relative",
                        resize: "none",
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                        height: "auto",
                        minHeight: "24px",
                        backgroundColor: "transparent",
                      }}
                    />
                    {Array.from({
                      length: isNaN(numRows?.criterion3)
                        ? 1
                        : numRows?.criterion3,
                    })?.map((item: any, index: any) => (
                      <span
                        key={index}
                        style={{
                          position: "absolute",
                          top: `${
                            (1 / numRows?.criterion3 || 0) * 100 * index
                          }%`,
                          right: "0",
                          left: "0",
                          width: "100%",
                          height: `24px`,
                          borderBottom: "1px dashed",
                          transform: "translateY(-2px)",
                          backgroundColor: "transparent",
                        }}
                       
                      ></span>
                    ))}
                  </div>
              </div>
              <div className="mb-4">
              <div>
               <div style={{fontWeight:'bold'}}>4. Khả năng ứng dụng vào thực tiễn:</div>
                </div>
                <div className="relative">
                    <textarea
                      className="no-padding custom-input"
                      title="Nhập nhận xét"
                      wrap="hard"
                      name="criterion4"
                      readOnly={isView}
                      value={valueAcceptance?.criterion4}
                      onChange={(e) => handleChange(e)}
                      style={{
                        fontFamily: "Times New Roman",
                        fontSize: "16px",
                        display: "block",
                        zIndex: "1",
                        width: "100%",
                        outline: "unset",
                        position: "relative",
                        resize: "none",
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                        height: "auto",
                        minHeight: "24px",
                        backgroundColor: "transparent",
                      }}
                    />
                    {Array.from({
                      length: isNaN(numRows?.criterion4)
                        ? 1
                        : numRows?.criterion4,
                    })?.map((item: any, index: any) => (
                      <span
                        key={index}
                        style={{
                          position: "absolute",
                          top: `${
                            (1 / numRows?.criterion4 || 0) * 100 * index
                          }%`,
                          right: "0",
                          left: "0",
                          width: "100%",
                          height: `24px`,
                          borderBottom: "1px dashed",
                          transform: "translateY(-2px)",
                          backgroundColor: "transparent",
                        }}
                       
                      ></span>
                    ))}
                  </div>
              </div>
              <div className="mb-4">
              <div>
               <div style={{fontWeight:'bold'}}>5. Những nội dung cần được nghiên cứu bổ sung:</div>
                </div>
                <div className="relative">
                    <textarea
                      className="no-padding custom-input"
                      title="Nhập nhận xét"
                      wrap="hard"
                      readOnly={isView}
                      name="criterion5"
                      value={valueAcceptance?.criterion5}
                      onChange={(e) => handleChange(e)}
                      style={{
                        fontFamily: "Times New Roman",
                        fontSize: "16px",
                        display: "block",
                        zIndex: "1",
                        width: "100%",
                        outline: "unset",
                        position: "relative",
                        resize: "none",
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                        height: "auto",
                        minHeight: "24px",
                        backgroundColor: "transparent",
                      }}
                    />
                    {Array.from({
                      length: isNaN(numRows?.criterion5)
                        ? 1
                        : numRows?.criterion5,
                    })?.map((item: any, index: any) => (
                      <span
                        key={index}
                        style={{
                          position: "absolute",
                          top: `${
                            (1 / numRows?.criterion5 || 0) * 100 * index
                          }%`,
                          right: "0",
                          left: "0",
                          width: "100%",
                          height: `24px`,
                          borderBottom: "1px dashed",
                          transform: "translateY(-2px)",
                          backgroundColor: "transparent",
                        }}
                       
                      ></span>
                    ))}
                  </div>
              </div>
              <div className="mb-4">
              <div>
               <div style={{fontWeight:'bold'}}>6. Đánh giá<em style={{fontWeight:'normal'}}>(Đủ hay không đủ điều kiện):</em></div>
                </div>
                <div className="relative">
                    <textarea
                      className="no-padding custom-input"
                      title="Nhập nhận xét"
                      wrap="hard"
                      readOnly={isView}
                      name="criterion6"
                      value={valueAcceptance?.criterion6}
                      onChange={(e) => handleChange(e)}
                      style={{
                        fontFamily: "Times New Roman",
                        fontSize: "16px",
                        display: "block",
                        zIndex: "1",
                        width: "100%",
                        outline: "unset",
                        position: "relative",
                        resize: "none",
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                        height: "auto",
                        minHeight: "24px",
                        backgroundColor: "transparent",
                      }}
                    />
                    {Array.from({
                      length: isNaN(numRows?.criterion6)
                        ? 1
                        : numRows?.criterion6,
                    })?.map((item: any, index: any) => (
                      <span
                        key={index}
                        style={{
                          position: "absolute",
                          top: `${
                            (1 / numRows?.criterion6 || 0) * 100 * index
                          }%`,
                          right: "0",
                          left: "0",
                          width: "100%",
                          height: `24px`,
                          borderBottom: "1px dashed",
                          transform: "translateY(-2px)",
                          backgroundColor: "transparent",
                        }}
                       
                      ></span>
                    ))}
                  </div>
              </div>
              <div className="mb-4">
              <div>
               <div style={{fontWeight:'bold'}}>7. Kiến nghị:</div>
                </div>
                <div className="relative">
                    <textarea
                      className="no-padding custom-input"
                      title="Nhập nhận xét"
                      wrap="hard"
                      readOnly={isView}
                      name="criterion7"
                      value={valueAcceptance?.criterion7}
                      onChange={(e) => handleChange(e)}
                      style={{
                        fontFamily: "Times New Roman",
                        fontSize: "16px",
                        display: "block",
                        zIndex: "1",
                        width: "100%",
                        outline: "unset",
                        position: "relative",
                        resize: "none",
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                        height: "auto",
                        minHeight: "24px",
                        backgroundColor: "transparent",
                      }}
                    />
                    {Array.from({
                      length: isNaN(numRows?.criterion7)
                        ? 1
                        : numRows?.criterion7,
                    })?.map((item: any, index: any) => (
                      <span
                        key={index}
                        style={{
                          position: "absolute",
                          top: `${
                            (1 / numRows?.criterion7 || 0) * 100 * index
                          }%`,
                          right: "0",
                          left: "0",
                          width: "100%",
                          height: `24px`,
                          borderBottom: "1px dashed",
                          transform: "translateY(-2px)",
                          backgroundColor: "transparent",
                        }}
                       
                      ></span>
                    ))}
                  </div>
              </div>
              {/* <div className="sign flex justify-content-end py-10">
                <div className="flex-column text-center">
                  <p className="fs-4 fw-bold">Hà Nội, ngày....tháng....năm 2024</p>
                  <p className="fs-4 fw-bold">Ký tên</p>
                </div>
              </div> */}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          {!isView && (
            <Button
                variant="primary"
                className="min-w-75px fw-bold"
                type="submit"
              >
                {"Lưu"}
            </Button>
          )}
          <Button
              variant="secondary"
              className="min-w-75px fw-bold"
              onClick={onHide}
            >
              Đóng
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AcceptanceForm;
