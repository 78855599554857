import { columnNamesType, OctTable } from "@oceantech/oceantech-ui";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { Col, Form as FormBS, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AppContext } from "../../appContext/AppContext";
import { TextFieldCustom } from "../../category-personnel/components/text-field-custom/TextFieldCustom";
import { ACCEPT_FILE_TYPE, CODE, GENDER, MEMBER_TYPE, ORG_TYPE } from "../../utils/Constant";
import {
  initTopicOutline,
  TExecutiveOrganization,
  TImplementOfficer,
  TMainCoordinatingOrganization,
  TMainResearcher,
  TMyTopic,
  TTopicOutline,
  TTopicSecretary
} from "../models/MyTopicModel";
import { addTopicOutline, getTopicById } from "../services/services";
import AddOfficerDialog from "./AddOfficerDialog";
import AddOrganizationDialog from "./AddOrganizationDialog";
import CustomIconButton from "../../component/custom-icon-button/CustomIconButton";
import { TMember } from "../../dang-ky-de-tai/models/TopicRegistrationModel";
import { objectToFormData } from "../../utils/AppFunction";
import TopicInfo from "./outline/1.TopicInfo";
import TopicTarget from "./outline/2.TopicTarget";
import ImplementationProgress from "./outline/3.ImplementationProgress";
import TopicProduct from "./outline/4.TopicProduct";
import PlanApply from "./outline/5.PlanApply";
import ImpactAndBenefits from "./outline/6.ImpactAndBenefits";
import EthicalIssues from "./outline/7.EthicalIssues";
import EquipmentPlan from "./outline/8.EquipmentPlan";
import FundingRequirements from "./outline/9.FundingRequirements";

const TopicOutline: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsLoading } = useContext(AppContext);
  const state = location.state as { show?: boolean; data?: TMyTopic };

  const [openAddOfficer, setOpenAddOfficer] = useState(false);
  const [officerList, setOfficerList] = useState<TImplementOfficer[]>([]);
  const [openAddOrganization, setOpenAddOrganization] = useState(false);
  const [organizationList, setOrganizationList] = useState<TMainCoordinatingOrganization[]>([]);
  const [topicInfo, setTopicInfo] = useState<any>({});
  const [dataOrg, setDataOrg] = useState<TMainCoordinatingOrganization>({});
  const [dataOfficer, setDataOfficer] = useState<TImplementOfficer>({});
  const [mainResearcher, setMainResearcher] = useState<TMainResearcher>({});
  const [topicSecretary, setTopicSecretary] = useState<TTopicSecretary>({});
  const [executiveOrganization, setExecutiveOrganization] = useState<TExecutiveOrganization>({});
  const [file, setFile] = useState<any>();

  let validationSchema = Yup.object({});

  useEffect(() => {
    let topicId = state?.data?.id;
    if(!topicId) return;
    getTopicDetails(topicId);
  }, [state.data?.id]);

  const getTopicDetails = async (topicId: string) => {
    try {
      setIsLoading(true);
      let { data } = await getTopicById(topicId);
      if (data?.code === CODE.SUCCESS) {
        setTopicInfo(data?.data);
        setMainResearcher(
          data?.data?.members?.find(
            (member: TMember) => member?.typeId === MEMBER_TYPE.CHU_NHIEM_DE_TAI
          ) as TMainResearcher
        );
        setTopicSecretary(
          data?.data?.members?.find(
            (member: TMember) => member?.typeId === MEMBER_TYPE.THU_KY
          ) as TTopicSecretary
        );
        setExecutiveOrganization(
          data?.data?.offices?.find(
            (organization: TExecutiveOrganization) => organization?.typeId === ORG_TYPE.TO_CHUC_CHU_TRI
          ) as TExecutiveOrganization
        );
        setOfficerList(
          data?.data?.members?.filter(
            (member: TMember) => member?.typeId === MEMBER_TYPE.CAN_BO_THUC_HIEN
          ) as TMember[]
        );
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  const onAddOfficer = (officer: TImplementOfficer) => {
    let index = -1;
    if(officer.id){
      index = officerList.findIndex(
        (item) => item.id === officer.id
      );
    }
    if(officer.fakeId){
      index = officerList.findIndex(
        (item) => item.fakeId === officer.fakeId
      );
    }
    if (index !== -1) {
      setOfficerList([
        ...officerList.slice(0, index),
        officer,
        ...officerList.slice(index + 1),
      ]);
    } else {
      setOfficerList([...officerList, officer]);
    }
  }

  const onAddOrganization = (organization: TMainCoordinatingOrganization) => {
    let index = organizationList.findIndex(
      (item) => item.fakeId === organization.fakeId
    );
    if (index !== -1) {
      setOrganizationList([
        ...organizationList.slice(0, index),
        organization,
        ...organizationList.slice(index + 1),
      ]);
    } else {
      setOrganizationList([...organizationList, organization]);
    }
  }

  const onHide = () => {
    navigate("/de-tai-cua-toi", { replace: true })
  };

  const handleEditOgnization = (organization: TMainCoordinatingOrganization) => {
    setDataOrg(organization);
    setOpenAddOrganization(true);
  }

  const handleDeleteOgnization = (organization: TMainCoordinatingOrganization) => {
    let index = organizationList.findIndex(
      (item) => item.fakeId === organization.fakeId
    );
    if (index !== -1) {
      setOrganizationList([
        ...organizationList.slice(0, index),
        ...organizationList.slice(index + 1),
      ]);
    }
  }

  const handleEditOfficer = (officer: TImplementOfficer) => {
    setDataOfficer(officer);
    setOpenAddOfficer(true);
  }

  const handleDeleteOfficer = (officer: TImplementOfficer) => {
    let index = officerList.findIndex(
      (item) => item.fakeId === officer.fakeId
    );
    if (index !== -1) {
      setOfficerList([
        ...officerList.slice(0, index),
        ...officerList.slice(index + 1),
      ]);
    }
  }

  const handleFormSubmit = async (values: TTopicOutline) => {
    const newOfficerList = officerList.map((officer, index) => {
      return {
        ...officer,
        scientificTitle: officer.scientificTitle,
        officeCurrently: officer.officeCurrently,
        typeId: officer.typeId,
        academicRank: officer.academicRank,
        degree: officer.degree,
        name: officer.name
      }
    })
    let dataTopicOutline = {
      name: values.name || "",
      code: values.code || "",
      numMonths: (values.startDate && values.endDate) ? (moment(values.endDate).month() - moment(values.startDate).month()) + 1 : 0,
      startDate: values.startDate ? moment(values.startDate).startOf("month").format("YYYY-MM-DD") : "",
      endDate: values.endDate ? moment(values.endDate).endOf("month").format("YYYY-MM-DD") : "",
      manageLevel: values.manageLevel || "",
      budgetTotal: (values.budgetFromGovernment || 0) + (values.budgetFromOther || 0),
      budgetFromGovernment: values.budgetFromGovernment || 0,
      budgetFromOther: values.budgetFromOther || 0,
      isPaymentEntire: values.isPaymentEntire || false,
      isPaymentPart: values.isPaymentPart || false,
      paymentPieceWork: values.paymentPieceWork || 0,
      paymentNotPieceWork: values.paymentNotPieceWork || 0,
      isPartProgram: values.isPartProgram || false,
      programName: values.programName || "",
      programCode: values.programCode || "",
      isScience: values.isScience || false,
      isSingle: values.isSingle || false,
      isOther: values.isOther || false,
      members: [{...mainResearcher, typeId: MEMBER_TYPE.CHU_NHIEM_DE_TAI}, {...topicSecretary, typeId: MEMBER_TYPE.THU_KY}, ...newOfficerList],
      offices: [{...values.executiveOrganization, typeId: ORG_TYPE.TO_CHUC_CHU_TRI}, ...organizationList],
    }
    let formData = objectToFormData(dataTopicOutline);
    if(file){
      formData.append("file", file);
    }
    try {
      setIsLoading(true);
      let { data } = await addTopicOutline(formData, String(state?.data?.id));
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        toast.success("Thêm đề cương thành công");
        onHide();
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {

    }
    setIsLoading(false);
  };

  const handleChangeFile = async (e: any) => {
    let file = e?.target?.files?.[0];
    if (
      ![
        ACCEPT_FILE_TYPE.DOC,
        ACCEPT_FILE_TYPE.DOCX
      ].includes(file?.type)
    ) {
      toast.warning(
        "Chi được phép tải lên tệp tin nên định dạng .doc hoặc .docx"
      );
      e.target.value = "";
    } else {
      setFile(file);
    }
  };

  const officerColumns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: "50px"
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row, index, stt) => {
        return stt;
      },
    },
    {
      name: "Họ và tên",
      field: "name",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "center",
      }
    },
    {
      name: "Học hàm",
      field: "academicRank",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "center",
      }
    },
    {
      name: "Học vị",
      field: "degree",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "center",
      }
    },
    {
      name: "Chức danh thực hiện đề tài",
      field: "scientificTitle",
      headerStyle: {
        minWidth: "200px",
      },
      cellStyle: {
        textAlign: "center",
      }
    },
    {
      name: "Tổ chức công tác",
      field: "officeCurrently",
      headerStyle: {
        minWidth: "250px",
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    // {
    //   name: "Thao tác",
    //   field: "actions",
    //   headerStyle: {
    //     width: "150px",
    //   },
    //   cellStyle: {
    //     textAlign: "center"
    //   },
    //   render(data, index, numericalOrder, itemList) {
    //     return (
    //       <div className="flex spaces justify-content-center">
    //         <CustomIconButton variant="edit" onClick={() => handleEditOfficer(data)}>
    //           <i className="bi bi-pencil-square text-navy"></i>
    //         </CustomIconButton>

    //         <CustomIconButton variant="delete" onClick={() => handleDeleteOfficer(data)}>
    //           <i className="bi bi-trash3-fill text-danger"></i>
    //         </CustomIconButton>
    //       </div>
    //     );
    //   },
    // },
  ]

  const organizationColumns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: "50px",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row, index, stt) => {
        return stt;
      },
    },
    {
      name: "Tên tổ chức chủ trì đề tài",
      field: "topicSupervisor",
      headerStyle: {
        minWidth: "150px",
      }
    },
    {
      name: "Điện thoại",
      field: "phone",
      headerStyle: {
        minWidth: "75px",
      },
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      name: "Fax",
      field: "fax",
      headerStyle: {
        minWidth: "75px",
        maxWidth: "75px"
      },
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      name: "Địa chỉ",
      field: "address",
      headerStyle: {
        minWidth: "250px",
        maxWidth: "250px"
      },
      cellStyle: {
        textAlign: "left"
      }
    },
    {
      name: "Họ và tên thủ trưởng tổ chức",
      field: "chiefName",
      headerStyle: {
        minWidth: "150px",
      }
    },
    {
      name: "Số tài khoản",
      field: "bankNumber",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      name: "Ngân hàng",
      field: "bankName",
      headerStyle: {
        minWidth: "150px",
      },
      cellStyle: {
        textAlign: "center"
      }
    },
    {
      name: "Thao tác",
      field: "actions",
      headerStyle: {
        width: "150px",
      },
      cellStyle: {
        textAlign: "center"
      },
      render(data, index, numericalOrder, itemList) {
        return (
          <div className="flex spaces justify-content-center">
            <CustomIconButton variant="edit" onClick={() => handleEditOgnization(data)}>
              <i className="bi bi-pencil-square text-navy"></i>
            </CustomIconButton>

            <CustomIconButton variant="delete" onClick={() => handleDeleteOgnization(data)}>
              <i className="bi bi-trash3-fill text-danger"></i>
            </CustomIconButton>
          </div>
        );
      },
    },
  ]

  return (
    <Modal
      centered
      show={state?.show}
      onHide={() => navigate("/de-tai-cua-toi", { replace: true })}
      size="xl"
      fullscreen
      className="page-full spaces h-calc-vh-50"
    >
      <Formik<TTopicOutline>
        enableReinitialize
        initialValues={{ ...initTopicOutline, executiveOrganization, mainResearcher, topicSecretary }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Modal.Header className="header-modal min-h-40px">
                <div className="d-flex gap-6 py-2">
                  <div
                    className="d-flex align-items-center title-back gap-3 cursor-pointer"
                    onClick={() => {
                      navigate("/de-tai-cua-toi", { replace: true });
                    }}
                  >
                    <i className="spaces bi bi-chevron-left ml-10"> </i>
                    <div className="spaces mr-20">Quay lại</div>
                  </div>
                  <h4 className="text-title border-bottom text-pri fw-bold fs-4 spaces mb-0 px-10">
                    Xây dựng thuyết minh đề cương
                  </h4>
                </div>
              </Modal.Header>
              <Modal.Body className="spaces p-0 mt-5 bg-white">
                <div className="form spaces p-10">
                  <TopicInfo
                    errors={errors}
                    handleChange={handleChange}
                    handleChangeFile={handleChangeFile}
                    handleDeleteOgnization={handleDeleteOgnization}
                    handleEditOgnization={handleEditOgnization}
                    mainResearcher={mainResearcher}
                    officerList={officerList}
                    organizationList={organizationList}
                    setDataOrg={setDataOrg}
                    setFieldValue={setFieldValue}
                    setOpenAddOrganization={setOpenAddOrganization}
                    topicSecretary={topicSecretary}
                    values={values}
                  />
                  {/* <TopicTarget
                    errors={errors}
                    handleChange={handleChange}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  <ImplementationProgress
                    errors={errors}
                    handleChange={handleChange}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  <TopicProduct
                    errors={errors}
                    handleChange={handleChange}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  <PlanApply
                    errors={errors}
                    handleChange={handleChange}
                    values={values}
                  />
                  <ImpactAndBenefits
                    errors={errors}
                    handleChange={handleChange}
                    values={values}
                  />
                  <EthicalIssues
                    errors={errors}
                    handleChange={handleChange}
                    values={values}
                  />
                  <EquipmentPlan
                    errors={errors}
                    handleChange={handleChange}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  <FundingRequirements
                    errors={errors}
                    handleChange={handleChange}
                    values={values}
                  /> */}
                </div>
              </Modal.Body>
              <Modal.Footer className="flex justify-content-end">
                <button
                  type="submit"
                  className="spaces min-w-90px btn btn-primary btn btn-primary"
                >
                  Lưu
                </button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
      {openAddOfficer && (
        <AddOfficerDialog
          show={openAddOfficer}
          onHide={() => setOpenAddOfficer(false)}
          data={dataOfficer}
          onAddOfficer={(officer: TImplementOfficer) => {
            onAddOfficer(officer);
          }}
        />
      )}
      {openAddOrganization && (
        <AddOrganizationDialog
          show={openAddOrganization}
          onHide={() => setOpenAddOrganization(false)}
          topicId={state.data?.id}
          data={dataOrg}
          onAddOrganization={(organization: TMainCoordinatingOrganization) => {
            onAddOrganization(organization);
          }}
        />
      )}
    </Modal>
  );
};
export default TopicOutline;
