import moment from "moment";
import { TExecutiveOrganization } from "../../../de-tai-cua-toi/models/MyTopicModel";

const currentYear = moment().year();
// Đề tài
export type TTopic = {
  createdDate?: string | null;
  createdBy?: string | null;
  modifiedDate?: string | null;
  modifiedBy?: string | null;
  id?: string | null;
  orgId?: string | null;
  regisPeriodId?: string | null;
  name?: string | null;
  code?: string | null;
  numMembers?: number | null;
  numTeachers?: number | null;
  urgency?: string | null;
  objective?: string | null;
  content?: string | null;
  estimatedTimeframe?: string | null;
  estimatedBudget?: string | null;
  expectedOutcome?: string | null;
  feasibility?: string | null;
  reference?: string | null;
  otherInformation?: string | null;
  statusId?: string | null;
  statusName?: string | null;
  stageId?: string | null;
  stageName?: string | null;
  ownerId?: string | null;
  numMonths?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  manageLevel?: string | null;
  budgetTotal?: string | null;
  budgetFromGovernment?: string | null;
  budgetFromOther?: string | null;
  paymentEntire?: string | null;
  isPaymentEntire?: boolean | null;
  isPaymentPart?: boolean | null;
  paymentPieceWork?: string | null;
  paymentNotPieceWork?: string | null;
  isPartProgram?: boolean | null;
  programName?: string | null;
  programCode?: string | null;
  isScience?: boolean | null;
  isSingle?: boolean | null;
  isOther?: boolean | null;
  members?: any[];
  offices?: string | null
  scienceCommitteeId?: string;
  acceptanceCommitteeId?: string;
}

export const initTopic: TTopic = {
  code: "",
  name: ""
}

// Tìm kiếm
export type TTopicFilter = {
  year?: string;
  yearOption?: any;
  regisPeriodId?: string;
  regisPeriod?: any;
  statusId?: string;
  keyword?: string;
  statusOption?: any;
  pageIndex?: number;
  pageSize?: number;
}

export const initTopicFilter: TTopicFilter = {
  year: currentYear.toString(),
  regisPeriodId: "",
  statusId: "",
  keyword: "",
  pageIndex: 1,
  pageSize: 10
}

// Chủ nhiệm đề tài/Nghiên cứu viên chính
export type TMainResearcher = {
  fakeId?: string;
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
  id?: string;
  orgId?: string;
  topicId?: string;
  name?: string;
  phone?: string;
  email?: string;
  department?: string;
  departmentOption?: any;
  position?: string;
  userId?: string;
  typeId?: number;
  typeName?: string;
  isKeyMember?: boolean;
  dob?: string;
  gender?: number;
  eduLevel?: string;
  scientificTitle?: string;
  office?: string;
  officeCurrently?: string;
  officeAddress?: string;
  fax?: string;
}

// Thư ký đề tài
export type TTopicSecretary = TMainResearcher

// Thành viên tham gia
export type TMember = TMainResearcher

export type TOffice = {
  address?: string | null;
  bankName?: string | null;
  bankNumber?: string | null;
  chiefName?: string | null;
  createdBy?: string | null;
  createdDate?: string | null;
  fax?: string | null;
  id?: string | null;
  fakeId?: string | null;
  modifiedBy?: string | null;
  modifiedDate?: string | null;
  name?: string | null;
  orgId?: string | null;
  phone?: string | null;
  topicId?: string | null;
  topicSupervisor?: string | null;
  typeId?: number | null;
  typeName?: string | null;
  website?: string | null;
}

export type TTopicDetails = {
  date?: string;
  alreadyExamined?: boolean;
  alreadyInspected?: boolean;
  budgetFromGovernment?: number;
  budgetFromOther?: number;
  budgetTotal?: number;
  code?: string;
  content?: string;
  createdBy?: string;
  createdDate?: string;
  endDate?: string;
  estimatedBudget?: number;
  estimatedTimeframe?: string;
  expectedOutcome?: string;
  feasibility?: string;
  id?: string;
  isOther?: boolean;
  isPartProgram?: boolean;
  isPaymentEntire?: boolean;
  isPaymentPart?: boolean;
  isScience?: boolean;
  isSingle?: boolean;
  manageLevel?: string;
  members?: TMember[];
  modifiedBy?: string;
  modifiedDate?: string;
  name?: string;
  numMembers?: number;
  numMonths?: number;
  numTeachers?: number;
  objective?: string;
  offices?: TOffice[];
  orgId?: string;
  otherInformation?: string;
  ownerId?: string;
  paymentEntire?: number;
  paymentNotPieceWork?: number;
  paymentPieceWork?: number;
  programCode?: string;
  programName?: string;
  reference?: string;
  regisPeriod?: string;
  regisPeriodId?: string;
  stageId?: number;
  stageName?: string;
  startDate?: string;
  statusId?: number;
  statusName?: string;
  urgency?: string;
  newDeadline?: string;
  mainResearcher?: TMainResearcher; // Chủ nhiệm đề tài
  coMainResearcher?: TMainResearcher; // Đồng chủ nhiệm đề tài
  topicSecretary?: TTopicSecretary; // Thư ký đề tài
  participatingMember1?: TMember; // Thành viên tham gia
  participatingMember2?: TMember; // Thành viên tham gia
  executiveOrganization?: TExecutiveOrganization;
  hasSuggestion?: boolean; // Có góp ý
  hasModificationRequest?: boolean; // Có yêu cầu chỉnh sửa
  participatingMembers?: TMember[];
  hasTopicSecretary?: boolean;
  hasCoMainResearcher?: boolean;
  hasAcceptanceRecord?: boolean;
  hasScienceRecord?: boolean;
  scienceCommitteeId?: string;
  acceptanceCommitteeId?: string;
}

export type TFileProduct = {
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
  id?: string;
  orgId?: string;
  topicId?: string;
  fileId?: string;
  originalFileName?: string;
  content?: string;
  date?: string
}
export type TDataScienceMinutes = {
  id?: string;
  code?: string;
  topicName?: string;
  dayDate?: number;
  monthDate?: number;
  yearDate?: number;
  date?: string;
  place?: string;
  supervisor?: string;
  numAttendees?: number;
  numAbsent?: number;
  absentMembers?: string;
  chairman?: string;
  presenter?: string;
  presenterDuty?: string;
  committeeFeedback?: string;
  committeeRecommendation?: string;
  committeeConclusionId?:number;
  topicPiName?: string;
  implementingAgency?: string;
  guest?: string;
  scoreScientificNature?: string;
  scoreCreativity?: string;
  scoreEffectiveness?: string;
  scoreApplicability?: string;
  scoreTotal?: string;
  scoreInitialAverage?: string;
  scoreInitialTotal?: string;
  scoreInitialValid?: string;
  scoreInitialInvalid?: string;
  scoreValidTotal?: string;
  scoreFinalAverage?: string;
  committeeConclusion?: string;
};