import { createContext, Dispatch, FC, SetStateAction, useState } from "react";
import { initTopicFilter, TTopic, TTopicFilter } from "./models/TopicModel";
import Topic from "./Topic";

export interface TopicContextProps {
  filter: TTopicFilter;
  setFilter: Dispatch<SetStateAction<TTopicFilter>>;
  topicList: TTopic[];
  setTopicList: Dispatch<SetStateAction<TTopic[]>>;
}

const initialContext = {
  filter: initTopicFilter,
  setFilter: () => {},
  topicList: [],
  setTopicList: () => {}
};

export const TopicContext =
  createContext<TopicContextProps>(initialContext);

export const TopicContextProvider: FC = () => {
    
  const [filter, setFilter] = useState<TTopicFilter>(initTopicFilter);
  const [topicList, setTopicList] = useState<TTopic[]>([]);

  return (
    <TopicContext.Provider
      value={{
        filter,
        setFilter,
        topicList,
        setTopicList
      }}
    >
      <div className="bg-white h-100">
        <div className="flex-1 flex flex-column">
          <div className="py-1">
            <Topic />
          </div>
        </div>
      </div>
    </TopicContext.Provider>
  );
};

export default TopicContextProvider;
