import { Form, Formik } from "formik";
import moment from "moment";
import { useContext } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AppContext } from "../../../appContext/AppContext";
import { TextFieldCustom } from "../../../category-personnel/components/text-field-custom/TextFieldCustom";
import { CODE } from "../../../utils/Constant";
import { initBatchOfRegistration, TBatchOfRegistration } from "../models/OpenBatchOfRegistrationModel";
import { addBatchOfRegistration, updateBatchOfRegistration } from "../services/services";

interface Props {
  show: boolean;
  onHide: () => void;
  dataEdit: TBatchOfRegistration;
  onRefresh: () => Promise<void>;
}

const AddBatchOfRegistrationDialog = (props: Props) => {
  const { show, onHide, onRefresh, dataEdit } = props;
  const { setIsLoading } = useContext(AppContext);
  const currentYear = moment().year();

  const validationSchema = Yup.object({
    name: Yup.string().required("Vui lòng nhập thông tin!"),

    startDate: Yup.string()
    .required("Ngày tạo đợt không được để trống!")
  });

  const handleSubmit = async (values: TBatchOfRegistration) => {
    try {
      setIsLoading(true);
      let { data } = await (values?.id ? updateBatchOfRegistration(values) : addBatchOfRegistration(values));
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        toast.success(`${values?.id ? "Cập nhật" : "Thêm mới"} đợt đăng ký thành công`);
        onHide();
        onRefresh();
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error: any) {
      toast.warning(error?.response?.data?.message);
    } finally {

    }
    setIsLoading(false);
  };

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="lg"
      className="spaces h-calc-vh-50"
    >
      <Formik
        enableReinitialize
        initialValues={dataEdit}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header className="header-modal min-h-40px" closeButton>
              <Modal.Title className="py-4">{dataEdit?.id ? "Cập nhật" : "Thêm mới"} đợt đăng ký</Modal.Title>
            </Modal.Header>
            <Modal.Body className="spaces p-0 bg-white">
              <div className="spaces p-10">
                <Row className="py-2">
                  <Col xs={12}>
                    <div className="flex align-items-center">
                      <div className="label min-w-75px fw-bold text-align-right pe-4">
                        Đợt
                      </div>
                      <div className="flex-1">
                        <TextFieldCustom
                          type="date"
                          title=""
                          name="startDate"
                          disabled={!!dataEdit?.id}
                          values={values.startDate}
                          errors={errors.startDate}
                          handleChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col xs={12}>
                    <div className="flex align-items-center">
                      <div className="label min-w-75px fw-bold text-align-right pe-4">
                        Tên đợt
                      </div>
                      <div className="flex-1">
                        <TextFieldCustom
                          className="min-h-90px"
                          type="text"
                          as={"textarea"}
                          title=""
                          name="name"
                          values={values.name}
                          errors={errors.name}
                          handleChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer className="flex justify-content-center">
              <Button variant="primary" className="min-w-75px fw-bold" type="submit">{dataEdit?.id ? "Cập nhật" : "Mở đợt" }</Button>
              <Button variant="secondary" className="min-w-75px fw-bold" onClick={onHide}>Hủy</Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddBatchOfRegistrationDialog;