import { FC, useContext, useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import { TextFieldCustom } from "../../../category-personnel/components/text-field-custom/TextFieldCustom";
import { TMember, TTopic, TTopicDetails } from "../models/TopicModel";
import { AppContext } from "../../../appContext/AppContext";
import { addRequestEditAcceptance, getTopicById } from "../services/services";
import { ACCEPT_FILE_TYPE, CODE } from "../../../utils/Constant";
import { toast } from "react-toastify";
import moment from "moment";

const TopicRequestEditAcceptanceAccceptance: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsLoading } = useContext(AppContext);
  const state = location.state  as { show?: boolean, data?: TTopic };

  const [requestEditAcceptanceInfo, setRequestEditAcceptanceInfo] = useState({
    reportModDueDate: "",
    content: "",
    file: undefined,
    topicId: state?.data?.id,
  });

  const handleChange = (e: any) => {
    setRequestEditAcceptanceInfo({
      ...requestEditAcceptanceInfo,
      [e.target.name]: e.target.value
    })
  };

  const [topicInfo, setTopicInfo] = useState<TTopicDetails>({});
  const [file, setFile] = useState<File>();

  useEffect(() => {
    let topicId = state?.data?.id;
    if(!topicId) return;
    getTopicDetails(topicId);
  }, [state.data?.id]);

  const getTopicDetails = async (topicId: string) => {
    try {
      setIsLoading(true);
      let { data } = await getTopicById(topicId);
      if (data?.code === CODE.SUCCESS) {
        setTopicInfo(data?.data);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  const handleChangeFile = async (e: any) => {
    let file = e.target.files[0];
    if(!file) return;
    if(![ACCEPT_FILE_TYPE.DOC, ACCEPT_FILE_TYPE.DOCX].includes(file.type)) {
      toast.warning("Chi được phép tải lên tệp tin nên định dạng .doc hoặc .docx");
      e.target.value = '';
    }else{
      setFile(file);
    }
  };

  const handleSubmit = async () => {
    if(!requestEditAcceptanceInfo.content) {
      toast.warning("Chưa nhập nội dung góp ý");
      return;
    }
    let topicId = requestEditAcceptanceInfo.topicId;
    if(!topicId) return;
    let formData = new FormData();
    if(file){
      formData.append('file', file);
    }
    formData.append('reportModDueDate', requestEditAcceptanceInfo.reportModDueDate);
    formData.append('content', requestEditAcceptanceInfo.content);
    formData.append('topicId', topicId);

    try {
      setIsLoading(true);
      const { data } = await addRequestEditAcceptance(formData);
      if(data?.code === CODE.SUCCESS) {
        toast.success("Gửi yêu cầu chỉnh sửa nghiệm thu thành công");
        navigate("/quan-ly-de-tai/de-tai", { replace: true });
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      setIsLoading(false);
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      centered
      show={state?.show}
      onHide={() => navigate('/quan-ly-de-tai/de-tai', { replace: true })}
      size="xl"
      fullscreen
      className="page-full spaces h-calc-vh-50"
    >
      <Modal.Header className="header-modal min-h-40px">
        <div className="d-flex gap-6 py-2">
          <div
            className="d-flex align-items-center title-back gap-3 cursor-pointer"
            onClick={() => {
              navigate('/quan-ly-de-tai/de-tai', { replace: true });
            }}
          >
            <i className="spaces bi bi-chevron-left ml-10"> </i>
            <div className="spaces mr-20">Quay lại</div>
          </div>
          <h4 className="text-title border-bottom text-pri fw-bold fs-4 spaces mb-0">
            Yêu cầu chỉnh sửa nghiệm thu
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body className="spaces p-0 mt-3 bg-white">
        <Row className="spaces px-10 border-bottom">
          <Col xs={4}>
            <div className="flex py-2">
              <div className="label fw-bold min-w-150px">Tên đề tài:</div>
              <div className="flex-1">{topicInfo?.name || ""}</div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="flex py-2">
              <div className="label fw-bold min-w-150px">Mã đề tài:</div>
              <div className="flex-1">{topicInfo?.code || ""}</div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="flex py-2">
              <div className="label fw-bold min-w-150px">Ngày đăng ký:</div>
              <div className="flex-1">{topicInfo.date && moment(topicInfo.date).format('DD/MM/YYYY')}</div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="flex py-2">
              <div className="label fw-bold min-w-150px">
                Thời gian thực hiện:
              </div>
              <div className="flex-1">{topicInfo.startDate && moment(topicInfo.startDate).format('DD/MM/YYYY')} {topicInfo.startDate && topicInfo.endDate && ' - '} {topicInfo.endDate && moment(topicInfo.endDate).format('DD/MM/YYYY')}</div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="flex py-2">
              <div className="label fw-bold min-w-150px">Đợt:</div>
              <div className="flex-1">{topicInfo.regisPeriod || ""}</div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="flex py-2">
              <div className="label fw-bold min-w-150px">Gia hạn:</div>
              <div className="flex-1">{topicInfo?.newDeadline || "Không" }</div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="flex py-2">
              <div className="label fw-bold min-w-150px">Chủ nhiệm:</div>
              <div className="flex-1">{topicInfo?.members?.find((member: TMember) => member.typeId === 1)?.name || ""}</div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="flex py-2">
              <div className="label fw-bold min-w-150px">Email chủ nhiệm:</div>
              <div className="flex-1">{topicInfo?.members?.find((member: TMember) => member.typeId === 1)?.email || ""}</div>
            </div>
          </Col>
          <Col xs={4}>
            <div className="flex py-2">
              <div className="label fw-bold min-w-150px">Trạng thái:</div>
              <div className="flex-1">{topicInfo.statusName || ""}</div>
            </div>
          </Col>
        </Row>
        <Row className="spaces p-10">
          <Col xs={6}>
            <div className="flex py-2 align-items-center">
              <div className="label fw-bold min-w-150px">Hạn chỉnh sửa báo cáo:</div>
              <div className="flex-1">
                <TextFieldCustom
                  type="date"
                  title=""
                  name="reportModDueDate"
                  values={requestEditAcceptanceInfo.reportModDueDate}
                  handleChange={handleChange}
                  errors=""
                />
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className="flex py-2 align-items-center">
              <div className="label fw-bold min-w-150px">Yêu cầu chỉnh sửa:</div>
              <div className="flex-1">
                <TextFieldCustom
                  type="text"
                  as="textarea"
                  className="min-h-90px"
                  title=""
                  name="content"
                  values={requestEditAcceptanceInfo.content}
                  handleChange={handleChange}
                  errors=""
                />
              </div>
            </div>
          </Col>
          <Col xs={6} className="spaces pt-10">
            <div className="flex py-2 align-items-center">
              <div className="label fw-bold min-w-150px">File đính kèm:</div>
              <div className="flex-1">
                <Form.Group controlId="formFile" className="flex-1">
                  <Form.Control
                    type="file"
                    name="file"
                    size="sm"
                    accept={".doc, .docx"}
                    onChange={(e) => handleChangeFile(e)}
                  />
                </Form.Group>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="flex justify-content-end">
        <button
          type="submit"
          className="spaces min-w-90px btn btn-primary btn btn-primary"
          onClick={handleSubmit}
        >
          Gửi
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default TopicRequestEditAcceptanceAccceptance;
