import { Field, FieldArray, Form, Formik } from "formik";
import { useContext } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Select from 'react-select';
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AppContext } from "../../appContext/AppContext";
import { TextFieldCustom } from "../../category-personnel/components/text-field-custom/TextFieldCustom";
import { TTopicDetails } from "../../quan-ly-de-tai/de-tai/models/TopicModel";
import { CODE, MEMBER_TYPE } from "../../utils/Constant";
import { memberOptions, STATUS_ID, teacherOptions } from "../constants/constants";
import { initTopicRegistration, TBatchOfRegistration, TMember, TTopicRegistration } from "../models/TopicRegistrationModel";
import { registerTopic, registerTopicAgain } from "../services/services";
import moment from "moment";

interface Props {
  show: boolean;
  onHide: () => void;
  batchOfRegistrationInfo: TBatchOfRegistration;
  onRefresh: () => Promise<void>;
  topicInfo?: TTopicDetails;
}

const AddTopicRegistrationDialog = (props: Props) => {
  const { show, onHide, batchOfRegistrationInfo, onRefresh, topicInfo } = props;
  const { setIsLoading } = useContext(AppContext);

  const validationSchema = Yup.object({
  });

  const handleSubmit = async (values: TTopicRegistration) => {
    let isEdit = batchOfRegistrationInfo?.statusId === STATUS_ID.OPEN && batchOfRegistrationInfo?.topicIdOfCurrentUser && !batchOfRegistrationInfo?.proposalCode;
    let members = [...values.memberList, ...values.teacherList].map((item) => {
      return {
        ...item,
        name: item.name,
        position: item.position,
        typeId: item.typeId,
        isKeyMember: item?.isKeyMember ? true : false
      }
    });
    let dataRegister = {
      regisPeriodId: batchOfRegistrationInfo?.id,
      members,
      numMembers: values.memberList.length,
      numTeachers: values.teacherList.length,
      date: values?.date,
    }
    try {
      setIsLoading(true);
      let { data } = await (isEdit ? registerTopicAgain(dataRegister, String(batchOfRegistrationInfo?.topicIdOfCurrentUser)) : registerTopic(dataRegister));
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        toast.success(`${isEdit ? "Đăng ký lại" : "Đăng ký"} thành công`);
        onHide();
        onRefresh();
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {

    }
    setIsLoading(false);
  };

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="lg"
      scrollable
      className="spaces h-calc-vh-50"
    >
      <Formik
        enableReinitialize
        initialValues={
          topicInfo?.id
            ? {
                ...initTopicRegistration,
                numMembers:
                  topicInfo?.numMembers ||
                  topicInfo?.members?.filter(
                    (item: TMember) =>
                      item.typeId === MEMBER_TYPE.CAN_BO_THUC_HIEN
                  ).length ||
                  0,
                memberList:
                  topicInfo?.members?.filter(
                    (item: TMember) =>
                      item.typeId === MEMBER_TYPE.CAN_BO_THUC_HIEN
                  ) || [],
                teacherList:
                  topicInfo?.members?.filter(
                    (item: TMember) => item.typeId === MEMBER_TYPE.CHU_NHIEM_DE_TAI
                  ) || [],
                numTeachers:
                  topicInfo?.numTeachers ||
                  topicInfo?.members?.filter(
                    (item: TMember) => item.typeId === MEMBER_TYPE.CHU_NHIEM_DE_TAI
                  ).length ||
                  0,
                date: topicInfo?.createdDate ? moment(topicInfo?.createdDate).format("YYYY-MM-DD") : "",
              }
            : initTopicRegistration
        }
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          handleBlur,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header className="header-modal min-h-40px" closeButton>
              <Modal.Title className="py-4">Đăng ký đề tài</Modal.Title>
            </Modal.Header>
            <Modal.Body className="spaces p-0 bg-white max-h-80vh">
              <div className="spaces p-10">
                <Row className="py-2">
                  <Col xs={12}>
                    <div className="flex align-items-center">
                      <div className="label min-w-150px fw-bold">Đợt</div>
                      <div className="flex-1">
                        <TextFieldCustom
                          type="date"
                          title=""
                          name="startDate"
                          disabled
                          values={batchOfRegistrationInfo?.startDate}
                          errors=""
                          handleChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} className="pt-4">
                    <div className="flex align-items-center">
                      <div className="label min-w-150px fw-bold">Ngày đăng ký</div>
                      <div className="flex-1">
                        <TextFieldCustom
                          type="date"
                          title=""
                          name="date"
                          values={values?.date}
                          errors=""
                          handleChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className={`member py-2 border`}>
                <Col xs={12}>
                    <div className="flex align-items-center">
                      <div className="label min-w-150px fw-bold">
                        Chủ nhiệm đề tài
                      </div>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="flex align-items-center pb-4">
                      <div className="label min-w-150px fw-bold text-pri spaces pl-16">
                        Họ và tên
                      </div>
                      <div className="flex-1">
                        <Select
                          name={`member${0 + 1}`}
                          value={values?.teacherList?.[0]}
                          onChange={(e) => {
                            setFieldValue(`teacherList.${0}`, e);
                          }}
                          getOptionLabel={(option) => option?.name || ""}
                          getOptionValue={(option) => option?.id || ""}
                          options={teacherOptions}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="flex align-items-center pb-4">
                      <div className="label min-w-150px fw-bold text-pri spaces pl-16">
                        Chức vụ
                      </div>
                      <div className="flex-1">
                        <TextFieldCustom
                          type="text"
                          title=""
                          name={`teacherList.[${0}].position`}
                          values={values?.teacherList?.[0]?.position}
                          errors={""}
                          handleChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="flex align-items-center">
                      <div className="label min-w-150px fw-bold text-pri spaces pl-16">
                        Tham gia chính
                      </div>
                      <div className="flex-1">
                        <Field
                          type="checkbox"
                          name={`teacherList.${0}.isKeyMember`}
                          checked={values?.teacherList?.[0]?.isKeyMember}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col xs={12}>
                    <div className="flex align-items-center">
                      <div className="label min-w-150px fw-bold">
                        Số lượng thành viên
                      </div>
                      <div className="flex-1">
                        <TextFieldCustom
                          type="number"
                          title=""
                          name="numMembers"
                          values={values.numMembers}
                          errors={errors.numMembers}
                          onBlur={(e: React.FocusEvent<any>) => {
                            handleBlur(e);
                            let quantity = Number(e.target.value);
                            const currentLength = values.memberList.length;
                            if (quantity > currentLength) {
                              let newMembers: TMember[] = Array.from(
                                { length: quantity - currentLength },
                                () => ({
                                  id: "",
                                  name: "",
                                  position: "",
                                })
                              );
                              setFieldValue("memberList", [
                                ...values.memberList,
                                ...newMembers,
                              ]);
                            }
                            if (quantity < currentLength) {
                              setFieldValue(
                                "memberList",
                                values.memberList.slice(0, quantity)
                              );
                            }
                          }}
                          handleChange={(e: React.ChangeEvent<any>) => {
                            let quantity = Number(e.target.value);
                            if (quantity > 4) {
                              toast.warning("Không được quá 5 thành viên, tính cả chủ nhiệm đề tài");
                              return;
                            }
                            handleChange(e);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="py-2">
                  <FieldArray
                    name="memberList"
                    render={() => (
                      <>
                        {values?.memberList?.map((item, index) => (
                          <div
                            className={`member py-2 border${
                              index === 0 ? "" : "-bottom"
                            }`}
                          >
                            <Col xs={12}>
                              <div className="flex align-items-center pb-4">
                                <div className="label min-w-150px fw-bold text-pri spaces pl-16">
                                  Thành viên {index + 1}
                                </div>
                                <div className="flex-1">
                                  <Select
                                    name={`member${index + 1}`}
                                    value={values?.memberList?.[index]}
                                    onChange={(e) => {
                                      setFieldValue(`memberList.${index}`, e);
                                    }}
                                    getOptionLabel={(option) =>
                                      option?.name || ""
                                    }
                                    getOptionValue={(option) =>
                                      option?.fakeId || ""
                                    }
                                    options={memberOptions}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col xs={12}>
                              <div className="flex align-items-center pb-4">
                                <div className="label min-w-150px fw-bold text-pri spaces pl-16">
                                  Chức vụ
                                </div>
                                <div className="flex-1">
                                  <TextFieldCustom
                                    type="text"
                                    title=""
                                    name={`memberList.[${index}].position`}
                                    values={
                                      values?.memberList?.[index]?.position
                                    }
                                    errors={""}
                                    handleChange={handleChange}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col xs={12}>
                              <div className="flex align-items-center">
                                <div className="label min-w-150px fw-bold text-pri spaces pl-16">
                                  Tham gia chính
                                </div>
                                <div className="flex-1">
                                  <Field
                                    type="checkbox"
                                    name={`memberList.${index}.isKeyMember`}
                                    checked={
                                      values?.memberList?.[index]?.isKeyMember
                                    }
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </Col>
                          </div>
                        ))}
                      </>
                    )}
                  />
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer className="flex justify-content-center">
              <Button
                variant="primary"
                className="min-w-75px fw-bold"
                type="submit"
              >
                Lưu
              </Button>
              <Button
                variant="secondary"
                className="min-w-75px fw-bold"
                onClick={onHide}
              >
                Hủy
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddTopicRegistrationDialog;