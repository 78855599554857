import { useEffect } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TABS } from "../../../../../app/modules/de-tai-cua-toi/constants/constants";
import { getFileExtension } from "../../../../../app/modules/utils/AppFunction";
import { CODE, NOTIFY_TYPEID } from "../../../../../app/modules/utils/Constant";
import { handleDownloadFile, maskAsReadNoti } from "../../../../../app/modules/utils/ServicesUtils";

type IProps = {
  open: boolean;
  handleCloseDialog: () => void;
  data: any;
  getCountUnread :() => Promise<void>;
  getListNoti: (isReset: boolean) => Promise<void>
};

const NotificationDialog = (props: IProps) => {
  const navigate = useNavigate();

  const { open, handleCloseDialog, data, getCountUnread, getListNoti } = props;

  useEffect(() => {
    if(data?.isRead) return;
    markAsRead(data?.notificationUserId);
  }, [data])
  const markAsRead = async (notificationUserId: string) => {
    try {
      let { data } = await maskAsReadNoti(notificationUserId);
      if (data?.code === CODE.SUCCESS) {
        getCountUnread();
        getListNoti(true);
      } else {
        toast.error("Đã có lỗi xảy ra!");
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleNavigate = () => {
    let notiTypeId = data?.typeId;
    switch (notiTypeId) {
      case NOTIFY_TYPEID.GOP_Y_DE_XUAT:
        if(!data?.topicId){
          toast.warning("Đề tài không tồn tại");
          return;
        }
        navigate(`/de-tai-cua-toi/${data?.topicId}`, {
          state: {
            show: true,
            tabIndex: TABS.PROPOSAL.eventKey,
            data: {id: data?.topicId}
          },
          replace: true,
        });
        break;
      case NOTIFY_TYPEID.YEU_CAU_CHINH_SUA_DE_CUONG:
        if(!data?.topicId){
          toast.warning("Đề tài không tồn tại");
          return;
        }
        navigate(`/de-tai-cua-toi/${data?.topicId}`, {
          state: {
            show: true,
            tabIndex: TABS.OUTLINE.eventKey,
            data: {id: data?.topicId}
          },
          replace: true,
        });
        break;
      default:
        break;
    }
    handleCloseDialog();
  }

  const TooltipCustom = ({ id, children, title, placement }: any) => (
    <OverlayTrigger placement={placement} overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <div className="width-fit-content">{children}</div>
    </OverlayTrigger>
  );

  return (
    <Modal centered show={open}>
      <Modal.Header className="p-4 header-modal modal-header">
        <Modal.Title className="text-cyan text-uppercase">
          Thông báo
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex-column spaces gap-4">
          <div className="title text-pri fw-bolder fs-3 py-2 spaces">
            {data?.typeName?.charAt(0).toUpperCase() + data?.typeName.slice(1)}
          </div>
          <div className="fs-5 py-2 spaces">
            <span className="fw-bold fs-5">Nội dung: </span>
            {data?.content}
          </div>
          {data?.fileId && (
            <>
              <div className="fs-5 py-2 spaces fw-bold">Tệp đính kèm:</div>
              <div className="flex gap-5">
                <div className="flex-column cursor-pointer" onClick={() => handleDownloadFile(data?.fileId, data?.originalFileName)}>
                  <TooltipCustom id="t-2" title="Click để tải xuống" placement="right">
                    <div
                      className="file-icon file-icon-lg"
                      data-type={getFileExtension(data?.originalFileName)}
                    ></div>
                  </TooltipCustom>
                  <div className="file-name fs-7 spaces pt-4">
                    {data?.originalFileName || ""}
                  </div>
                </div>
              </div>
            </>
          )}
          {[NOTIFY_TYPEID.GOP_Y_DE_XUAT, NOTIFY_TYPEID.YEU_CAU_CHINH_SUA_DE_CUONG].includes(data?.typeId) && (
            <div className="text-green cursor-pointer pt-4 fs-5 py-2 spaces fw-bold text-align-center" onClick={handleNavigate}>
              Đi đến chỉnh sửa
              <i className="fas fa-external-link-alt ms-2 text-green"></i>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="pt-2 pb-2 justify-content-center">
        <Button className="btn-danger min-w-60px" onClick={handleCloseDialog}>
          Đóng
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default NotificationDialog;
