import { OctTablePagination } from '@oceantech/oceantech-ui';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Column, HeaderProps } from 'react-table';
import { toast } from 'react-toastify';
import { TableCustomCell } from '../../../../component/table/components/TableCustomCell';
import { TableCustomHeader } from '../../../../component/table/components/TableCustomHeader';
import { TableCustom } from '../../../../component/table/table-custom/TableCustom';
import { uniqueArray } from '../../../../utils/AppFunction';
import { CODE, DEFAULT_PAGE_INDEX, SELECTION_MODE, TRANG_THAI } from '../../../../utils/Constant';
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from '../../../../utils/PageUtils';
import { TAcceptanceCouncilDetails, TAcceptanceCouncilTopic } from '../../models/AcceptanceCouncilManagerModels';
import { searchTopic } from '../../services/services';

type IProps = {
  setSelectedTopics: React.Dispatch<React.SetStateAction<TAcceptanceCouncilTopic[]>>
  regisPeriodId: string;
  isView?: boolean;
  details?: TAcceptanceCouncilDetails;
}
const Step3: FC<IProps> = (props) => {

  const { setSelectedTopics, regisPeriodId, isView, details } = props;

  const [totalElements, setTotalElements] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [filter, setFilter] = useState<any>({});
  const [topicList, setTopicList] = useState<TAcceptanceCouncilTopic[]>([]);

  useEffect(() => {
    if(!filter.pageIndex || !filter.pageSize  || isView) return;
    getTopicList();
  }, [filter])

  useEffect(() => {
    if(!details?.id) return;
    setSelectedTopics(details?.topics || []);
  }, [details?.id])

  useEffect(() => {
    setFilter({...filter, year: new Date().getFullYear(), pageIndex: page, pageSize: rowsPerPage, statusId: TRANG_THAI.CHO_XET_DUYET, regisPeriodId: regisPeriodId || details?.regisPeriodId})
  }, [page, rowsPerPage, regisPeriodId, details])

  const getTopicList = async () => {
    try {
      let { data } = await searchTopic(filter);
      if (data?.code === CODE.SUCCESS) {
        setTopicList([...data?.data, ...details?.topics || []]);
        setTotalElements(data?.data?.pageable?.totalElements);
      } else {
        toast.error("Đã có lỗi xảy ra!");
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSelectData = (rows: TAcceptanceCouncilTopic[]) => {
    const newRows = uniqueArray(rows);
    setSelectedTopics(newRows);
  }

  const scienceColumns: ReadonlyArray<
    Column<any>
  > = [
      {
        Header: (props: PropsWithChildren<HeaderProps<any>>) => (
          <TableCustomHeader<any>
            tableProps={props}
            title={"Mã đề tài"}
            className="text-center text-white align-middle bg-pri min-w-50px"
          />
        ),
        id: "code",
        Cell: ({ ...props }) => (
          <TableCustomCell
            className="text-center s-2"
            data={props.data[props.row.index].code}
          />
        ),
      },
      {
        Header: (props: PropsWithChildren<HeaderProps<any>>) => (
          <TableCustomHeader<any>
            tableProps={props}
            title={"Tên đề tài"}
            className="text-center text-white align-middle bg-pri min-w-250px"
          />
        ),
        id: "name",
        Cell: ({ ...props }) => (
          <TableCustomCell
            className="text-center s-2"
            data={props.data[props.row.index].name}
          />
        ),
      }
    ];

    const selectedColumns: ReadonlyArray<
    Column<TAcceptanceCouncilTopic>
  > = [
      {
        Header: (props: PropsWithChildren<HeaderProps<TAcceptanceCouncilTopic>>) => (
          <TableCustomHeader<TAcceptanceCouncilTopic>
            tableProps={props}
            title={"Mã đề tài"}
            className="text-center text-white align-middle bg-pri min-w-50px"
          />
        ),
        id: "code",
        Cell: ({ ...props }) => (
          <TableCustomCell
            className="text-center s-2"
            data={props.data[props.row.index].code}
          />
        ),
      },
      {
        Header: (props: PropsWithChildren<HeaderProps<TAcceptanceCouncilTopic>>) => (
          <TableCustomHeader<TAcceptanceCouncilTopic>
            tableProps={props}
            title={"Tên đề tài"}
            className="text-center text-white align-middle bg-pri min-w-250px"
          />
        ),
        id: "name",
        Cell: ({ ...props }) => (
          <TableCustomCell
            className="text-center s-2"
            data={props.data[props.row.index].name}
          />
        ),
      }
    ];

  return (
    <div className="w-100">
      {isView ? (
        <Row>
          <Col xs={12}>
            <TableCustom<any>
              className="p-0 table-topic"
              data={details?.topics || []}
              columns={scienceColumns}
              verticalScroll={true}
            />
          </Col>
          <Col xs={12}>
            Tổng số bản ghi: {details?.numTopics || 0}
          </Col>
        </Row>
      ): (
        <Row>
          <Col xs={12}>
            <TableCustom<any>
              className="p-0 table-topic"
              data={topicList}
              columns={scienceColumns}
              selectionMode={SELECTION_MODE.MULTI}
              verticalScroll={true}
              getSelectedRowsData={handleSelectData}
              checkedRows={details?.topics || []}
            />
            <OctTablePagination
              handlePagesChange={handlePagesChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              totalPages={Math.ceil(totalElements / rowsPerPage)}
              totalElements={details?.numTopics || 0}
              rowsForPage={rowsForPage}
              numberOfElements={0}
            />
          </Col>
        </Row>
      )}
    </div>
  );
}

export { Step3 };

