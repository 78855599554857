import { Form, Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import { initFilter, TBatchOfRegistrationFilter } from "../models/OpenBatchOfRegistrationModel";
import { TextFieldCustom } from "../../../category-personnel/components/text-field-custom/TextFieldCustom";
import { generateYears } from "../../../utils/AppFunction";
import { TRANG_THAI } from "../constants/constants";
import "../OpenBatchOfRegistration.scss";
import { useContext } from "react";
import { OpenBatchOfRegistrationContext } from "../OpenBatchOfRegistrationContext";
import AutocompleteObjectV2 from '../../../component/AutocompleteObjectV2';

export default function Filter() {

  const { filter, setFilter } = useContext(OpenBatchOfRegistrationContext);
  const validationSchema = Yup.object({});

  const handleSubmit = async (values: TBatchOfRegistrationFilter) => {
    setFilter({...filter, ...values});
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initFilter}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="batch-of-registration-filter spaces px-10 py-10 bg-white mb-4">
            <Row>
              <Col xs={3}>
                <div className="flex align-items-center">
                  <div className="label pe-4 fw-bold">Năm</div>
                  <div className="flex-1">
                    <AutocompleteObjectV2
                      options={generateYears()}
                      isSearchDefauilt={true}
                      name={"yearOption"}
                      onChange={(e: any) =>  {
                        setFieldValue("year", e?.id || null);
                        setFieldValue("yearOption", e);
                      }}
                      value={values.yearOption || {
                        id: 2024,
                        title: "2024",
                        value: "2024"
                      }}
                      errors={errors.year}
                      searchObject={{}}
                      getOptionLabel={(e: any) => e?.title}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={3}>
                <div className="flex align-items-center">
                  <div className="label pe-4 fw-bold">Trạng thái</div>
                  <div className="flex-1">
                    <AutocompleteObjectV2
                      options={TRANG_THAI}
                      isSearchDefauilt={true}
                      name={"statusOption"}
                      onChange={(e: any) =>  {
                        setFieldValue("status", e?.value || null);
                        setFieldValue("statusOption", e);
                      }}
                      value={values.statusOption || {
                        id: 0,
                        title: "Toàn bộ",
                        value: "all"
                      }}
                      errors={errors.status}
                      searchObject={{}}
                      getOptionLabel={(e: any) => e?.title}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={3}>
                <div className="flex align-items-center">
                  <div className="label pe-4 fw-bold">Từ khóa</div>
                  <div className="flex-1">
                    <TextFieldCustom
                      type="text"
                      title=""
                      name="keyword"
                      placeholder="Nhập tên đợt"
                      values={values?.keyword}
                      errors={""}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={3} className="flex align-items-stretch">
                <button
                  type="submit"
                  onClick={() => {}}
                  className="btn-outline btn-filter"
                >
                  <i className="bi bi-filter text-pri fs-3"></i>
                  Lọc
                </button>
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </Formik>
  );
}
