import moment from "moment";
import { TTopicDetails } from "../../../quan-ly-de-tai/de-tai/models/TopicModel";

const currentYear = moment().year();

export type TScienceCouncil = {
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
  id?: string;
  orgId?: string;
  regisPeriodId?: string;
  name?: string;
  time?: string;
  year?: string;
  date?: string;
  place?: string;
  numMembers?: number;
  numTopics?: number;
  typeId?: number;
  typeName?: string;
  statusId?: number;
  statusName?: string;
  dateTime?: string;
  members?: TScienceCouncilMember[];
  topics?: TScienceCouncilTopic[]
};

export type TScienceCouncilFilter = {
  year?: string;
  yearOption?: any;
  regisPeriodId?: string;
  keyword?: string;
  regisPeriod?: any;
  typeId?: number;
  pageIndex?: number;
  pageSize?: number;
};

export const initFilter: TScienceCouncilFilter = {
  year: currentYear.toString(),
  regisPeriodId: "",
  keyword: "",
  typeId: 1,
  pageIndex: 1,
  pageSize: 10
};

export type TScienceCouncilMember = {
  id?: string;
  name?: string;
  gender?: number;
  genderOption?: any;
  duty?: string;
  academicRank?: string;
  degree?: string;
  department?: string;
  position?: string;
  email?: string;
  phone?: string;
  office?: string;
  username?: string;
  isChairman?: boolean;
  isSecretary?: boolean;
}

export const initScienceCouncilMember: TScienceCouncilMember = {
  name: "",
  gender: undefined,
  duty: "",
  academicRank: "",
  degree: "",
  department: "",
  position: "",
  email: "",
  phone: "",
  office: ""
}

export type TScienceCouncilTopic = {
  id?: string;
  name?: string;
  code?: string;
  statusName?: string;
}

export type TAddScienceCouncil = {
  year?: string;
  yearOption?: any;
  regisPeriodId?: string;
  regisPeriod?: any;
  name?: string;
  code?: string;
  time?: string;
  date?: string;
  place?: string;
  typeId?: number;
  file?: string;
  numMembers?: number;
  numTopics?: number;
  members: TScienceCouncilMember[];
  topics: TScienceCouncilTopic[];
  startDate?: string;
  originalFileName?: string;
}

export const initAddScienceCouncil: TAddScienceCouncil = {
  year: currentYear.toString(),
  regisPeriodId: "",
  name: "",
  code: "",
  time: "",
  date: "",
  place: "",
  typeId: 1,
  file: "",
  numMembers: 5,
  numTopics: 0,
  members: Array.from(
    { length: 5 },
    () => (initScienceCouncilMember)
  ),
  topics: []
}

export type TSummaryInfo = {
  numPendingExamination?: number;
  numUnderExamination?: number;
}

export type TScienceCouncilDetails = {
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
  id?: string;
  orgId?: string;
  regisPeriodId?: string;
  name?: string;
  code?: string;
  time?: string;
  year?: string;
  yearOption?: any;
  date?: string;
  place?: string;
  numMembers?: number;
  numTopics?: number;
  typeId?: number;
  typeName?: string;
  statusId?: number;
  statusName?: string;
  dateTime?: string;
  members?: TCouncilDetailsMember[];
  topics?: TTopicDetails[];
  startDate?: string;
  originalFileName?: string;
  fileId?: string;
}

export type TCouncilDetailsMember = {
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
  id?: string;
  orgId?: string;
  committeeId?: string;
  name?: string;
  phone?: string;
  email?: string;
  department?: string;
  position?: string;
  gender?: number;
  academicRank?: string;
  degree?: string;
  duty?: string;
  office?: string
}