import {
  columnNamesType,
  OctTable,
  OctTablePagination,
} from "@oceantech/oceantech-ui";
import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AppContext } from "../../appContext/AppContext";
import {
  ACCEPT_FILE_TYPE,
  BUTTON_CONFIG,
  checkActiveButton,
  checkDisplayButton,
  CODE,
  DEFAULT_PAGE_INDEX,
  ROLE,
  TRANG_THAI,
} from "../../utils/Constant";
import {
  handlePagesChange,
  handleRowsPerPageChange,
  rowsForPage,
} from "../../utils/PageUtils";
import Filter from "./components/Filter";
import {
  approveAcceptance,
  approveOutline,
  approveProposal,
  approveTopic,
  rejectAcceptance,
  rejectOutline,
  rejectProposal,
  rejectTopic,
  searchTopic,
} from "./services/services";
import "./Topic.scss";
import { TopicContext } from "./TopicContext";
import moment from "moment";
import { ConfirmDialog } from "../../component/ConfirmDialog";
import { TTopic } from "./models/TopicModel";
import { APPROVAL_TYPE, REJECT_TYPE } from "./constants/constants";
import { hasAuthority } from "../../utils/Permission";
import AcceptanceMinutesDialog from "./components/AcceptanceMinutesDialog";
import ScienceMinutesDialog from "./components/ScienceMinutesDialog";
import EvaluationForm from "./components/EvaluationForm";
import AcceptanceForm from "./components/AcceptanceForm";
import ApprovalOutlineDialog from "./components/ApprovalOutlineDialog";

export const Topic: FC = () => {
  let navigate = useNavigate();
  const { setIsLoading } = useContext(AppContext);
  const { filter, setFilter, topicList, setTopicList } =
    useContext(TopicContext);

  const [totalElements, setTotalElements] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<TTopic>({});
  const [typeConfirm, setTypeConfirm] = useState<string>("");
  const [openAcceptanceMinuteDialog, setOpenAcceptanceMinuteDialog] = useState<boolean>(false);
  const [openScienceMinuteDialog, setOpenScienceMinuteDialog] = useState<boolean>(false);
  const [openEvaluationForm, setOpenEvaluationForm] = useState<boolean>(false);
  const [openApprovalOutlineDialog, setOpenApprovalOutlineDialog] =useState<boolean>(false)
  const [openAcceptanceForm, setOpenAcceptanceForm] =useState<boolean>(false)
  const messageError = {
    file: {
      required: "Vui lòng nhập file!",
    },
  };
  const [errorText, setErrorText] = useState({
    file: "",
  });
  useEffect(() => {
    getTopicList();
  }, [filter]);

  useEffect(() => {
    setFilter({ ...filter, pageIndex: page, pageSize: rowsPerPage });
  }, [page, rowsPerPage]);

  const getTopicList = async () => {
    try {
      setIsLoading(true);
      let { data } = await searchTopic(filter);
      if (data?.code === CODE.SUCCESS) {
        setTopicList(data?.data?.content);
        setTotalElements(data?.data?.pageable?.totalElements);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleApprovalOutline = async (data: any) => {
    let formData = new FormData();
    if(data?.file){
      formData.append("file", data.file);
    }
    formData.append("code", String(data?.code || ""));
    try {
      setIsLoading(true);
      let { data } = await approveOutline(String(selectedRow?.id || ""), formData);
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
        toast.success("Phê duyệt đề cương thành công");
        setOpenApprovalOutlineDialog(false);
        getTopicList();
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {
      setIsLoading(false);
      setOpenApprovalOutlineDialog(false);
    }
  }

  const handleChangeStatus = async (data: any, row: TTopic) => {
    let { file } = data;
    let formData = new FormData();
    if(typeConfirm !== APPROVAL_TYPE.PROPOSAL){
      formData.append("file", file);
    }
    try {
      if (!row?.id) return;
      let apiChangeStatus = null;
      switch (typeConfirm) {
        case APPROVAL_TYPE.PROPOSAL:
          apiChangeStatus = approveProposal(row.id, formData);
          break;
        case APPROVAL_TYPE.TOPIC:
          apiChangeStatus = approveTopic(row.id, formData);
          break;
        case APPROVAL_TYPE.ACCEPTANCE:
          apiChangeStatus = approveAcceptance(row.id, formData);
          break;
        case REJECT_TYPE.OUTLINE:
          apiChangeStatus = rejectOutline(row.id, formData);
          break;
        case REJECT_TYPE.TOPIC:
          apiChangeStatus = rejectTopic(row.id, formData);
          break;
        case REJECT_TYPE.PROPOSAL:
          apiChangeStatus = rejectProposal(row.id, formData);
          break;
        case REJECT_TYPE.ACCEPTANCE:
          apiChangeStatus = rejectAcceptance(row.id, formData);
          break;
        default:
          break;
      }
      if (!apiChangeStatus) return;
      let { data } = await apiChangeStatus;
      if (data?.code === CODE.SUCCESS) {
        setOpenConfirmDialog(false);
        toast.success(
          `${
            typeConfirm.charAt(0).toUpperCase() + typeConfirm.slice(1)
          } thành công!`
        );
        getTopicList();
      } else {
        toast.warning(
          `${
            typeConfirm.charAt(0).toUpperCase() + typeConfirm.slice(1)
          } thất bại`
        );
        setOpenConfirmDialog(false);
      }
    } catch (error) {
      console.error(error);
      setOpenConfirmDialog(false);
    }
  };

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: "50px",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row, index, stt) => {
        return (
          <div>
            {(Number(filter?.pageIndex) - 1) * Number(filter?.pageSize) +
              index +
              1}
          </div>
        );
      },
    },
    {
      name: "Đợt",
      field: "regisPeriod",
      headerStyle: {
        width: "100px",
      },
      cellStyle: {
        textAlign: "center",
        color: "#333",
      },
      render: (row, index, stt) => {
        return (
          <div>
            {row?.regisPeriod
              ? moment(row?.regisPeriod).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      name: "Mã đề xuất",
      field: "proposalCode",
      headerStyle: {
        maxWidth: "90px",
      },
      cellStyle: {
        textAlign: "center",
        maxWidth: "90px",
      },
      render: (row, index, stt) => {
        return (
          <div
            className="text-pri"
            onClick={() => {
              navigate(`/quan-ly-de-tai/de-tai/${row.id}`, {
                state: {
                  show: true,
                  data: row,
                },
                replace: true,
              });
            }}
          >
            {row.proposalCode}
          </div>
        );
      },
    },
    {
      name: "Mã đề tài",
      field: "code",
      headerStyle: {
        maxWidth: "90px",
      },
      cellStyle: {
        maxWidth: "90px",
      },
      render: (row, index, stt) => {
        return (
          <div
            className="text-pri"
            onClick={() => {
              navigate(`/quan-ly-de-tai/de-tai/${row.id}`, {
                state: {
                  show: true,
                  data: row,
                },
                replace: true,
              });
            }}
          >
            {row.code}
          </div>
        );
      },
    },
    {
      name: "Tên đề tài",
      field: "name",
      headerStyle: {
        minWidth: "100px",
      },
      cellStyle: {
        color: "#333",
      },
    },
    {
      name: "Chủ nhiệm",
      field: "chuNhiem",
      headerStyle: {
        minWidth: "50px",
      },
      cellStyle: {
        textAlign: "center",
        color: "#333",
      },
      render: (row, index, stt) => {
        return (
          <div>
            {row?.members?.find((member: any) => member?.typeId === 1)?.name}
          </div>
        );
      },
    },
    {
      name: "Ngày đăng ký",
      field: "date",
      headerStyle: {
        width: "110px",
      },
      cellStyle: {
        textAlign: "center",
        width: "110px",
        color: "#333",
      },
      render: (row, index, stt) => {
        return (
          <div>
            {row?.date
              ? moment(row?.date).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      name: "Thời gian",
      field: "thoiGian",
      headerStyle: {
        minWidth: "90px",
      },
      cellStyle: {
        textAlign: "center",
        maxWidth: "90px",
        color: "#333",
      },
      render: (row, index, stt) => {
        return (
          <div>{`${
            (row?.startDate && moment(row?.startDate).format("DD/MM/YYYY")) ||
            ""
          } ${row?.startDate && row?.endDate ? " - " : ""} ${
            (row?.endDate && moment(row?.endDate).format("DD/MM/YYYY")) || ""
          }`}</div>
        );
      },
    },
    {
      name: "Gia hạn",
      field: "newDeadline",
      headerStyle: {
        minWidth: "90px",
      },
      cellStyle: {
        textAlign: "center",
        maxWidth: "90px",
        color: "#333",
      },
      render: (row, index, stt) => {
        return (
          <div>
            {row?.newDeadline
              ? moment(row?.newDeadline).format("DD/MM/YYYY")
              : ""}
          </div>
        );
      },
    },
    {
      name: "Trạng thái",
      field: "statusName",
      headerStyle: {
        minWidth: "100px",
      },
      cellStyle: {
        textAlign: "center",
        maxWidth: "100px",
        color: "#333",
      },
    },
    {
      name: "Thao tác",
      field: "action",
      headerStyle: {
        width: "150px",
      },
      cellStyle: {
        width: "150px",
      },
      render: (row, index, stt) => {
        return (
          <div className="flex justify-content-center align-items-center gap-4 px-4">
            <>
              {checkDisplayButton("sanPham", row?.statusId) && (
                <span
                  className="tooltips"
                  data-tooltip={BUTTON_CONFIG["sanPham"].title}
                  data-tooltip-pos="left"
                  data-tooltip-length="medium"
                >
                  <i
                    className={`bi bi-file-earmark-zip-fill fs-1 ${
                      checkActiveButton("sanPham", row?.statusId)
                        ? "text-pri"
                        : "text-gray"
                    } m-0`}
                    onClick={() => {
                      if (!checkActiveButton("sanPham", row?.statusId)) return;
                      navigate(`/quan-ly-de-tai/de-tai/${row.id}/san-pham`, {
                        state: {
                          show: true,
                          data: row,
                        },
                        replace: true,
                      });
                    }}
                  ></i>
                </span>
              )}
              {checkDisplayButton("giayTo", row?.statusId) && (
                <span
                  className="tooltips"
                  data-tooltip={BUTTON_CONFIG["giayTo"].title}
                  data-tooltip-pos="left"
                  data-tooltip-length="medium"
                >
                  <i
                    className={`bi bi-paperclip fs-1 ${
                      checkActiveButton("giayTo", row?.statusId)
                        ? "text-pri"
                        : "text-gray"
                    } m-0`}
                    onClick={() => {
                      if (!checkActiveButton("giayTo", row?.statusId)) return;
                      navigate(
                        `/quan-ly-de-tai/de-tai/${row.id}/giay-to-lien-quan`,
                        {
                          state: {
                            show: true,
                            data: row,
                          },
                          replace: true,
                        }
                      );
                    }}
                  ></i>
                </span>
              )}
            </>
            {checkDisplayButton("duyetDeTai", row?.statusId) && (
              <div
                onClick={() => {
                  setOpenConfirmDialog(true);
                  setSelectedRow(row);
                  setTypeConfirm(APPROVAL_TYPE.TOPIC);
                }}
              >
                <div className="dropdown-item text-pri fw-bold">
                  {BUTTON_CONFIG["duyetDeTai"].title}
                </div>
              </div>
            )}
            {checkDisplayButton("tuChoiDuyetDeTai", row?.statusId) && (
              <div
                onClick={() => {
                  setOpenConfirmDialog(true);
                  setSelectedRow(row);
                  setTypeConfirm(REJECT_TYPE.TOPIC);
                }}
              >
                <div className="dropdown-item text-pri fw-bold">
                  {BUTTON_CONFIG["tuChoiDuyetDeTai"].title}
                </div>
              </div>
            )}
            {checkDisplayButton("khac", row?.statusId) && (
              <div className="dropdown topic-action-dropdown dropstart">
                <div
                  className="dropdown-toggle text-pri mr-4"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span
                    className="tooltips"
                    data-tooltip={BUTTON_CONFIG["khac"].title}
                    data-tooltip-pos="left"
                    data-tooltip-length="small"
                  >
                    <i className="bi bi-menu-app fs-1 text-pri"></i>
                  </span>
                </div>
                <ul className="dropdown-menu">
                  {checkDisplayButton("duyetDeXuat", row?.statusId) && (
                    <li
                      onClick={() => {
                        if (row?.hasSuggestion) {
                          toast.warning(
                            "Đang có góp ý, không thể duyệt đề xuất"
                          );
                          return;
                        }
                        setOpenConfirmDialog(true);
                        setSelectedRow(row);
                        setTypeConfirm(APPROVAL_TYPE.PROPOSAL);
                      }}
                    >
                      <div
                        className={`dropdown-item ${
                          row?.hasSuggestion ? "text-gray" : "text-pri"
                        } fw-bold py-2 px-4`}
                      >
                        {BUTTON_CONFIG["duyetDeXuat"].title}
                      </div>
                    </li>
                  )}
                  {checkDisplayButton("tuChoiDuyetDeXuat", row?.statusId) && (
                    <li
                      onClick={() => {
                        if (row?.hasSuggestion) {
                          toast.warning(
                            "Đang có góp ý, không thể từ chối đề xuất"
                          );
                          return;
                        }
                        setOpenConfirmDialog(true);
                        setSelectedRow(row);
                        setTypeConfirm(REJECT_TYPE.PROPOSAL);
                      }}
                    >
                      <div
                        className={`dropdown-item ${
                          row?.hasSuggestion ? "text-gray" : "text-pri"
                        } fw-bold py-2 px-4`}
                      >
                        {BUTTON_CONFIG["tuChoiDuyetDeXuat"].title}
                      </div>
                    </li>
                  )}
                  <li onClick={() => {
                    if(!row?.scienceCommitteeId) {
                      toast.warning("Chưa tạo hội đồng khoa học");
                      return;
                    }
                    if(row?.hasModificationRequest) {
                      toast.warning("Đang có yêu cầu chỉnh sửa");
                      return;
                    }
                    if(!row?.allowScienceReview) {
                      toast.warning("Hiện tại không thể đánh giá");
                      return;
                    }
                    setOpenEvaluationForm(true);
                    setSelectedRow(row);
                  }}>
                    <div
                      className={`dropdown-item ${(!row?.scienceCommitteeId || row?.hasModificationRequest || !row?.allowScienceReview) ? 'text-gray' : 'text-pri' } fw-bold py-2 px-4`}
                    >
                      {BUTTON_CONFIG["taoPhieuDanhGiaDT"].title}
                    </div>
                  </li>
                   {checkDisplayButton("taoBienBanKH", row?.statusId) && (
                    <li onClick={() => {
                      if(!row?.scienceCommitteeId) {
                        toast.warning("Chưa tạo hội đồng khoa học");
                        return;
                      }
                      if(row?.hasModificationRequest) {
                        toast.warning("Đang có yêu cầu chỉnh sửa");
                        return;
                      }
                      if(row?.hasScienceRecord) {
                        toast.warning("Đã tạo biên bản");
                        return;
                      }
                      if(!row?.allowScienceRecord) {
                        return;
                      }
                      setOpenScienceMinuteDialog(true);
                      setSelectedRow(row);
                    }}>
                      <div
                        className={`dropdown-item ${(!row?.allowScienceRecord) ? "text-gray" : "text-pri"} fw-bold py-2 px-4`}
                      >
                        {BUTTON_CONFIG["taoBienBanKH"].title}
                      </div>
                    </li>
                  )}
                  {checkDisplayButton("duyetDeCuong", row?.statusId) && (
                    <li
                      onClick={() => {
                        if (!row?.alreadyExamined) {
                          return;
                        }
                        setSelectedRow(row);
                        setOpenApprovalOutlineDialog(true);
                      }}
                    >
                      <div
                        className={`dropdown-item ${
                          !row?.alreadyExamined
                            ? "text-gray"
                            : "text-pri"
                        } fw-bold py-2 px-4`}
                      >
                        {BUTTON_CONFIG["duyetDeCuong"].title}
                      </div>
                    </li>
                  )}
                  {checkDisplayButton("tuChoiDuyetDeCuong", row?.statusId) && (
                    <li
                      onClick={() => {
                        if (!row?.alreadyExamined) {
                          return;
                        }
                        setOpenConfirmDialog(true);
                        setSelectedRow(row);
                        setTypeConfirm(REJECT_TYPE.OUTLINE);
                      }}
                    >
                      <div
                        className={`dropdown-item ${
                          !row?.alreadyExamined
                            ? "text-gray"
                            : "text-pri"
                        } fw-bold py-2 px-4`}
                      >
                        {BUTTON_CONFIG["tuChoiDuyetDeCuong"].title}
                      </div>
                    </li>
                  )}
                  <li onClick={() => {
                    if(!row?.acceptanceCommitteeId) {
                      toast.warning("Chưa tạo hội đồng nghiệm thu");
                      return;
                    }
                    if(row?.hasModificationRequest) {
                      toast.warning("Đang có yêu cầu chỉnh sửa");
                      return;
                    }
                    if(!row?.allowAcceptanceReview) {
                      toast.warning("Hiện tại không thể đánh giá");
                      return;
                    }
                    setOpenAcceptanceForm(true);
                    setSelectedRow(row);
                  }}>
                    <div
                      className={`dropdown-item ${(!row?.acceptanceCommitteeId || row?.hasModificationRequest || !row?.allowAcceptanceReview) ? 'text-gray' : 'text-pri' } fw-bold py-2 px-4`}
                    >
                      {BUTTON_CONFIG["taoPhieuDanhGiaNT"].title}
                    </div>
                  </li>
                   {checkDisplayButton("taoBienBanNT", row?.statusId) && (
                    <li onClick={() => {
                      if(!row?.acceptanceCommitteeId) {
                        toast.warning("Chưa tạo hội đồng nghiệm thu");
                        return;
                      }
                      if(row?.hasModificationRequest) {
                        toast.warning("Đang có yêu cầu chỉnh sửa");
                        return;
                      }
                      if(row?.hasAcceptanceRecord) {
                        toast.warning("Đã tạo biên bản");
                        return;
                      }
                      if(!row?.allowAcceptanceRecord) {
                        return;
                      }
                      setOpenAcceptanceMinuteDialog(true);
                      setSelectedRow(row);
                    }}>
                      <div
                        className={`dropdown-item ${(!row?.allowAcceptanceRecord) ? "text-gray" : "text-pri"} fw-bold py-2 px-4`}
                      >
                        {BUTTON_CONFIG["taoBienBanNT"].title}
                      </div>
                    </li>
                  )}
                  {checkDisplayButton("nghiemThu", row?.statusId) && (
                    <li
                      onClick={() => {
                        if (!row?.alreadyInspected) {
                          return;
                        }
                        setOpenConfirmDialog(true);
                        setSelectedRow(row);
                        setTypeConfirm(APPROVAL_TYPE.ACCEPTANCE);
                      }}
                    >
                      <div
                        className={`dropdown-item ${(row?.alreadyInspected) ? "text-pri" : "text-gray"} fw-bold py-2 px-4`}
                      >
                        {BUTTON_CONFIG["nghiemThu"].title}
                      </div>
                    </li>
                  )}
                  {checkDisplayButton("tuChoiNghiemThu", row?.statusId) && (
                    <li
                      onClick={() => {
                        if (!row?.alreadyInspected) {
                          return;
                        }
                        setOpenConfirmDialog(true);
                        setSelectedRow(row);
                        setTypeConfirm(REJECT_TYPE.ACCEPTANCE);
                      }}
                    >
                      <div
                        className={`dropdown-item ${(row?.alreadyInspected) ? "text-pri" : "text-gray"} fw-bold py-2 px-4`}
                      >
                        {BUTTON_CONFIG["tuChoiNghiemThu"].title}
                      </div>
                    </li>
                  )}
                  {checkDisplayButton(
                    "yeuCauChinhSuaNghiemThu",
                    row?.statusId
                  ) && (
                    <li
                      onClick={() => {
                        if (!row?.acceptanceCommitteeId) {
                          toast.warning("Chưa tạo hội đồng nghiệm thu");
                          return;
                        }
                        if (
                          row?.acceptanceCommitteeId &&
                          !row?.reportModDueDate
                        ) {
                          navigate(
                            `/quan-ly-de-tai/de-tai/${row.id}/yeu-cau-sua-nghiem-thu`,
                            {
                              state: {
                                show: true,
                                data: row,
                              },
                              replace: true,
                            }
                          );
                        }
                      }}
                    >
                      <div
                        className={`dropdown-item ${
                          row?.acceptanceCommitteeId && !row?.reportModDueDate
                            ? "text-pri"
                            : "text-gray"
                        } fw-bold py-2 px-4`}
                      >
                        {BUTTON_CONFIG["yeuCauChinhSuaNghiemThu"].title}
                      </div>
                    </li>
                  )}
                  {/* {row?.statusId !== TRANG_THAI.CHO_DUYET_DE_XUAT && (
                    <li onClick={() => {
                      navigate(`/quan-ly-de-tai/de-tai/${row.id}/thong-bao-ket-qua`, {
                        state: {
                          show: true,
                          data: row,
                        },
                        replace: true,
                      });
                    }}>
                      <div
                        className="dropdown-item text-pri fw-bold py-2 px-4"
                      >
                        Thông báo kết quả
                      </div>
                    </li>
                  )} */}
                  {checkDisplayButton("gopY", row?.statusId) && (
                    <li
                      onClick={() => {
                        navigate(`/quan-ly-de-tai/de-tai/${row.id}/gop-y`, {
                          state: {
                            show: true,
                            data: row,
                          },
                          replace: true,
                        });
                      }}
                    >
                      <div className="dropdown-item text-pri fw-bold py-2 px-4">
                        {BUTTON_CONFIG["gopY"].title}
                      </div>
                    </li>
                  )}
                  {checkDisplayButton("yeuCauChinhSua", row?.statusId) && (
                    <li
                      onClick={() => {
                        if (!row?.scienceCommitteeId) {
                          toast.warning("Chưa tạo hội đồng khoa học");
                          return;
                        }
                        if (row?.hasModificationRequest) {
                          toast.warning("Đang có yêu cầu chỉnh sửa");
                          return;
                        }
                        navigate(
                          `/quan-ly-de-tai/de-tai/${row.id}/yeu-cau-sua`,
                          {
                            state: {
                              show: true,
                              data: row,
                            },
                            replace: true,
                          }
                        );
                      }}
                    >
                      <div
                        className={`dropdown-item ${
                          !row?.scienceCommitteeId ||
                          row?.hasModificationRequest
                            ? "text-gray"
                            : "text-pri"
                        } fw-bold py-2 px-4`}
                      >
                        {BUTTON_CONFIG["yeuCauChinhSua"].title}
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="bg-white">
      <div className="spaces bg-gray radius-2">
        <div className="bg-white spaces width-100">
          <h4 className="text-title border-bottom text-pri fw-bold fs-4 spaces mb-0 px-10">
            Danh sách đề tài
          </h4>
          <Filter />
        </div>
        <div className="d-flex flex-column spaces width-100">
          <div className="bg-white">
            <div className=" spaces bg-white m-0">
              <div className="bg-white spaces mb-6 pb-15 fl-1 ml-10 mr-10">
                <OctTable
                  id="ds-de-tai"
                  className="spaces de-tai-table"
                  columns={columns}
                  data={topicList || []}
                  justFilter={false}
                  clearToolbar
                  noToolbar
                  noPagination
                  scrollable
                />
                <OctTablePagination
                  handlePagesChange={handlePagesChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalPages={Math.ceil(totalElements / rowsPerPage)}
                  totalElements={totalElements}
                  rowsForPage={rowsForPage}
                  numberOfElements={0}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {openScienceMinuteDialog && (
        <ScienceMinutesDialog
          show={openScienceMinuteDialog}
          onRefresh={getTopicList}
          onHide={() => {
            setOpenScienceMinuteDialog(false);
            setSelectedRow({});
          }}
          id={selectedRow?.id || ""}
        />
      )}
      {openAcceptanceMinuteDialog && (
        <AcceptanceMinutesDialog
          show={openAcceptanceMinuteDialog}
          onRefresh={getTopicList}
          onHide={() => {
            setOpenAcceptanceMinuteDialog(false);
            setSelectedRow({});
          }}
          id={selectedRow?.id || ""}
        />
      )}
      {openEvaluationForm &&(
        <EvaluationForm
          show={openEvaluationForm}
          onRefresh={getTopicList}
          onHide ={() => {
            setOpenEvaluationForm(false);
            setSelectedRow({})
          }}
          id={selectedRow?.id || ""}
          councilId={selectedRow?.scienceCommitteeId}
        />
      )}
      {openAcceptanceForm &&(
        <AcceptanceForm
          show={openAcceptanceForm}
          onRefresh={getTopicList}
          onHide ={() => {
            setOpenAcceptanceForm(false);
            setSelectedRow({})
          }}
          id={selectedRow?.id || ""}
        />
      )}
      {openConfirmDialog && (
        <ConfirmDialog
          show={openConfirmDialog}
          yes="Xác nhận"
          onYesClick={(data: any) => handleChangeStatus(data, selectedRow)}
          message={`Bạn có chắc muốn ${typeConfirm} này?`}
          close="Huỷ"
          onCloseClick={() => setOpenConfirmDialog(false)}
          title="Xác nhận thao tác"
          hasFile={typeConfirm !== APPROVAL_TYPE.PROPOSAL}
        />
      )}
      {openApprovalOutlineDialog && (
        <ApprovalOutlineDialog
          show={openApprovalOutlineDialog}
          onHide={() => setOpenApprovalOutlineDialog(false)}
          onSubmit={(data: any) => handleApprovalOutline(data)}
        />
      )}
    </div>
  );
};

export default Topic;
