import { columnNamesType } from "@oceantech/oceantech-ui";
import { FormCheck } from "react-bootstrap";
import { OptionType } from "../../../category-personnel/model/PersonnelModel";
import { REPORT_SUB_TYPE } from "../../../utils/Constant";

export const COMPONENT_TYPE = {
  TEXTFIELD: "textfield",
  DATEPICKER: "datepicker",
  NUMBER: "number",
  SELECT: "select",
  CHECKBOX: "checkbox",
  RADIO: "radio",
  TEXTAREA: "textarea",
  FILE: "file",
  COLUMNS: "columns",
  BUTTON: "button",
  PASSWORD: "password",
  SELECTBOXES: "selectboxes",
  DAY: "day",
  TIME: "time",
  EMAIL: "email",
  DATETIME: "datetime",
  PHONE: "phoneNumber",
  CONTENT: "content",
  HTML: "htmlelement",
};

export const memberColumns: columnNamesType[] = [
  {
    name: "STT",
    field: "stt",
    headerStyle: {
      width: "50px",
    },
    cellStyle: {
      textAlign: "center",
    },
    render: (row, index, stt) => {
      return stt;
    },
  },
  {
    name: "Họ tên",
    field: "hoTen",
    headerStyle: {
      minWidth: "150px",
    },
  },
  {
    name: "Chức vụ",
    field: "chucVu",
    headerStyle: {
      minWidth: "200px",
    },
  },
  {
    name: "Tham gia chính",
    field: "thamGiaChinh",
    headerStyle: {
      minWidth: "50px",
      maxWidth: "50px"
    },
    cellStyle: {
      textAlign: "center"
    },
    render: (row, index, stt) => {
      return (
        <FormCheck checked={row.thamGiaChinh} readOnly onChange={() => {}}/>
      )
    }
  },
]

export const TRANG_THAI: OptionType[] = [
  {
    id: 1,
    title: "Chờ duyệt đề xuất",
    value: "3"
  },
  {
    id: 2,
    title: "Chờ nộp đề cương",
    value: "4"
  },
  {
    id: 3,
    title: "Chờ xét duyệt đề cương",
    value: "5"
  },
  {
    id: 4,
    title: "Chờ phê duyệt",
    value: "6"
  },
  {
    id: 5,
    title: "Đang triển khai",
    value: "7"
  },
  {
    id: 6,
    title: "Từ chối phê duyệt",
    value: "8"
  },
  {
    id: 7,
    title: "Chờ nghiệm thu",
    value: "9"
  },
  {
    id: 8,
    title: "Đã nghiệm thu",
    value: "10"
  },
  {
    id: 9,
    title: "Từ chối nghiệm thu",
    value: "11"
  },
  {
    id: 10,
    title: "Từ chối đề xuất",
    value: "15"
  },
  {
    id: 11,
    title: "Từ chối đề cương",
    value: "16"
  }
]

export const GIA_HAN: OptionType[] = [
  {
    id: 1,
    title: "Có",
    value: "1"
  },
  {
    id: 2,
    title: "Không",
    value: "2"
  }
]

export enum APPROVAL_TYPE {
  PROPOSAL = "duyệt đề xuất",
  ACCEPTANCE = "nghiệm thu",
  OUTLINE = "duyệt đề cương",
  TOPIC = "duyệt đề tài"
}

export enum REJECT_TYPE {
  PROPOSAL = "từ chối duyệt đề xuất",
  ACCEPTANCE = "từ chối nghiệm thu",
  OUTLINE = "từ chối duyệt đề cương",
  TOPIC = "từ chối duyệt đề tài"
}

export const REPORT_TYPE = {
  PRODUCT: 1,
  ATTACHMENT: 2
}

const findBySubTypeId = (arr: any, subTypeId: number) => {
  return arr?.find((item: any) => item?.subTypeId === subTypeId)
}

export const getListAttachment = (data: any) => {
  return {
    [REPORT_SUB_TYPE.BIEN_BAN_HOI_DONG_KHOA_HOC]: findBySubTypeId(data, REPORT_SUB_TYPE.BIEN_BAN_HOI_DONG_KHOA_HOC),
    [REPORT_SUB_TYPE.BIEN_BAN_HOI_DONG_DAO_DUC]: findBySubTypeId(data, REPORT_SUB_TYPE.BIEN_BAN_HOI_DONG_DAO_DUC),
    [REPORT_SUB_TYPE.BIEN_BAN_GIAI_TRINH_CHINH_SUA]: findBySubTypeId(data, REPORT_SUB_TYPE.BIEN_BAN_GIAI_TRINH_CHINH_SUA),
    [REPORT_SUB_TYPE.DE_CUONG]: findBySubTypeId(data, REPORT_SUB_TYPE.DE_CUONG),
    [REPORT_SUB_TYPE.PHIEU_NHAN_XET]: findBySubTypeId(data, REPORT_SUB_TYPE.PHIEU_NHAN_XET),
    [REPORT_SUB_TYPE.QUYET_DINH_PHE_DUYET_DE_TAI]: findBySubTypeId(data, REPORT_SUB_TYPE.QUYET_DINH_PHE_DUYET_DE_TAI),
    [REPORT_SUB_TYPE.GIA_HAN]: findBySubTypeId(data, REPORT_SUB_TYPE.GIA_HAN),
    [REPORT_SUB_TYPE.GOP_Y_DE_XUAT]: findBySubTypeId(data, REPORT_SUB_TYPE.GOP_Y_DE_XUAT),
    [REPORT_SUB_TYPE.YEU_CAU_CHINH_SUA_DE_CUONG]: findBySubTypeId(data, REPORT_SUB_TYPE.YEU_CAU_CHINH_SUA_DE_CUONG),
    [REPORT_SUB_TYPE.QUYET_DINH_THANH_LAP_HD_KHOA_HOC]: findBySubTypeId(data, REPORT_SUB_TYPE.QUYET_DINH_THANH_LAP_HD_KHOA_HOC),
    [REPORT_SUB_TYPE.QUYET_DINH_THANH_LAP_HD_NGHIEM_THU]: findBySubTypeId(data, REPORT_SUB_TYPE.QUYET_DINH_THANH_LAP_HD_NGHIEM_THU),
    [REPORT_SUB_TYPE.QUYET_DINH_NGHIEM_THU]: findBySubTypeId(data, REPORT_SUB_TYPE.QUYET_DINH_NGHIEM_THU),
    [REPORT_SUB_TYPE.DUYET_DE_XUAT]: findBySubTypeId(data, REPORT_SUB_TYPE.DUYET_DE_XUAT),
    [REPORT_SUB_TYPE.TU_CHOI_DE_XUAT]: findBySubTypeId(data, REPORT_SUB_TYPE.TU_CHOI_DE_XUAT),
    [REPORT_SUB_TYPE.XET_DUYET_DE_CUONG]: findBySubTypeId(data, REPORT_SUB_TYPE.XET_DUYET_DE_CUONG),
    [REPORT_SUB_TYPE.TU_CHOI_XET_DUYET_DE_CUONG]: findBySubTypeId(data, REPORT_SUB_TYPE.TU_CHOI_XET_DUYET_DE_CUONG),
    [REPORT_SUB_TYPE.PHE_DUYET_DE_TAI]: findBySubTypeId(data, REPORT_SUB_TYPE.PHE_DUYET_DE_TAI),
    [REPORT_SUB_TYPE.TU_CHOI_PHE_DUYET_DE_TAI]: findBySubTypeId(data, REPORT_SUB_TYPE.TU_CHOI_PHE_DUYET_DE_TAI),
    [REPORT_SUB_TYPE.NGHIEM_THU_DE_TAI]: findBySubTypeId(data, REPORT_SUB_TYPE.NGHIEM_THU_DE_TAI),
    [REPORT_SUB_TYPE.TU_CHOI_NGHIEM_THU_DE_TAI]: findBySubTypeId(data, REPORT_SUB_TYPE.TU_CHOI_NGHIEM_THU_DE_TAI),
  }
}