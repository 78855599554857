import { columnNamesType, OctTable, OctTablePagination } from "@oceantech/oceantech-ui";
import { FC, useContext, useEffect, useState } from "react";
import AddBatchOfRegistrationDialog from "./components/AddBatchOfRegistrationDialog";
import { initBatchOfRegistration, initFilter, TBatchOfRegistration, TBatchOfRegistrationFilter } from "./models/OpenBatchOfRegistrationModel";
import Filter from "./components/Filter";
import { OpenBatchOfRegistrationContext } from "./OpenBatchOfRegistrationContext";
import { closeBatchOfRegistration, deleteBatchOfRegistration, openBatchOfRegistration, searchBatchOfRegistration } from "./services/services";
import { CODE, DEFAULT_PAGE_INDEX } from "../../utils/Constant";
import moment from "moment";
import { ConfirmDialog } from "../../component/ConfirmDialog";
import { AppContext } from "../../appContext/AppContext";
import { toast } from "react-toastify";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../../utils/PageUtils";
import { STATUS_ID } from "../../dang-ky-de-tai/constants/constants";
import { CONFIRM_TYPE } from "./constants/constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const OpenBatchOfRegistration: FC = () => {

  const { setIsLoading } = useContext(AppContext);
  const { batchOfRegistrationList, setBatchOfRegistrationList, filter, setFilter } = useContext(OpenBatchOfRegistrationContext);

  const [openDialogAdd, setOpenDialogAdd] = useState<boolean>(false);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<TBatchOfRegistration>({});
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [typeConfirm, setTypeConfirm] = useState<string>("");
  const [dataEdit, setDataEdit] = useState<TBatchOfRegistration>(initBatchOfRegistration);

  useEffect(() => {
    getBatchOfRegistrationList();
  }, [filter])

  useEffect(() => {
    setFilter({...filter, pageIndex: page, pageSize: rowsPerPage})
  }, [page, rowsPerPage])

  const getBatchOfRegistrationList = async () => {
    try {
      setIsLoading(true);
      let { data } = await searchBatchOfRegistration(filter);
      if (data?.code === CODE.SUCCESS) {
        setOpenDialogAdd(false);
        setBatchOfRegistrationList(data?.data?.content);
        setTotalElements(data?.data?.pageable?.totalElements);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  const columns: columnNamesType[] = [
    {
      name: "STT",
      field: "stt",
      headerStyle: {
        width: "50px",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row, index, stt) => {
        return <div>{(((Number(filter?.pageIndex) - 1) * Number(filter?.pageSize) + index) + 1)}</div>
      },
    },
    {
      name: "Đợt đăng ký",
      field: "startDate",
      headerStyle: {
        width: "100px",
      },
      cellStyle: {
        textAlign: "center",
        width: "100px",
        color: "#333"
      },
      render: (row, index, stt) => {
        return (
          <div>
            {row?.startDate && moment(row?.startDate).format("DD/MM/YYYY")}
          </div>
        );
      },
    },
    {
      name: "Trạng thái",
      field: "statusName",
      headerStyle: {
        width: "120px",
      },
      cellStyle: {
        textAlign: "center",
        width: "120px",
      },
      render: (row, index, stt) => {
        return (
          <div
            className={`status ${row?.statusName === "Mở" ? "open" : "close"}`}
          >
            {row?.statusName}
          </div>
        );
      },
    },
    {
      name: "Tên đợt",
      field: "name",
      headerStyle: {
        minWidth: "300px",
      },
      cellStyle: {
        color: "#333"
      }
    },
    {
      name: "Thao tác",
      field: "action",
      headerStyle: {
        width: "120px",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row, index, stt) => {
        return (
          <div className="flex justify-content-center align-items-center gap-4 px-4">
            <div
              onClick={() => {
                setIsOpenConfirmDialog(true);
                setSelectedRow(row);
                setTypeConfirm(
                  row?.statusId === STATUS_ID.OPEN
                    ? CONFIRM_TYPE.CLOSE
                    : CONFIRM_TYPE.OPEN
                );
              }}
              className={`${
                row?.statusId === STATUS_ID.OPEN ? "close" : "open"
              }`}
            >
              {row?.statusId === STATUS_ID.OPEN ? (
                <span
                  className="tooltips"
                  data-tooltip="Đóng đợt"
                  data-tooltip-pos="left"
                  data-tooltip-length="small"
                >
                  <i className="bi bi-lock-fill fs-2 text-danger"></i>
                </span>
              ) : (
                <span
                  className="tooltips"
                  data-tooltip="Mở đợt"
                  data-tooltip-pos="left"
                  data-tooltip-length="small"
                >
                  <i className="bi bi-unlock-fill fs-2 text-pri"></i>
                </span>
              )}
            </div>
            <div
              onClick={() => {
                if (row?.hasTopic) return;
                setOpenDialogAdd(true);
                setDataEdit(row);
              }}
            >
              <span
                className="tooltips"
                data-tooltip="Chỉnh sửa"
                data-tooltip-pos="left"
                data-tooltip-length="medium"
              >
                <i
                  className={`bi bi-pencil-fill fs-2 ${
                    row?.hasTopic ? "text-gray" : "text-pri"
                  }`}
                ></i>
              </span>
            </div>
            <div
              onClick={() => {
                if (row?.hasTopic) return;
                setIsOpenConfirmDialog(true);
                setSelectedRow(row);
                setTypeConfirm(CONFIRM_TYPE.DELETE);
              }}
            >
              <span
                className="tooltips"
                data-tooltip="Xóa"
                data-tooltip-pos="left"
                data-tooltip-length="small"
              >
                <i
                  className={`bi bi-trash3 fs-2 ${
                    row?.hasTopic ? "text-gray" : "text-pri"
                  }`}
                ></i>
              </span>
            </div>
          </div>
        );
      },
    },
  ];

  const handleYesClick = async (row: TBatchOfRegistration) => {
    try {
      if(!row?.id) return;
      let api = null;
      switch (typeConfirm) {
        case CONFIRM_TYPE.OPEN:
          api = openBatchOfRegistration(row.id);
          break;
        case CONFIRM_TYPE.CLOSE:
          api = closeBatchOfRegistration(row.id);
          break;
        case CONFIRM_TYPE.DELETE:
          api = deleteBatchOfRegistration(row.id);
          break;
        default:
          break;
      }
      if(!api) return;
      let { data } = await api;
      if(data?.code === CODE.SUCCESS) {
        setIsOpenConfirmDialog(false);
        toast.success(`${typeConfirm.charAt(0).toUpperCase() + typeConfirm.slice(1)} thành công!`);
        getBatchOfRegistrationList();
      } else {
        toast.warning(`${typeConfirm.charAt(0).toUpperCase() + typeConfirm.slice(1)} thất bại`);
        setIsOpenConfirmDialog(false);
      }
    } catch (error) {
      console.error(error);
      setIsOpenConfirmDialog(false);
    }
  }

  return (
    <div className="bg-white">
      <div className="spaces bg-gray radius-2">
        <div className="bg-white spaces width-100 flex justify-content-between p-10 border-bottom">
          <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0">
            Danh sách đợt đăng ký
          </h4>
          <button
            type="button"
            onClick={() => {
              setOpenDialogAdd(true);
            }}
            className="spaces min-w-90px btn btn-primary btn btn-primary"
          >
            <i className="bi bi-plus-lg text-white fs-3"></i>
            Thêm
          </button>
        </div>
        <Filter/>
        <div className="d-flex flex-column spaces width-100">
          <div className="bg-white">
            <div className=" spaces bg-white m-0">
              <div className="bg-white spaces mb-6 pb-15 fl-1 ml-10 mr-10">
                <OctTable
                  id="h-bang-ds-dot-dang-ky"
                  className="spaces dot-dang-ky-table"
                  columns={columns}
                  data={batchOfRegistrationList}
                  justFilter={false}
                  clearToolbar
                  noToolbar
                  noPagination
                />
                <OctTablePagination
                  handlePagesChange={handlePagesChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalPages={Math.ceil(totalElements/rowsPerPage)}
                  totalElements={totalElements}
                  rowsForPage={rowsForPage}
                  numberOfElements={0}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {openDialogAdd && (
        <AddBatchOfRegistrationDialog
          show={openDialogAdd}
          onHide={() => {setOpenDialogAdd(false); setDataEdit({})}}
          dataEdit={dataEdit}
          onRefresh={getBatchOfRegistrationList}
        />
      )}
      {isOpenConfirmDialog && (
        <ConfirmDialog
          show={isOpenConfirmDialog}
          yes="Xác nhận"
          onYesClick={() => handleYesClick(selectedRow)}
          message={`Bạn có chắc muốn ${typeConfirm} đăng ký này?`}
          close="Huỷ"
          onCloseClick={() => setIsOpenConfirmDialog(false)}
          title="Xác nhận thao tác"
        />
      )}
    </div>
  );
};

export default OpenBatchOfRegistration;
