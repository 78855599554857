import moment from "moment";
import { FC, Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { TTopicDetails } from "../../../quan-ly-de-tai/de-tai/models/TopicModel";
import { GENDER } from "../../../utils/Constant";
import { TMainResearcher, TTopicSecretary } from "../../models/MyTopicModel";

type Iprops = {
  topicInfo: TTopicDetails;
  mainResearcher: TMainResearcher;
  memberList: TMainResearcher[];
  coMainResearcher: TMainResearcher;
  topicSecretary: TTopicSecretary;
};
const TabDetails: FC<Iprops> = (props) => {

  const { topicInfo, mainResearcher, coMainResearcher, topicSecretary, memberList } = props;

  return (
    <>
      <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 px-10">
        Thông tin chi tiết của đề tài
      </h4>
      <Row className="spaces px-10">
        <Col xs={4}>
          <div className="flex py-2">
            <div className="label fw-bold min-w-150px">Tên đề tài:</div>
            <div className="flex-1">{topicInfo?.name}</div>
          </div>
        </Col>
        <Col xs={4}>
          <div className="flex py-2">
            <div className="label fw-bold min-w-150px">Mã đề tài:</div>
            <div className="flex-1">{topicInfo?.code}</div>
          </div>
        </Col>
        <Col xs={4}>
          <div className="flex py-2">
            <div className="label fw-bold min-w-150px">
              Thời gian thực hiện:
            </div>
            <div className="flex-1">
              {topicInfo?.startDate &&
                moment(topicInfo.startDate).format("DD/MM/YYYY")}{" "}
              {topicInfo.startDate && topicInfo.endDate && " - "}{" "}
              {topicInfo.endDate &&
                moment(topicInfo.endDate).format("DD/MM/YYYY")}
            </div>
          </div>
        </Col>
        <Col xs={4}>
          <div className="flex py-2">
            <div className="label fw-bold min-w-150px">Ngày đăng ký:</div>
            <div className="flex-1">
              {topicInfo?.date &&
                moment(topicInfo.date).format("DD/MM/YYYY")}
            </div>
          </div>
        </Col>
        <Col xs={4}>
          <div className="flex py-2">
            <div className="label fw-bold min-w-150px">Trạng thái:</div>
            <div className="flex-1">{topicInfo?.statusName}</div>
          </div>
        </Col>
        <Col xs={4}>
          <div className="flex py-2">
            <div className="label fw-bold min-w-150px">Đợt:</div>
            <div className="flex-1">
              {topicInfo?.regisPeriod &&
                moment(topicInfo.regisPeriod).format("DD/MM/YYYY")}
            </div>
          </div>
        </Col>
        <Col xs={4}>
          <div className="flex py-2">
            <div className="label fw-bold min-w-150px">Gia hạn:</div>
            <div className="flex-1">
              {topicInfo?.newDeadline &&
                moment(topicInfo.newDeadline).format("DD/MM/YYYY")}
            </div>
          </div>
        </Col>
      </Row>
      <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 px-10">
        Thông tin chủ nhiệm đề tài
      </h4>
      <Row className="spaces px-10">
        <Col xs={4}>
          <div className="flex py-2">
            <div className="label fw-bold min-w-150px">Họ và tên:</div>
            <div className="flex-1">{mainResearcher?.name || ""}</div>
          </div>
        </Col>
        <Col xs={4}>
          <div className="flex py-2">
            <div className="label fw-bold min-w-150px">Giới tính:</div>
            <div className="flex-1">
              {GENDER.find((i) => i.value === mainResearcher?.gender)?.title}
            </div>
          </div>
        </Col>
        <Col xs={4}>
          <div className="flex py-2">
            <div className="label fw-bold min-w-150px">Học vị:</div>
            <div className="flex-1">{mainResearcher?.eduLevel || ""}</div>
          </div>
        </Col>
        <Col xs={4}>
          <div className="flex py-2">
            <div className="label fw-bold min-w-150px">Học hàm:</div>
            <div className="flex-1">
              {mainResearcher?.scientificTitle || ""}
            </div>
          </div>
        </Col>
        <Col xs={4}>
          <div className="flex py-2">
            <div className="label fw-bold min-w-150px">Email:</div>
            <div className="flex-1">{mainResearcher?.email || ""}</div>
          </div>
        </Col>
        <Col xs={4}>
          <div className="flex py-2">
            <div className="label fw-bold min-w-150px">Số điện thoại:</div>
            <div className="flex-1">{mainResearcher?.phone || ""}</div>
          </div>
        </Col>
      </Row>
      {coMainResearcher && (
        <>
          <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 px-10">
            Thông tin đồng chủ nhiệm
          </h4>
          <Row className="spaces px-10">
            <Col xs={4}>
              <div className="flex py-2">
                <div className="label fw-bold min-w-150px">Họ và tên:</div>
                <div className="flex-1">{coMainResearcher?.name || ""}</div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="flex py-2">
                <div className="label fw-bold min-w-150px">Giới tính:</div>
                <div className="flex-1">
                  {GENDER.find((i) => i.value === coMainResearcher?.gender)?.title}
                </div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="flex py-2">
                <div className="label fw-bold min-w-150px">Học vị:</div>
                <div className="flex-1">{coMainResearcher?.eduLevel || ""}</div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="flex py-2">
                <div className="label fw-bold min-w-150px">Học hàm:</div>
                <div className="flex-1">
                  {coMainResearcher?.scientificTitle || ""}
                </div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="flex py-2">
                <div className="label fw-bold min-w-150px">Email:</div>
                <div className="flex-1">{coMainResearcher?.email || ""}</div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="flex py-2">
                <div className="label fw-bold min-w-150px">Số điện thoại:</div>
                <div className="flex-1">{coMainResearcher?.phone || ""}</div>
              </div>
            </Col>
          </Row>
        </>
      )}
      {topicSecretary && (
        <>
          <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 px-10">
            Thông tin thư ký đề tài
          </h4>
          <Row className="spaces px-10">
            <Col xs={4}>
              <div className="flex py-2">
                <div className="label fw-bold min-w-150px">Họ và tên:</div>
                <div className="flex-1">{topicSecretary?.name || ""}</div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="flex py-2">
                <div className="label fw-bold min-w-150px">Giới tính:</div>
                <div className="flex-1">
                  {GENDER.find((i) => i.value === topicSecretary?.gender)?.title}
                </div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="flex py-2">
                <div className="label fw-bold min-w-150px">Học vị:</div>
                <div className="flex-1">{topicSecretary?.eduLevel || ""}</div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="flex py-2">
                <div className="label fw-bold min-w-150px">Học hàm:</div>
                <div className="flex-1">
                  {topicSecretary?.scientificTitle || ""}
                </div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="flex py-2">
                <div className="label fw-bold min-w-150px">Email:</div>
                <div className="flex-1">{topicSecretary?.email || ""}</div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="flex py-2">
                <div className="label fw-bold min-w-150px">Số điện thoại:</div>
                <div className="flex-1">{topicSecretary?.phone || ""}</div>
              </div>
            </Col>
          </Row>
        </>
      )}
      <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 px-10">
        Thông tin các thành viên khác
      </h4>
      {memberList.map((member: TMainResearcher, index: number) => (
        <Fragment key={index}>
          <div className="fw-bold px-10 fs-4 spaces">
            Thành viên {index + 1}
          </div>
          <Row className="spaces px-20">
            <Col xs={4}>
              <div className="flex py-2">
                <div className="label min-w-175px">Họ và tên:</div>
                <div className="flex-1">{member.name}</div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="flex py-2">
                <div className="label min-w-175px">MSCB:</div>
                <div className="flex-1"></div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="flex py-2">
                <div className="label min-w-175px">Giới tính:</div>
                <div className="flex-1">
                  {GENDER.find((i) => i.value === member?.gender)?.title}
                </div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="flex py-2">
                <div className="label min-w-175px">Học vị:</div>
                <div className="flex-1">{member.eduLevel || ""}</div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="flex py-2">
                <div className="label min-w-175px">Học hàm:</div>
                <div className="flex-1">{member.scientificTitle || ""}</div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="flex py-2">
                <div className="label min-w-175px">Email:</div>
                <div className="flex-1">{member.email || ""}</div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="flex py-2">
                <div className="label min-w-175px">Số điện thoại:</div>
                <div className="flex-1">{member.phone || ""}</div>
              </div>
            </Col>
          </Row>
        </Fragment>
      ))}
    </>
  );
};

export default TabDetails;
