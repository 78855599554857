import { FC, useContext, useEffect, useState } from "react";
import { Accordion, Button, Form, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { AppContext } from "../../appContext/AppContext";
import { getListAttachment } from "../../quan-ly-de-tai/de-tai/constants/constants";
import { CODE, RELATED_PAPER } from "../../utils/Constant";
import { handleDownloadFile, handleDownloadSampleFile } from "../../utils/ServicesUtils";
import { REPORT_TYPE } from "../constants/constants";
import { TMyTopic } from "../models/MyTopicModel";
import { getAttachmentList } from "../services/services";

const MyTopicAttachment: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsLoading } = useContext(AppContext);
  const state = location.state  as { show?: boolean, data?: TMyTopic };

  const [files, setFiles] = useState<any>({});

  useEffect(() => {
    getFiles();
  }, [])

  const findBySubTypeId = (arr: any, subTypeId: number) => {
    return arr?.find((item: any) => item?.subTypeId === subTypeId)
  }

  const getFiles = async () => {
    let topicId = state?.data?.id;
    if (!topicId) return;
    try {
      setIsLoading(true);
      let { data } = await getAttachmentList({ topicId, typeId: String(REPORT_TYPE.ATTACHMENT) });
      if (data?.code === CODE.SUCCESS) {
        let files = getListAttachment(data?.data);
        setFiles(files);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  return (
    <Modal
      centered
      show={state?.show}
      onHide={() => navigate("/de-tai-cua-toi", { replace: true })}
      size="xl"
      fullscreen
      className="page-full spaces h-calc-vh-50"
    >
      <Modal.Header className="header-modal min-h-40px">
        <div className="d-flex gap-6 py-2">
          <div
            className="d-flex align-items-center title-back gap-3 cursor-pointer"
            onClick={() => {
              navigate("/de-tai-cua-toi", { replace: true });
            }}
          >
            <i className="spaces bi bi-chevron-left ml-10"> </i>
            <div className="spaces mr-20">Quay lại</div>
          </div>
          <h4 className="text-title border-bottom text-pri fw-bold fs-4 spaces mb-0 px-10">
            Giấy tờ khác
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body className="spaces p-0 mt-3 bg-white">
        <Accordion defaultActiveKey="0">
          {RELATED_PAPER.map((item, index) => (
            <Accordion.Item eventKey={index.toString()} key={index}>
              <Accordion.Header>{item.title}</Accordion.Header>
              <Accordion.Body>
                <div className="flex gap-3 w-100">
                  <>
                    <Form.Group controlId="formFile" className="flex-1">
                      <Form.Control
                        type="text"
                        name={item.name}
                        disabled
                        size="sm"
                        value={files?.[item.subType]?.originalName}
                      />
                    </Form.Group>
                    {item?.hasSample && (
                      <Button variant="primary" onClick={() => handleDownloadSampleFile(`[Mẫu]${item.title}.doc`, item.apiPath || "")}>Tải mẫu</Button>
                    )}
                    <Button variant="primary" onClick={() => handleDownloadFile(files?.[item.subType]?.id, files?.[item.subType]?.originalName)}>Tải xuống</Button>
                  </>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Modal.Body>
    </Modal>
  );
};
export default MyTopicAttachment;
