import { FC, useContext, useEffect, useState } from "react";
import AcceptanceForm, { TAcceptanceForm } from "../../../quan-ly-de-tai/de-tai/components/AcceptanceForm";
import { AppContext } from "../../../appContext/AppContext";
import { toast } from "react-toastify";
import { CODE } from "../../../utils/Constant";
import { getReviewAcceptanceList } from "../../services/services";

type Iprops = {
  topicId?: string;
};
const TabReviewAcceptanceCouncil: FC<Iprops> = (props) => {

  const { topicId } = props;
  const { setIsLoading } = useContext(AppContext);

  const [isOpenReviewDialog, setIsOpenReviewDialog] = useState<boolean>(false);
  const [reviews, setReviews] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<TAcceptanceForm>();

  useEffect(() => {
    if (!topicId) return;
    getListReview(topicId);
  }, [topicId]);

  const getListReview = async (topicId: string) => {
    try {
      setIsLoading(true);
      let { data } = await getReviewAcceptanceList(topicId);
      if (data?.code === CODE.SUCCESS) {
        setReviews(data?.data);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <div className="form spaces p-10">
      <table className="comment-review">
        <thead>
          <tr>
            <td className="fw-bold" width={50}>STT</td>
            <td className="fw-bold" width={200}>Người đánh giá</td>
            <td className="fw-bold">Xếp loại đánh giá</td>
            <td className="fw-bold" width={150}>Thao tác</td>
          </tr>
        </thead>
        <tbody>
          {reviews?.map((item, index) => (
            <tr key={index}>
              <td className="text-center">{index + 1}</td>
              <td>{item?.reviewerName}</td>
              <td>{item?.criterion6}</td>
              <td className="text-center">
                <span
                  className="tooltips"
                  data-tooltip="Xem chi tiết"
                  data-tooltip-pos="left"
                  data-tooltip-length="medium"
                >
                <i className="bi bi-eye-fill m-0 fs-1 cursor-pointer text-danger" onClick={() => {
                  setSelectedRow(item);
                  setIsOpenReviewDialog(true);
                }}></i></span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isOpenReviewDialog && (
        <AcceptanceForm
          show={isOpenReviewDialog}
          onHide={() => setIsOpenReviewDialog(false)}
          dataDetails={selectedRow}
          isView
        />
      )}
    </div>
  );
};

export default TabReviewAcceptanceCouncil;
