import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { Accordion, Col, Form, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { AppContext } from "../../appContext/AppContext";
import { TextFieldCustom } from "../../category-personnel/components/text-field-custom/TextFieldCustom";
import { ACCEPT_FILE_TYPE, CODE } from "../../utils/Constant";
import { handleDownloadFile } from "../../utils/ServicesUtils";
import { REPORT_TYPE } from "../constants/constants";
import { TFileProduct, TMyTopic } from "../models/MyTopicModel";
import { addReportProduct, getAttachmentProduct } from "../services/services";

const MyTopicProduct: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsLoading } = useContext(AppContext);
  const state = location.state  as { show?: boolean, data?: TMyTopic };
  const errorMessage: { [key: string]: { require: string } } = {
    file: {
      require: 'Vui lòng chọn file!',
    },
    content: {
      require: 'Vui lòng nhập nội dung!',
    },
  };
const [errorText,setErrorText]= useState({
  file:'',
  content:''
})
  const [file, setFile] = useState<File>();
  const [files, setFiles] = useState<TFileProduct[]>([]);
  const [reportInfo, setReportInfo] = useState({
    content: "",
    topicId: state?.data?.id,
    typeId: REPORT_TYPE.PRODUCT
  });

  useEffect(() => {
    getHistoryFiles();
  }, [])

  const getHistoryFiles = async () => {
    let topicId = state?.data?.id;
    if (!topicId) return;
    try {
      setIsLoading(true);
      let { data } = await getAttachmentProduct({ topicId });
      if (data?.code === CODE.SUCCESS) {
        setFiles(data?.data?.content);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  const handleChange = (e: any) => {
    let { name, value } = e.target;
    setReportInfo({
      ...reportInfo,
      [name]: value
    })
    setErrorText({
      ...errorText,
      [name]:!value ? errorMessage[name].require :''
    })
  };

  const handleChangeFile = async (e: any) => {
    let file = e.target.files[0];
    if(!file) return;
    setFile(file);
    setErrorText({
      ...errorText,
      file:''
    })
  };

  const handleDownloadAll = () => {
    for (let index = 0; index < files.length; index++) {
      let fileId = files[index].fileId;
      let fileName = files[index].originalFileName;
      if(!fileId || !fileName) return;
      handleDownloadFile(fileId, fileName);
    }
  }

  const handleSubmit = async () => {
    if(!file) {
     setErrorText({
      ...errorText,
      file:errorMessage.file.require
     })
     return;
    }
    if(!reportInfo.content) {
      setErrorText({
        ...errorText,
        content:errorMessage.content.require
       })
      return;
    }
    let topicId = reportInfo.topicId;
    if(!topicId) return;
    let formData = new FormData();
    formData.append('file', file);
    formData.append('content', reportInfo.content);
    formData.append('topicId', topicId);
    formData.append('typeId', String(reportInfo.typeId));

    try {
      setIsLoading(true);
      const { data } = await addReportProduct(formData);
      if(data?.code === CODE.SUCCESS) {
        toast.success("Nộp báo cáo sản phẩm thành công");
        navigate("/de-tai-cua-toi", { replace: true });
      } else {
        setIsLoading(false);
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.warning(errorMesage);
      }
    } catch (error) {
      setIsLoading(false);
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      centered
      show={state?.show}
      onHide={() => navigate("/de-tai-cua-toi", { replace: true })}
      size="xl"
      fullscreen
      className="page-full spaces h-calc-vh-50"
    >
      <Modal.Header className="header-modal min-h-40px">
        <div className="d-flex gap-6 py-2">
          <div
            className="d-flex align-items-center title-back gap-3 cursor-pointer"
            onClick={() => {
              navigate("/de-tai-cua-toi", { replace: true });
            }}
          >
            <i className="spaces bi bi-chevron-left ml-10"> </i>
            <div className="spaces mr-20">Quay lại</div>
          </div>
          <h4 className="text-title border-bottom text-pri fw-bold fs-4 spaces mb-0 px-10">
            Nộp báo cáo
          </h4>
        </div>
      </Modal.Header>
      <Modal.Body className="spaces py-10 px-0 mt-3 bg-white">
        <div className="spaces px-10">
          <div className="">
            <Col xs={12}>
              <div className="flex py-2 align-items-center">
                <div className="label fw-bold min-w-150px">Ngày bắt đầu:</div>
                <div className="flex-1">{state?.data?.startDate && moment(state?.data?.startDate).format("DD/MM/YYYY")}</div>
              </div>
            </Col>
            <Col xs={12}>
              <div className="flex py-2 align-items-center">
                <div className="label fw-bold min-w-150px">Ngày kết thúc:</div>
                <div className="flex-1">{state?.data?.endDate && moment(state?.data?.endDate).format("DD/MM/YYYY")}</div>
              </div>
            </Col>
          </div>
        </div>
        <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 px-10">
          Nộp sản phẩm
        </h4>
        <div className="spaces px-10">
          <Row>
            <Col xs={6} className="spaces">
              <div className="flex py-2 align-items-center">
                <div className="label fw-bold min-w-150px">
                  Tập tin sản phẩm:
                </div>
                <div className="flex-1">
                  <Form.Group controlId="formFile" className="flex-1">
                    <Form.Control
                      type="file"
                      name="file"
                      size="sm"
                      accept=".zip, .rar"
                      onChange={(e) => handleChangeFile(e)}
                      isInvalid={!!errorText.file}
                    />
                    <Form.Control.Feedback type="invalid">{errorText.file}</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <div className="flex py-2 align-items-center">
                <div className="label fw-bold min-w-150px">Báo cáo:</div>
                <div className="flex-1">
                  <TextFieldCustom
                    type="text"
                    as="textarea"
                    className="min-h-90px"
                    title=""
                    name="content"
                    values={reportInfo.content}
                    handleChange={handleChange}
                    errors={errorText.content}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="d-flex justify-content-end">
              <button
                type="submit"
                className="spaces min-w-90px btn btn-primary btn btn-primary"
                onClick={handleSubmit}
              >
                Lưu
              </button>
            </Col>
          </Row>
        </div>
        {files?.length > 0 && (
          <div className="flex justify-content-between align-items-center">
            <h4 className="text-title text-pri fw-bold fs-4 spaces mb-0 p-10">
              Lịch sử nộp báo cáo
            </h4>
            <div className="spaces pe-2">
              <button
                type="button"
                className="spaces min-w-90px btn btn-primary btn btn-primary"
                onClick={() => handleDownloadAll()}
              >
                Tải tất cả
              </button>
            </div>
          </div>
        )}
        <Accordion defaultActiveKey="0">
          {files?.map((file: TFileProduct, index: number) => (
            <Accordion.Item eventKey={index.toString()}>
              <Accordion.Header>{`Ngày ${file.date ? moment(file.date).format("DD/MM/YYYY") : ""}`}</Accordion.Header>
              <Accordion.Body>
                <div className="spaces px-10 m-0">
                  <Row className="flex align-items-end">
                    <Col xs={6} className="spaces">
                      <div className="flex py-2 align-items-center">
                        <div className="label fw-bold min-w-150px">
                          Tập tin sản phẩm:
                        </div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            title=""
                            name="file"
                            disabled
                            values={files[index]?.originalFileName}
                            handleChange={() => {}}
                            errors=""
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className="spaces pb-7">
                      <button
                        type="button"
                        className="spaces min-w-90px btn btn-primary btn btn-primary"
                        onClick={() => {
                          handleDownloadFile(String(files[index]?.fileId), String(files[index]?.originalFileName));
                        }}
                      >
                        Tải xuống
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <div className="flex py-2 align-items-center">
                        <div className="label fw-bold min-w-150px">Báo cáo:</div>
                        <div className="flex-1">
                          <TextFieldCustom
                            type="text"
                            as="textarea"
                            className="min-h-90px"
                            title=""
                            name="content"
                            disabled
                            values={files[index]?.content}
                            handleChange={() => {}}
                            errors=""
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Modal.Body>
    </Modal>
  );
};
export default MyTopicProduct;
