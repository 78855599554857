import { Form, Formik } from "formik";
import moment from "moment";
import { useState } from "react";
import { Button, Col, Form as FormBS, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import { TextFieldCustom } from "../../category-personnel/components/text-field-custom/TextFieldCustom";
import { ACCEPT_FILE_TYPE } from "../../utils/Constant";
import { toast } from "react-toastify";

interface Props {
  show: boolean;
  onHide: () => void;
  onSubmit: (officer: any) => void;
}

const UploadOutlineDialog = (props: Props) => {
  const { show, onHide, onSubmit } = props;

  const [file, setFile] = useState<any>(null);
  const [errorText,setErrorText]=useState({
    file:''
  })

  const validationSchema = Yup.object({
    startDate: Yup.string().required("Không được để trống trường này!"),
    endDate: Yup.string().required("Không được để trống trường này!"),
  });

  const handleChangeFile = async (e: any) => {
    let file = e.target.files[0];
    if(!file) return;
    if(![ACCEPT_FILE_TYPE.DOC, ACCEPT_FILE_TYPE.DOCX].includes(file.type)) {
      toast.warning("Chi được phép tải lên tệp tin nên định dạng .doc hoặc .docx");
      e.target.value = '';
    }else{
      setFile(file);
      setErrorText({
        ...errorText,
        file:""
      })
    }
  };
  const handleSubmit = async (values: any) => {
    onSubmit({ 
      ...values,
      file,
      startDate: values.startDate ? moment(values.startDate).startOf("month").format("YYYY-MM-DD") : "",
      endDate: values.endDate ? moment(values.endDate).endOf("month").format("YYYY-MM-DD") : ""
    });
    onHide();
  };

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size="lg"
      scrollable
      className="spaces h-calc-vh-50"
    >
      <Formik
        enableReinitialize
        initialValues={{ startDate: "", endDate: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header className="header-modal min-h-40px" closeButton>
              <Modal.Title className="py-4">Tải lên đề cương</Modal.Title>
            </Modal.Header>
            <Modal.Body className="spaces p-0 bg-white">
              <div className="spaces p-10">
                <Row className="p-2">
                  <Col xs={6} className="pb-4">
                    <TextFieldCustom
                      type="month"
                      title="Thực hiện từ tháng"
                      name="startDate"
                      values={values.startDate}
                      errors={errors.startDate}
                      handleChange={handleChange}
                    />
                  </Col>
                  <Col xs={6} className="pb-4">
                    <TextFieldCustom
                      type="month"
                      title="Thực hiện đến tháng"
                      name="endDate"
                      values={values.endDate}
                      errors={errors.endDate}
                      handleChange={handleChange}
                    />
                  </Col>
                  <Col xs={12} className="pb-4">
                    <FormBS.Group controlId="formFile" className="flex-1 pt-4">
                      <FormBS.Label>Chọn file</FormBS.Label>
                      <FormBS.Control
                        type="file"
                        name="file"
                        size="sm"
                        accept={".doc, .docx"}
                        required
                        onChange={(e) => handleChangeFile(e)}
                        isInvalid={!!errorText.file}
                      />
                    </FormBS.Group>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer className="flex justify-content-center">
              <Button
                variant="primary"
                className="min-w-75px fw-bold"
                type="submit"
              >
                Lưu
              </Button>
              <Button
                variant="secondary"
                className="min-w-75px fw-bold"
                onClick={onHide}
              >
                Hủy
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default UploadOutlineDialog;
