import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CODE } from "../../../../../app/modules/utils/Constant";
import { getNotifications } from "../../../../../app/modules/utils/ServicesUtils";
import NotificationDialog from "./NotificationDialog";
import { TimeAgo } from "../../../../../app/modules/utils/AppFunction";
import moment from "moment";

type Iprops = {
  getCountUnread :() => Promise<void>
}
const MenuNotification = (props: Iprops) => {

  const { getCountUnread } = props

  const [notifications, setNotifications] = useState<any[]>([]);
  const [filter, setFilter] = useState<any>({ pageIndex: 1, pageSize: 5 });
  const [totalElements, setTotalElements] = useState<number>(0);
  const [openNotificationDialog, setOpenNotificationDialog] = useState<boolean>(false);
  const [notification, setNotification] = useState<any>({});

  useEffect(() => {
    getListNoti(false);
  }, [filter]);

  const getListNoti = async (isReset: boolean) => {
    try {
      let { data } = await getNotifications(isReset ? {...filter, pageIndex: 1, pageSize: 5} : filter);
      if (data?.code === CODE.SUCCESS) {
        setNotifications(isReset ? data?.data?.content : [...notifications, ...data?.data?.content]);
        setTotalElements(data?.data?.pageable?.totalElements);
      } else {
        toast.error("Đã có lỗi xảy ra!");
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <div className="flex justify-content-between align-items-center p-16 spaces">
        <p className="m-0 fs-3">Thông báo</p>
        <i className="bi bi-three-dots fs-3"></i>
      </div>
      <div className="flex justify-content-start align-items-center spaces px-16 pb-8 gap-12 noti-filter border-bottom">
        <p className="m-0 fs-5 active">Tất cả</p>
        <p className="m-0 fs-5">Chưa đọc</p>
      </div>
      <div className="noti-list spaces overflow-auto">
        {notifications?.map((noti: any, index: number) => (
          <div
            className={`noti-item spaces px-16 py-8 flex-column ${
              noti?.isRead ? "" : "unread"
            }`}
            key={index}
            onClick={() => {
              setOpenNotificationDialog(true);
              setNotification(noti);
            }}
          >
            <div className="title text-pri fs-4 py-2 spaces">
              {noti?.typeName?.charAt(0).toUpperCase() +
                noti?.typeName.slice(1)}
            </div>
            <div className="content fw-normal py-4 spaces">{noti?.content}</div>
            <div className="time fs-6 fw-normal text-gray text-italic py-2 spaces">
              <span
                className="tooltips"
                data-tooltip={moment(noti?.createdDate).format("DD/MM/YYYY HH:mm:ss")}
                data-tooltip-pos="right"
                data-tooltip-length="medium"
              >
              {TimeAgo.inWords(new Date(noti?.createdDate).getTime())}
              </span>
            </div>
          </div>
        ))}
        {notifications?.length === 0 && (
          <div className="spaces px-16 py-80 flex-column justify-content-center align-items-center gap-10">
            <i className="bi bi-bell-fill no-noti-icon"></i>
            <div className="fs-4 py-2 spaces fw-normal">Không có thông báo nào</div>
          </div>
        )}
      </div>
      <div className="view-more text-pri fs-6 text-center">
        <p
          className="m-0 spaces p-8"
          onClick={() =>
            setFilter({ ...filter, pageIndex: filter.pageIndex + 1 })
          }
        >
          {filter.pageIndex < Math.ceil(totalElements / filter.pageSize) &&
            "Xem thêm"}
        </p>
      </div>
      {Math.ceil(totalElements / filter.pageSize) === 1 && (
        <div className="spaces py-8"></div>
      )}
      {openNotificationDialog && (
        <NotificationDialog
          open={openNotificationDialog}
          handleCloseDialog={() => setOpenNotificationDialog(false)}
          data={notification}
          getCountUnread={getCountUnread}
          getListNoti={getListNoti}
        />
      )}
    </>
  );
}

export { MenuNotification }