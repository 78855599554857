import { FC, useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import { CODE } from "../../../utils/Constant";
import { MINMUTES_TYPE, TABS } from "../../constants/constants";
import { chairmanSignScience, getTopicScienceMinutes, secretarySignScience } from "../../services/services";
import moment from "moment";
import { TMainResearcher } from "../../models/MyTopicModel";
import { ConfirmDialog } from "../../../component/ConfirmDialog";

type IProps = {
  currentTab: string;
  topicId?: string;
  mainResearcher: TMainResearcher;
  coMainResearcher: TMainResearcher;
};
const TabScienceMinutes: FC<IProps> = (props) => {
  const { currentTab, topicId, mainResearcher, coMainResearcher } = props;
  const { setIsLoading } = useContext(AppContext);

  const [minutesInfo, setMinutesInfo] = useState<any>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [signer, setSigner] = useState<string>("");

  useEffect(() => {
    if (!currentTab || !topicId) return;
    getMinutesInfo(topicId);
  }, [currentTab, topicId]);

  const getMinutesInfo = async (topicId: string) => {
    if (currentTab !== TABS.SCIENCE_MINUTES.eventKey) return;
    try {
      setIsLoading(true);
      let { data } = await getTopicScienceMinutes(topicId);
      if (data?.code === CODE.SUCCESS) {
        setMinutesInfo(data?.data);
        setIsLoading(false);
      } else {
        toast.error("Đã có lỗi xảy ra!");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleYesClick = async () => {
    try {
      if(!signer || !minutesInfo?.id) return;
      let api = null;
      switch (signer) {
        case "secretary":
          api = secretarySignScience(minutesInfo?.id);
          break;
        case "chairman":
          api = chairmanSignScience(minutesInfo?.id);
          break;
        default:
          break;
      }
      if(!api) return;
      let { data } = await api;
      if(data?.code === CODE.SUCCESS) {
        setOpenConfirmDialog(false);
        toast.success(`Ký biên bản thành công!`);
        getMinutesInfo(String(topicId));
      } else {
        toast.error(`Ký biên bản thất bại`);
        setOpenConfirmDialog(false);
      }
    } catch (error) {
      console.error(error);
      setOpenConfirmDialog(false);
    }
  }

  return (
    <>
      <Modal.Body className="spaces p-0 bg-white">
        <div className="a4-page">
          <div className="a4-container">
            <div className="header-container">
              <div className="left-column center-text">
                <h3 className="bold">SỞ Y TẾ HÀ NỘI</h3>
                <h3 className="bold underline">BỆNH VIỆN ĐA KHOA MỸ ĐỨC</h3>
              </div>
              <div className="right-column center-text">
                <p className="bold m-0">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
                <p className="underline bold spaces mb-8">
                  Độc lập - Tự do - Hạnh phúc
                </p>
                <p
                  className="center-text spaces mt-2"
                  style={{ fontStyle: "italic" }}
                >
                  Mỹ Đức, ngày {moment().format("DD/MM/YYYY")}
                </p>
              </div>
            </div>

            <h3 className="text-center bold section fs-3">
              BIÊN BẢN HỌP HỘI ĐỒNG ĐÁNH GIÁ
            </h3>
            <h4 className="text-center bold fs-3">
              THUYẾT MINH ĐỀ TÀI KHOA HỌC VÀ CÔNG NGHỆ CẤP CƠ SỞ
            </h4>

            <div className="section">
              <p>
                <b>1. Tên đề tài: </b>
                {minutesInfo?.topicName}
              </p>
              <div className="pb-3">
                <p className="m-0">
                  <b>2. Chủ nhiệm đề tài: </b>
                  {mainResearcher?.name}
                </p>
                {coMainResearcher && (
                  <p style={{ paddingLeft: "16px", margin: "0" }}>
                    Đồng chủ nhiệm: {coMainResearcher?.name} và cộng sự
                  </p>
                )}
              </div>

              <p>
                <b>3. Quyết định thành lập Hội đồng:</b> Số {minutesInfo?.code}
                /QĐ-BVĐKSS
              </p>

              <p>
                <b>4. Cơ quan thực hiện đề tài:</b> Bệnh viện đa khoa Mỹ Đức
              </p>

              <p>
                <b>5. Ngày họp:</b>&nbsp;
                {minutesInfo?.date
                  ? moment(minutesInfo?.date).format("DD/MM/YYYY")
                  : ""}
              </p>

              <p>
                <b>6. Địa điểm:</b>&nbsp;
                {minutesInfo?.place}
              </p>
              <p>
                <b>7. Thành viên của hội đồng: &nbsp;</b>
              </p>
              <p>
                Tổng số: 5. Có mặt: {minutesInfo?.numAttendees}. Vắng mặt:{" "}
                {minutesInfo?.numAbsent}
              </p>
              <p>
                <b>8. Khách mời dự: &nbsp;</b>
              </p>
              <p>
                Tổng số điểm: {minutesInfo?.scoreTotal}. Điểm trung bình ban
                đầu: {minutesInfo?.scoreInitialAverage}
              </p>
              <p>
                <b>9. Tổng số đầu điểm: {minutesInfo?.scoreInitialTotal}</b>{" "}
                trong đó hợp lệ {minutesInfo?.scoreInitialValid}: không hợp lệ:{" "}
                {minutesInfo?.scoreInitialInvalid}
              </p>
              <p>
                <b>10.Tổng số điểm hợp lệ:</b> {minutesInfo?.scoreValidTotal}
              </p>
              <p>
                <b>11. Điểm trung bình cuối cùng:</b>{" "}
                {minutesInfo?.scoreFinalAverage}
              </p>
              <p>
                <b>12. Kết luận và kiến nghị của Hội đồng: &nbsp;</b>{" "}
                {minutesInfo?.committeeRecommendation}
              </p>
              <p>
                <b>13.Xếp loại phê duyệt:</b> {minutesInfo?.committeeConclusion}
              </p>
              <div className="flex" style={{ fontStyle: "italic" }}>
                <p style={{ width: "150px" }}>* Ghi chú:</p>
                <div>
                  <div>
                    - Phê duyệt: &gt; 70 điểm (trong đó tổng điểm của 4 tiêu chí
                    1,2,3,4 &gt; 50 điểm);
                  </div>
                  <div>- Không phê duyệt: ≤ 70 điểm</div>
                  <div>
                    - Điểm của thành viên hội đồng chênh lệch &gt; 20 điểm so
                    với điểm trung bình ban đầu coi là điểm không hợp lệ và
                    không được tính vào tổng điểm số điểm hợp lệ.
                  </div>
                </div>
              </div>
            </div>
            <div className="signature-container">
              <div className="left-signature">
                <p>
                  <b>THƯ KÝ</b>
                </p>
                <p className="pt-4">{minutesInfo?.secretaryName}</p>
                <i>{minutesInfo?.isSecretarySigned ? "(Đã ký)" : ""}</i>
                {minutesInfo?.allowSecretarySign && (
                  <p className="pt-4">
                    <Button
                      variant="primary"
                      className="min-w-75px fw-bold"
                      type="button"
                      onClick={() => {
                        setOpenConfirmDialog(true);
                        setSigner("secretary");
                      }}
                    >
                      {"Ký"}
                    </Button>
                  </p>
                )}
              </div>

              <div className="right-signature">
                <p>
                  <b>CHỦ TỊCH HỘI ĐỒNG</b>
                </p>
                <p className="pt-4">{minutesInfo?.chairmanName}</p>
                <i>{minutesInfo?.isChairmanSigned ? "(Đã ký)" : ""}</i>
                {minutesInfo?.allowChairmanSign && (
                  <p className="pt-4">
                    <Button
                      variant="primary"
                      className="min-w-75px fw-bold"
                      type="button"
                      onClick={() => {
                        setOpenConfirmDialog(true);
                        setSigner("chairman");
                      }}
                    >
                      {"Ký"}
                    </Button>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        {openConfirmDialog && (
          <ConfirmDialog
            show={openConfirmDialog}
            yes="Xác nhận"
            onYesClick={() => handleYesClick()}
            message={`Bạn có chắc muốn ký biên bản này?`}
            close="Huỷ"
            onCloseClick={() => setOpenConfirmDialog(false)}
            title="Xác nhận thao tác"
          />
        )}
      </Modal.Body>
    </>
  );
};
export default TabScienceMinutes;
