import { OptionType } from "../../category-personnel/model/PersonnelModel";
import { TMainResearcher, TMember } from "../models/TopicRegistrationModel";

export const TRANG_THAI: OptionType[] = [
  {
    id: 0,
    title: "Toàn bộ",
    value: "all"
  },
  {
    id: 1,
    title: "Mở",
    value: "1"
  },
  {
    id: 2,
    title: "Đóng",
    value: "2"
  }
]

export const STATUS_ID = {
  OPEN: 1,
  CLOSE: 2
}

export const memberOptions: TMember[] = [
  { fakeId: '1', name: 'Trần Văn A', typeId: 3, academicRank: "Phó giáo sư", degree: "Tiến sĩ", scientificTitle: "Bác sĩ đa khoa", officeCurrently: "Công ty BCD", gender: 1, department: "Khoa chuẩn đoán hình ảnh", position: "Bác sĩ", phone: "0123456789", email: "atv@gmail.com", eduLevel: "Phó giáo sư tiến sĩ", office: "Công ty ABC", fax: "151452145", officeAddress: "Thanh Xuân, Hà Nội"},
  { fakeId: '2', name: 'Trần Thị B', typeId: 3, academicRank: "Phó giáo sư", degree: "Tiến sĩ", scientificTitle: "Y tá", officeCurrently: "Công ty JAC", gender: 2, department: "Khoa ngoại tiết niệu", position: "Y tá", phone: "0987654321", email: "btt@gmail.com", eduLevel: "Phó giáo sư tiến sĩ", office: "Công ty ABC", fax: "151452145", officeAddress: "Thanh Xuân, Hà Nội"},
  { fakeId: '3', name: 'Đặng Thị C', typeId: 3, academicRank: "Phó giáo sư", degree: "Tiến sĩ", scientificTitle: "Kế toán", officeCurrently: "Công ty UAD", gender: 2, department: "Khoa tai mũi họng", position: "Bác sĩ đa khoa", phone: "0969245784", email: "cdt@gmail.com", eduLevel: "Phó giáo sư tiến sĩ", office: "Công ty ABC", fax: "151452145", officeAddress: "Thanh Xuân, Hà Nội"},
  { fakeId: '4', name: 'Phạm Thị D', typeId: 3, academicRank: "Phó giáo sư", degree: "Tiến sĩ", scientificTitle: "Bảo vệ", officeCurrently: "Công ty TFS", gender: 1, department: "Khoa chuẩn đoán hình ảnh", position: "Y tá", phone: "0987654321", email: "dpt@gmail.com", eduLevel: "Phó giáo sư tiến sĩ", office: "Công ty ABC", fax: "151452145", officeAddress: "Thanh Xuân, Hà Nội"}
];

export const teacherOptions: TMainResearcher[] = [
  { fakeId: '1', name: 'Nguyễn Văn Nhiệm', typeId: 1, academicRank: "Phó giáo sư", degree: "Tiến sĩ", scientificTitle: "Bác sĩ đa khoa", officeCurrently: "Công ty YFS", gender: 1, department: "Khoa chuẩn đoán hình ảnh", position: "Bác sĩ", phone: "0123456789", email: "nhiemnv@gmail.com", eduLevel: "Phó giáo sư tiến sĩ", office: "Công ty ABC", fax: "151452145", officeAddress: "Thanh Xuân, Hà Nội"},
  { fakeId: '2', name: 'Phan Đức Anh', typeId: 1, academicRank: "Phó giáo sư", degree: "Tiến sĩ", scientificTitle: "Kế toán", officeCurrently: "Công ty IYA", gender: 2, department: "Khoa ngoại tiết niệu", position: "Y tá", phone: "0987654321", email: "anhpd@gmail.com", eduLevel: "Phó giáo sư tiến sĩ", office: "Công ty ABC", fax: "151452145", officeAddress: "Thanh Xuân, Hà Nội"},
  { fakeId: '3', name: 'Võ Tấn Hiệp', typeId: 1, academicRank: "Phó giáo sư", degree: "Tiến sĩ", scientificTitle: "Bảo vệ", officeCurrently: "Công ty UAE", gender: 1, department: "Khoa tai mũi họng", position: "Bác sĩ đa khoa", phone: "0969245784", email: "hiepvt@gmail.com", eduLevel: "Phó giáo sư tiến sĩ", office: "Công ty ABC", fax: "151452145", officeAddress: "Thanh Xuân, Hà Nội"},
];

export const DEPARTMENTS: OptionType[] = [
  {
    id: 0,
    title: "Khoa chẩn đoán hình ảnh",
    value: "Khoa chẩn đoán hình ảnh"
  },
  {
    id: 1,
    title: "Khoa Ngoại Tiết Niệu",
    value: "Khoa Ngoại Tiết Niệu"
  },
  {
    id: 2,
    title: "Khoa Tai Mũi họng",
    value: "Khoa Tai Mũi họng"
  }
]