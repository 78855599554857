const RESPONSE_STATUS_CODE = {
  SUCCESS: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
}

const KEY_LOCALSTORAGE = {
  AUTH: "auth-patient",
  ACCESS_TOKEN_DECODE: "access-token-decode-patient",
  TOKEN_EXPIRATION: "token-expiration-patient",
  DEPARTMENT: "department",
  ROOM: "room"
}

const VARIABLE = {
  FUNCTION_CONSTANT: {
    ERROR_MESSAGE: "errorMessage",
    MESSAGE: "message",
  },
};

const AUTHORIZE_REQUEST = `${process.env.REACT_APP_SSO_AUTHORIZE_ENDPOINT}?response_type=${process.env.REACT_APP_SSO_RESPONSE_TYPE}&scope=${process.env.REACT_APP_SSO_SCOPE}&client_id=${process.env.REACT_APP_SSO_CLIENT_ID_SHELL}&redirect_uri=${process.env.REACT_APP_SSO_REDIRECT_URI_SHELL}`;

export { RESPONSE_STATUS_CODE, KEY_LOCALSTORAGE, AUTHORIZE_REQUEST, VARIABLE }
