import { OptionType } from "../../category-personnel/model/PersonnelModel";
import { REPORT_SUB_TYPE } from "../../utils/Constant";

export const TRANG_THAI: OptionType[] = [
  {
    id: 1,
    title: "Chờ duyệt đề xuất",
    value: "3"
  },
  {
    id: 2,
    title: "Chờ nộp đề cương",
    value: "4"
  },
  {
    id: 3,
    title: "Chờ xét duyệt đề cương",
    value: "5"
  },
  {
    id: 4,
    title: "Chờ phê duyệt",
    value: "6"
  },
  {
    id: 5,
    title: "Đang triển khai",
    value: "7"
  },
  {
    id: 6,
    title: "Từ chối phê duyệt",
    value: "8"
  },
  {
    id: 7,
    title: "Chờ nghiệm thu",
    value: "9"
  },
  {
    id: 8,
    title: "Đã nghiệm thu",
    value: "10"
  },
  {
    id: 9,
    title: "Từ chối nghiệm thu",
    value: "11"
  },
  {
    id: 10,
    title: "Từ chối đề xuất",
    value: "15"
  },
  {
    id: 11,
    title: "Từ chối đề cương",
    value: "16"
  }
]

export const GIA_HAN: OptionType[] = [
  {
    id: 1,
    title: "Có",
    value: "1"
  },
  {
    id: 2,
    title: "Không",
    value: "2"
  }
]

export const ANNOUNCEMENT_TYPE: OptionType[] = [
  {
    id: 1,
    title: "Xét duyệt",
    value: "1"
  }
]

export const EXTEND: OptionType[] = [
  {
    id: 1,
    title: "1 tháng",
    value: "1"
  },
  {
    id: 2,
    title: "2 tháng",
    value: "2"
  },
  {
    id: 3,
    title: "3 tháng",
    value: "3"
  },
  {
    id: 6,
    title: "6 tháng",
    value: "6"
  },
  {
    id: 12,
    title: "12 tháng",
    value: "12"
  }
]

export const REPORT_TYPE = {
  PRODUCT: 1,
  ATTACHMENT: 2
}

export const CONFIRM_TYPE = {
  DELETE_TOPIC: "xóa đề tài",
  UPLOAD_OUTLINE: "tải lên đề cương"
}

export const TABS = {
  DETAILS: {
    eventKey: "details",
    title: "Thông tin chi tiết"
  },
  OUTLINE: {
    eventKey: "outline",
    title: "Thông tin đề cương"
  },
  PROPOSAL: {
    eventKey: "proposal",
    title: "Thông tin đề xuất"
  },
  SCIENCE_MINUTES: {
    eventKey: "science_minutes",
    title: "Biên bản HĐKH"
  },
  ACCEPTANCE_MINUTES: {
    eventKey: "acceptance_minutes",
    title: "Biên bản HĐNT"
  },
  REVIEW_HDKH: {
    eventKey: "review_hdkh",
    title: "Nhận xét & đánh giá HĐKH"
  },
  REVIEW_HDNT: {
    eventKey: "review_hdnt",
    title: "Nhận xét & đánh giá HĐNT"
  }
}

export const MINMUTES_TYPE = {
  SCIENCE: 1,
  ACCEPTANCE: 2
}