import { FieldArray, FormikErrors, FormikHandlers } from "formik";
import { FC, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Select, { SingleValue } from 'react-select';
import { toast } from "react-toastify";
import { TextFieldCustom } from "../../../../category-personnel/components/text-field-custom/TextFieldCustom";
import { DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE } from "../../../../utils/Constant";
import { listScienceCouncilMember } from "../../constants/constants";
import { initScienceCouncilMember, TAddScienceCouncil, TScienceCouncilDetails, TScienceCouncilMember } from "../../models/ScienceCouncilManagerModels";
import AutocompleteObjectV2 from '../../../../component/AutocompleteObjectV2';
import { searchEmployee } from "../../services/services";

type Iprops = {
  values: TAddScienceCouncil;
  errors: FormikErrors<TAddScienceCouncil>;
  handleChange: FormikHandlers['handleChange'];
  handleBlur:FormikHandlers['handleBlur'];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  isView?: boolean;
  details?: TScienceCouncilDetails;
};

const Step2: FC<Iprops> = (props) => {
  const { values, errors, handleChange,handleBlur, setFieldValue, isView, details } = props;

  const GENDER = [{code: 0, name: "Nam"}, {code: 1, name: "Nữ"}]

  return (
    <div className="w-100">
      <Row className="py-2">
        <Col xs={12}>
          <div className="flex align-items-center">
            <div className="label min-w-100px fw-bold">Số thành viên</div>
            <div className="flex-1 spaces pe-3">
              <TextFieldCustom
                type="number"
                title=""
                name="numMembers"
                values={values.numMembers}
                errors={errors.numMembers}
                onBlur={(e: React.FocusEvent<any>) => {
                  let quantity = Number(e.target.value);
                            const currentLength = values.members.length;
                            if (quantity > currentLength) {
                              let newMemberList: TScienceCouncilMember[] = Array.from(
                                { length: quantity -currentLength },
                                () => (initScienceCouncilMember)
                              );
                              setFieldValue("members", [...values.members, ...newMemberList]);
                            }
                            else if(quantity< currentLength){
                              setFieldValue("members", values.members.slice(0, quantity))
                            }
                 
                  handleBlur(e);
                }}
                handleChange={(e: React.ChangeEvent<any>) => {
                  let quantity = Number(e?.target?.value);
                  if (quantity > 5) {
                    toast.warning("Không được quá 5 thành viên");
                    return;
                  }
                  handleChange(e);
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="py-2">
        <FieldArray
          name="members"
          render={() => (
            <Row>
              {(values?.members)?.map((item: TScienceCouncilMember, index: number) => (
                <Col xs={index === 0 ? 12 : 6}
                  className={`member flex py-3 border`}
                  key={index}
                >
                  <>
                    <div className="label min-w-100px fw-bold text-pri spaces  py-8">
                      {index === 0 && (
                        <div>Chủ tịch HĐ</div>
                      )}
                      {index === 1 && (
                        <div>Thư ký</div>
                      )}
                      {index === 2 && (
                        <div>Thành viên 1</div>
                      )}
                      {index === 3 && (
                        <div>Thành viên 2</div>
                      )}
                      {index === 4 && (
                        <div>Thành viên 3</div>
                      )}
                    </div>
                    <div className="flex-1">
                      {isView ? (
                        <TextFieldCustom
                          type="text"
                          title=""
                          name={`members.${index}.name`}
                          disabled={isView}
                          values={values?.members?.[index]?.name}
                          errors={""}
                          handleChange={handleChange}
                        />
                      ): (
                        <AutocompleteObjectV2
                          options={[]}
                          menuPlacement="bottom"
                          name={`member${index + 1}`}
                          onChange={(option: any) =>  { setFieldValue(`members.${index}`, 
                            {
                              ...option,
                              gender: option?.gender?.code,
                              duty: option?.viTriCongViecText,
                              department: option?.phongBanText,
                              position: option?.chucVuText,
                              email: option?.emailCaNhan,
                              username: option?.username,
                              isChairman: Boolean(index === 0),
                              isSecretary: Boolean(index === 1),
                            }
                          )}}
                          searchFunction={() =>
                            searchEmployee({
                              pageIndex: DEFAULT_PAGE_INDEX,
                              pageSize: MAX_PAGE_SIZE,
                              donViCongTacId: "8e482f21-2414-422a-9bc1-fb997776bc2b"
                            })
                          }
                          value={values?.members?.[index]}
                          errors={errors?.members?.[index]}
                          searchObject={{}}
                          getOptionLabel={(e: any) => e?.name}
                        />
                      )}
                    </div>
                    {/* <div className="flex align-items-center pb-4">
                      <div className="label min-w-150px fw-bold spaces pl-16">
                        Họ và tên
                      </div>
                      
                    </div> */}
                  </>
                  {/* <Col xs={6} className="pb-4">
                    <div className="flex align-items-center">
                      <div className="label min-w-150px fw-bold spaces pl-16">
                        Giới tính
                      </div>
                      <div className="flex-1">
                        <TextFieldCustom
                          type="text"
                          title=""
                          name={`members.${index}.gender`}
                          disabled={true}
                          values={GENDER.find((item: any) => item?.code === values?.members?.[index]?.gender)?.name || ""}
                          errors={""}
                          handleChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} className="pb-4">
                    <div className="flex align-items-center">
                      <div className="label min-w-150px fw-bold spaces pl-16">
                        Vai trò
                      </div>
                      <div className="flex-1">
                        <TextFieldCustom
                          type="text"
                          title=""
                          name={`members.${index}.duty`}
                          disabled={true}
                          values={values?.members?.[index]?.duty || ""}
                          errors={""}
                          handleChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} className="pb-4">
                    <div className="flex align-items-center">
                      <div className="label min-w-150px fw-bold spaces pl-16">
                        Học hàm
                      </div>
                      <div className="flex-1">
                        <TextFieldCustom
                          type="text"
                          title=""
                          name={`members.${index}.academicRank`}
                          disabled={isView}
                          values={values?.members?.[index]?.academicRank || ""}
                          errors={""}
                          handleChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} className="pb-4">
                    <div className="flex align-items-center">
                      <div className="label min-w-150px fw-bold spaces pl-16">
                        Học vị
                      </div>
                      <div className="flex-1">
                        <TextFieldCustom
                          type="text"
                          title=""
                          name={`members.${index}.degree`}
                          disabled={isView}
                          values={values?.members?.[index]?.degree || ""}
                          errors={""}
                          handleChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} className="pb-4">
                    <div className="flex align-items-center">
                      <div className="label min-w-150px fw-bold spaces pl-16">
                        Khoa/Phòng
                      </div>
                      <div className="flex-1">
                        <TextFieldCustom
                          type="text"
                          title=""
                          name={`members.${index}.department`}
                          disabled={true}
                          values={values?.members?.[index]?.department || ""}
                          errors={""}
                          handleChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} className="pb-4">
                    <div className="flex align-items-center">
                      <div className="label min-w-150px fw-bold spaces pl-16">
                        Chức vụ
                      </div>
                      <div className="flex-1">
                        <TextFieldCustom
                          type="text"
                          title=""
                          name={`members.${index}.position`}
                          disabled={true}
                          values={values?.members?.[index]?.position || ""}
                          errors={""}
                          handleChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} className="pb-4">
                    <div className="flex align-items-center">
                      <div className="label min-w-150px fw-bold spaces pl-16">
                        Email
                      </div>
                      <div className="flex-1">
                        <TextFieldCustom
                          type="text"
                          title=""
                          name={`members.${index}.email`}
                          disabled={true}
                          values={values?.members?.[index]?.email || ""}
                          errors={""}
                          handleChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} className="pb-4">
                    <div className="flex align-items-center">
                      <div className="label min-w-150px fw-bold spaces pl-16">
                        SĐT
                      </div>
                      <div className="flex-1">
                        <TextFieldCustom
                          type="text"
                          title=""
                          name={`members.${index}.phone`}
                          disabled={true}
                          values={values?.members?.[index]?.phone || ""}
                          errors={""}
                          handleChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} className="pb-4">
                    <div className="flex align-items-center">
                      <div className="label min-w-150px fw-bold spaces pl-16">
                        Đơn vị công tác
                      </div>
                      <div className="flex-1">
                        <TextFieldCustom
                          type="text"
                          title=""
                          name={`members.${index}.office`}
                          disabled={isView}
                          values={values?.members?.[index]?.office || ""}
                          errors={""}
                          handleChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col> */}
                </Col>
              ))}
            </Row>
          )}
        />
      </Row>
    </div>
  );
};

export { Step2 };

