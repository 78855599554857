import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { TextFieldCustom } from "../../../category-personnel/components/text-field-custom/TextFieldCustom";
import { generateYears } from "../../../utils/AppFunction";
import { CODE, DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE } from "../../../utils/Constant";
import { searchBatchOfRegistration } from "../../mo-dot-dang-ky/services/services";
import { TRANG_THAI } from "../constants/constants";
import { initTopicFilter, TTopicFilter } from "../models/TopicModel";
import { TopicContext } from "../TopicContext";
import moment from "moment";
import AutocompleteObjectV2 from '../../../component/AutocompleteObjectV2';

export default function Filter() {

  const { filter, setFilter } = useContext(TopicContext);

  const [batchOfRegistrationList, setBatchOfRegistrationList] = useState<any[]>([]);

  const validationSchema = Yup.object({});

  useEffect(() => {
    getBatchOfRegistrationList();
  }, [])

  const getBatchOfRegistrationList = async () => {
    try {
      let { data } = await searchBatchOfRegistration({
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: MAX_PAGE_SIZE
      });
      if (data?.code === CODE.SUCCESS) {
        setBatchOfRegistrationList(
          data?.data?.content?.map((item: any) => {
            return {
              ...item,
              title: item?.startDate ? moment(item?.startDate).format("DD/MM/YYYY") : "",
              value: item?.id,
            };
          })
        );
      } else {
        toast.error("Đã có lỗi xảy ra!");
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = async (values: TTopicFilter) => {
    setFilter({...filter, ...values});
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initTopicFilter}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="topic-filter spaces px-10 py-10">
            <Row>
              <Col xs={2}>
                <div className="flex align-items-center">
                  <div className="label pe-4 fw-bold">Năm</div>
                  <div className="flex-1">
                    <AutocompleteObjectV2
                      options={generateYears()}
                      isSearchDefauilt={true}
                      name={"yearOption"}
                      onChange={(e: any) =>  {
                        setFieldValue("year", e?.id || null);
                        setFieldValue("yearOption", e);
                      }}
                      value={values.yearOption || {
                        id: 2024,
                        title: "2024",
                        value: "2024"
                      }}
                      errors={errors.year}
                      searchObject={{}}
                      getOptionLabel={(e: any) => e?.title}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={3}>
                <div className="flex align-items-center">
                  <div className="label pe-4 fw-bold">Đợt</div>
                  <div className="flex-1">
                    <AutocompleteObjectV2
                      options={[]}
                      isSearchDefauilt={true}
                      name={"regisPeriod"}
                      onChange={(e: any) =>  { setFieldValue("regisPeriodId", e?.id || null) }}
                      searchFunction={() =>
                        searchBatchOfRegistration({
                          pageIndex: DEFAULT_PAGE_INDEX,
                          pageSize: MAX_PAGE_SIZE
                        })
                      }
                      value={values.regisPeriod}
                      errors={errors.regisPeriodId}
                      searchObject={{}}
                      getOptionLabel={(e: any) => e?.startDate ? moment(e?.startDate).format("DD/MM/YYYY") : ""}
                    
                    />
                  </div>
                </div>
              </Col>
              <Col xs={3}>
                <div className="flex align-items-center">
                  <div className="label pe-4 fw-bold">Trạng thái</div>
                  <div className="flex-1">
                    <AutocompleteObjectV2
                      options={TRANG_THAI}
                      isSearchDefauilt={true}
                      name={"statusOption"}
                      onChange={(e: any) =>  {setFieldValue("statusId", e?.value || null)}}
                      value={values.statusOption}
                      errors={errors.statusId}
                      searchObject={{}}
                      getOptionLabel={(e: any) => e?.title}
                      
                    />
                  </div>
                </div>
              </Col>
              <Col xs={3}>
                <div className="flex align-items-center">
                  <div className="label pe-4 fw-bold">Từ khóa</div>
                  <div className="flex-1">
                    <TextFieldCustom
                      type="text"
                      title=""
                      name="keyword"
                      placeholder="Nhập tên đề tài"
                      values={values?.keyword}
                      errors={""}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={1} className="flex align-items-stretch">
                <button
                  type="submit"
                  onClick={() => {}}
                  className="btn-outline btn-filter"
                >
                  <i className="bi bi-filter text-pri fs-3"></i>
                  Lọc
                </button>
              </Col>
            </Row>
            {/* <div className="d-flex spaces pt-10 mt-4 bg-white justify-content-between align-items-center">
              <div className="flex-grow-1 ">
                <InputSearch
                  placeholder="Tìm kiếm"
                  handleChange={() => {}}
                  className="spaces h-31 pr-4  radius-3"
                />
              </div>
            </div> */}
          </div>
        </Form>
      )}
    </Formik>
  );
}
