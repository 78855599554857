import { createContext, Dispatch, FC, SetStateAction, useState } from "react";
import TopicRegistration from "./TopicRegistration";
import "./TopicRegistration.scss";
import { initFilter, initProposal, initTopicRegistration, TBatchOfRegistration, TBatchOfRegistrationFilter, TProposal, TTopicRegistration } from "./models/TopicRegistrationModel";

export interface TopicRegistrationContextProps {
  topicRegistrationInfo: TTopicRegistration;
  setTopicRegistrationInfo: Dispatch<SetStateAction<TTopicRegistration>>;
  onReset: () => void;
  proposalInfo: TProposal;
  setProposalInfo: Dispatch<SetStateAction<TProposal>>;
  onResetProposal: () => void;
  filter: TBatchOfRegistrationFilter;
  setFilter: Dispatch<SetStateAction<TBatchOfRegistrationFilter>>;
  batchOfRegistrationList: TBatchOfRegistration[];
  setBatchOfRegistrationList: Dispatch<SetStateAction<TBatchOfRegistration[]>>;
}

const initialContext = {
  topicRegistrationInfo: initTopicRegistration,
  setTopicRegistrationInfo: () => {},
  onReset: () => {},
  proposalInfo: initProposal,
  setProposalInfo: () => {},
  onResetProposal: () => {},
  filter: initFilter,
  setFilter: () => {},
  batchOfRegistrationList: [],
  setBatchOfRegistrationList: () => {}
};

export const TopicRegistrationContext =
  createContext<TopicRegistrationContextProps>(initialContext);

export const TopicRegistrationProvider: FC = () => {
  const [topicRegistrationInfo, setTopicRegistrationInfo] =
    useState<TTopicRegistration>(initTopicRegistration);
  const [proposalInfo, setProposalInfo] =
    useState<TProposal>(initProposal);
  const [filter, setFilter] = useState<TBatchOfRegistrationFilter>(initFilter);
  const [batchOfRegistrationList, setBatchOfRegistrationList] = useState<TBatchOfRegistration[]>([]);

  return (
    <TopicRegistrationContext.Provider
      value={{
        topicRegistrationInfo,
        setTopicRegistrationInfo,
        onReset: () => {
          setTopicRegistrationInfo(initTopicRegistration);
        },
        proposalInfo,
        setProposalInfo,
        filter,
        setFilter,
        batchOfRegistrationList,
        setBatchOfRegistrationList,
        onResetProposal: () => {
          setTopicRegistrationInfo(initTopicRegistration);
        },
      }}
    >
      <div className="bg-white h-100">
        <div className="flex-1 flex flex-column">
          <div className="py-1">
            <TopicRegistration />
          </div>
        </div>
      </div>
    </TopicRegistrationContext.Provider>
  );
};

export default TopicRegistrationProvider;
